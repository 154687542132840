import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFormDetails } from "../../methods/actions";
import { AllOutliers } from "../../container";

export const Outliers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    //FInd a better way to handle this
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [],
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 0,
      })
    );
    //This block of code
  }, [dispatch]);

  return <AllOutliers />;
};
