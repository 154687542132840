import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LatestDeviceComponent, ScrollContainer, Search } from "../components";
import { getOutlierDevices, getFormDetails } from "../methods/actions";
import { useParams } from "react-router-dom";
import { PageLoader } from "../container";
import { CenterDevices, Container, SearchContainer } from "./style";
import moment from "moment";

export const OutlierDevices = () => {
  const dispatch = useDispatch();
  let { walletAddress } = useParams();
  const outlierdevices = useSelector(
    (state) => state.OutlierReducer.outlierdevices
  );

  const searchoutlierdevices = useSelector(
    (state) => state.GeneralReducer.searchoutlierdevices
  );
  const gettingOutlierDevices = useSelector(
    (state) => state.LoaderReducer.gettingOutlierDevices
  );
  useEffect(() => {
    dispatch(getOutlierDevices(walletAddress, 1, 50, searchoutlierdevices));
  }, [dispatch, searchoutlierdevices, walletAddress]);

  return (
    <Container>
      <SearchContainer margin={"200px"}>
        <Search
          placeholder={"Search Devices"}
          handleChange={(e) =>
            dispatch(
              getFormDetails({
                props: ["searchoutlierdevices"],
                value: e.target.value,
              })
            )
          }
        />
      </SearchContainer>

      <ScrollContainer height={"600px"}>
        {gettingOutlierDevices ? (
          <PageLoader />
        ) : outlierdevices.length > 0 ? (
          outlierdevices.map((outlier, i) => {
            return (
              <LatestDeviceComponent
                key={i}
                link={`/my_device/statistics/${outlier.tokenId}/${outlier.deviceId}`}
                title={outlier.deviceName}
                active={outlier.sessionResponse.hasActiveSession}
                flag={outlier.location.flagIconSmall}
                countryName={
                  outlier.location
                    ? outlier.location.city + ", " + outlier.location.country
                    : "--"
                }
                lastactive={
                  outlier.lastTrafficUpdate === 0
                    ? "Never Active"
                    : moment(outlier.lastTrafficUpdate).format(
                        "DD-MM-YYYY, h:mm a"
                      )
                }
                tier={outlier.deviceTier}
              />
            );
          })
        ) : (
          <CenterDevices> No devices match this search</CenterDevices>
        )}
      </ScrollContainer>
    </Container>
  );
};
