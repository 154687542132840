//import { Button } from "../../components";
import {
  Container,
  Content,
  ImageContainer,
  StatusText,
  StatusBox
} from "./style";
import { Link } from "react-router-dom";
//import Detail from "../../components/infoDetails/detail";

export const DeviceBox = ({
  img,
  title,
  number,
  onClick,
  connected,
  link,
  isp,
  speed,
  tier
}) => {
  return (
    <Container>
      <ImageContainer>
        <img src={img} alt={title} />
      </ImageContainer>
      <Content>
        {link ? (
          <Link style={{ textDecoration: "none" }} to={link}>
            {" "}
            <h4 style={{ position: "relative" }}>
              {title}{" "}
              {tier ? (
                tier === "TIER1" ? (
                  <img src="/assets/img/tierone.svg" alt="device-tier" />
                ) : tier === "TIER2" ? (
                  <img src="/assets/img/tiertwo.svg" alt="device-tier" />
                ) : (
                  <img src="/assets/img/tierthree.svg" alt="device-tier" />
                )
              ) : (
                ""
              )}
              {/* <Detail
                currentdescription={
                  <>
                    {" "}
                    <p > Tier 1</p> <p> This device is backed by an NFT</p>
                  </>
                }
              /> */}
            </h4>
          </Link>
        ) : (
          <h4>{title} </h4>
        )}

        <p>{number}</p>
        <p>
          {" "}
          ISP: <span> {isp}</span>
        </p>
        <p>
          {" "}
          Connection Speed: <span> {speed}</span>
        </p>
        {connected ? (
          <StatusBox>
            {" "}
            <StatusText>Connected </StatusText>
            <img src="/assets/icons/connected.png" alt="connected" />
          </StatusBox>
        ) : (
          <div> </div>
          // <Button
          //   buttonClass={"primary"}
          //   padding={"6px 40px"}
          //   fontSize={"0.61rem"}
          //   label={"Connect Device"}
          //   onClick={onClick}
          // />
        )}
      </Content>
    </Container>
  );
};
