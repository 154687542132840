import styled from "styled-components";
import { device } from "../../theme";

export const Container = styled.div`
  //position: absolute;
  //background: ${({ theme }) => theme.blue_gradient};
  padding: 0px;
  width: 100%;
  // left: 0;
  // top: 0;
  //margin-top: 100px;

  @media (max-width: ${device.laptop}) {
    top: 8%;
    //padding: 20px 42px 0px;
    width: calc(100% - 84px);
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  //margin-left: 10px;

  h4 {
    color: ${({ theme }) => theme.white};

    span {
      font-weight: normal;
      font-size: 1rem;
    }
  }
`;

export const RedDot = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${props =>
    props.active ? props.theme.green : props.theme.red};
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 50%;
  margin-left: 8px;
`;

export const MoreInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 20px;
  //margin-left: 30px;
`;

export const Spacer = styled.div`
  width: 21.43px;
`;

export const TabContainer = styled.div``;
