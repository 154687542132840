import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LatestDeviceComponent,
  ScrollContainer,
  AlternativeCard,
} from "../../../../components";
import Search from "./search";
import {
  Container,
  CardCont,
  CenterDevices,
  Option,
  OptionContainer,
} from "./style";
import {
  getFormDetails,
  getDevicesInCity,
  getDevicesByVolume,
  getDevicesByConnections,
} from "../../../../methods/actions";
import { useParams } from "react-router-dom";
import { PageLoader } from "../../../../container";
import moment from "moment";
import filesize from "filesize";

export const Devices = () => {
  const dispatch = useDispatch();
  const alldevicesincity = useSelector(
    (state) => state.DeviceReducer.alldevicesincity
  );
  const gettingDevicesInCity = useSelector(
    (state) => state.DeviceReducer.gettingDevicesInCity
  );
  const devicesInCitiesTotalPage = useSelector(
    (state) => state.DeviceReducer.devicesInCitiesTotalPage
  );
  const devicesInCitiesCurrentPage = useSelector(
    (state) => state.DeviceReducer.devicesInCitiesCurrentPage
  );

  const searchdeviceName = useSelector(
    (state) => state.GeneralReducer.searchdeviceName
  );

  const devicesbyvolume = useSelector(
    (state) => state.DeviceReducer.devicesbyvolume
  );
  const devicebyvolumeCurrentPage = useSelector(
    (state) => state.DeviceReducer.devicebyvolumeCurrentPage
  );
  const devicebyvolumeTotalPages = useSelector(
    (state) => state.DeviceReducer.devicebyvolumeTotalPages
  );
  const devicesbyconnections = useSelector(
    (state) => state.DeviceReducer.devicesbyconnections
  );
  const devicebyconnectionsCurrentPage = useSelector(
    (state) => state.DeviceReducer.devicebyconnectionsCurrentPage
  );
  const devicebyconnectionsTotalPages = useSelector(
    (state) => state.DeviceReducer.devicebyconnectionsTotalPages
  );
  const onlinestatus = useSelector(
    (state) => state.GeneralReducer.onlinestatus
  );
  const searchConnectionsResult = useSelector(
    (state) => state.GeneralReducer.searchConnectionsResult
  );
  const searchVolumeResult = useSelector(
    (state) => state.GeneralReducer.searchVolumeResult
  );
  const searchDevicesInCityResult = useSelector(
    (state) => state.GeneralReducer.searchDevicesInCityResult
  );

  let { cityname } = useParams();

  useEffect(() => {
    let newPage = devicesInCitiesCurrentPage + 1;
    if (cityname !== alldevicesincity[0]?.location?.city) {
      dispatch(getDevicesInCity(cityname, 1, 30, "", ""));
    } else if (devicesInCitiesTotalPage > devicesInCitiesCurrentPage) {
      dispatch(getDevicesInCity(cityname, newPage, 30, "", ""));
    }
  }, [
    dispatch,
    cityname,
    devicesInCitiesCurrentPage,
    devicesInCitiesTotalPage,
  ]);

  useEffect(() => {
    let newPage = devicebyconnectionsCurrentPage + 1;
    if (cityname !== devicesbyconnections[0]?.city) {
      dispatch(getDevicesByConnections(cityname, 1, 30, ""));
    } else if (devicebyconnectionsTotalPages > devicebyconnectionsCurrentPage) {
      dispatch(getDevicesByConnections(cityname, newPage, 30, ""));
    }
  }, [
    dispatch,
    cityname,
    devicebyconnectionsCurrentPage,
    devicebyconnectionsTotalPages,
  ]);

  useEffect(() => {
    let newPage = devicebyvolumeCurrentPage + 1;
    if (cityname !== devicesbyvolume[0]?.city) {
      dispatch(getDevicesByVolume(cityname, 1, 30, ""));
    } else if (devicebyvolumeTotalPages > devicebyvolumeCurrentPage) {
      dispatch(getDevicesByVolume(cityname, newPage, 30, ""));
    }
  }, [dispatch, cityname, devicebyvolumeCurrentPage, devicebyvolumeTotalPages]);

  return (
    <Container>
      <OptionContainer>
        <Option
          onClick={() => {
            dispatch(
              getFormDetails({
                props: ["onlinestatus"],
                value: "",
              })
            );
            dispatch(getDevicesInCity(cityname, 1, 30, "", ""));
          }}
          active={onlinestatus === ""}
        >
          {" "}
          All
        </Option>
        <Option
          onClick={() => {
            dispatch(
              getFormDetails({
                props: ["onlinestatus"],
                value: 1,
              })
            );
            dispatch(getDevicesByVolume(cityname, 1, 30, ""));
          }}
          active={onlinestatus === 1}
        >
          {" "}
          Data Volume
        </Option>
        <Option
          onClick={() => {
            dispatch(
              getFormDetails({
                props: ["onlinestatus"],
                value: 2,
              })
            );
            dispatch(getDevicesByConnections(cityname, 1, 30, ""));
          }}
          active={onlinestatus === 2}
        >
          {" "}
          Connections
        </Option>
      </OptionContainer>
      <Search />

      <ScrollContainer height={"380px"}>
        {gettingDevicesInCity ? (
          <PageLoader />
        ) : alldevicesincity.length > 0 ? (
          onlinestatus === "" ? (
            searchdeviceName !== "" ? (
              searchConnectionsResult.length === 0 ? (
                <CenterDevices>
                  {" "}
                  <CenterDevices>
                    <img src="/assets/img/light.svg" alt="empty" />
                  </CenterDevices>{" "}
                  {searchdeviceName + " is not in this location"}
                </CenterDevices>
              ) : (
                searchDevicesInCityResult.map((device, i) => (
                  <CardCont key={i}>
                    <LatestDeviceComponent
                      link={`/my_device/statistics/${device.tokenId}/${device.deviceId}`}
                      title={device.deviceName}
                      flag={device.location.flagIconSmall}
                      active={device?.sessionResponse?.hasActiveSession}
                      countryName={
                        device.location
                          ? device.location.city +
                            ", " +
                            device.location.country
                          : "--"
                      }
                      lastactive={
                        device.lastTrafficUpdate === 0
                          ? "Never Active"
                          : moment(device.lastTrafficUpdate).format(
                              "DD-MM-YYYY, h:mma"
                            )
                      }
                    />
                  </CardCont>
                ))
              )
            ) : (
              alldevicesincity.map((device, i) => (
                <CardCont key={i}>
                  <LatestDeviceComponent
                    link={`/my_device/statistics/${device.tokenId}/${device.deviceId}`}
                    title={device.deviceName}
                    flag={device.location.flagIconSmall}
                    active={device?.sessionResponse?.hasActiveSession}
                    countryName={
                      device.location
                        ? device.location.city + ", " + device.location.country
                        : "--"
                    }
                    lastactive={
                      device.lastTrafficUpdate === 0
                        ? "Never Active"
                        : moment(device.lastTrafficUpdate).format(
                            "DD-MM-YYYY, h:mm a"
                          )
                    }
                  />
                </CardCont>
              ))
            )
          ) : onlinestatus === 1 ? (
            searchdeviceName !== "" ? (
              searchConnectionsResult.length === 0 ? (
                <CenterDevices>
                  {" "}
                  <CenterDevices>
                    <img src="/assets/img/light.svg" alt="empty" />
                  </CenterDevices>{" "}
                  {searchdeviceName + " is not in this location"}
                </CenterDevices>
              ) : (
                searchVolumeResult.map((device, i) => (
                  <CardCont key={i}>
                    <AlternativeCard
                      link={`/my_device/statistics/${device.tokenId}/${device.deviceId}`}
                      title={device.deviceName}
                      flag={device.flagIconSmall}
                      active={device?.hasActiveSession}
                      countryName={device.city + ", " + device.country}
                      data={filesize(device.dataVolume)}
                    />
                  </CardCont>
                ))
              )
            ) : (
              devicesbyvolume.map((device, i) => (
                <CardCont key={i}>
                  <AlternativeCard
                    link={`/my_device/statistics/${device.tokenId}/${device.deviceId}`}
                    title={device.deviceName}
                    flag={device.flagIconSmall}
                    active={device?.hasActiveSession}
                    countryName={device.city + ", " + device.country}
                    data={filesize(device.dataVolume)}
                  />
                </CardCont>
              ))
            )
          ) : searchdeviceName !== "" ? (
            searchConnectionsResult.length === 0 ? (
              <CenterDevices>
                {" "}
                <CenterDevices>
                  <img src="/assets/img/light.svg" alt="empty" />
                </CenterDevices>{" "}
                {searchdeviceName + " is not in this location"}
              </CenterDevices>
            ) : (
              searchConnectionsResult.map((device, i) => (
                <CardCont key={i}>
                  <AlternativeCard
                    link={`/my_device/statistics/${
                      device.tokenId ? device.tokenId : 0
                    }/${device.deviceId}`}
                    title={device.deviceName}
                    flag={device.flagIconSmall}
                    active={device?.hasActiveSession}
                    countryName={device.city + ", " + device.country}
                    data={device.totalConnections + " total connections"}
                  />
                </CardCont>
              ))
            )
          ) : (
            devicesbyconnections.map((device, i) => (
              <CardCont key={i}>
                <AlternativeCard
                  link={`/my_device/statistics/${
                    device.tokenId ? device.tokenId : 0
                  }/${device.deviceId}`}
                  title={device.deviceName}
                  flag={device.flagIconSmall}
                  active={device?.hasActiveSession}
                  countryName={device.city + ", " + device.country}
                  data={device.totalConnections + " total connections"}
                />
              </CardCont>
            ))
          )
        ) : (
          <CenterDevices>
            {" "}
            <CenterDevices>
              <img src="/assets/img/light.svg" alt="empty" />
            </CenterDevices>{" "}
            No device found
          </CenterDevices>
        )}
      </ScrollContainer>
    </Container>
  );
};
