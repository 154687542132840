import { useSelector } from "react-redux";
import {
  ArrowContainer,
  CityName,
  ScrollContainer,
} from "../../../../components";
import moment from "moment";
import { formathash } from "../../../../methods/helper";

import { CardCont, TransactionName, CenterTransactions } from "./style";

export const Transaction = () => {
  const blocktransactions = useSelector(
    (state) => state.BlockHeightReducer.blocktransactions
  );

  return (
    <ScrollContainer height={"450px"}>
      {blocktransactions.length > 0 ? (
        blocktransactions.map((transaction, i) => (
          <CardCont key={i}>
            <ArrowContainer
              link={`/transaction_stats/${transaction.transactionId}`}
            >
              <div>
                <TransactionName>
                  {transaction.transactionId
                    ? formathash(transaction.transactionId)
                    : ""}
                </TransactionName>
                <CityName
                  flag={"/assets/svgs/clock.svg"}
                  name={moment(transaction.sessionTimeStamp).format(
                    "Do MMMM YYYY, h:mm"
                  )}
                />
              </div>
            </ArrowContainer>
          </CardCont>
        ))
      ) : (
        <CenterTransactions>
          {" "}
          <CenterTransactions>
            <img src="/assets/img/light.svg" alt="empty" />
          </CenterTransactions>
        </CenterTransactions>
      )}
    </ScrollContainer>
  );
};
