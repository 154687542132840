import styled from "styled-components";

export const Container = styled.div`
  //margin-bottom: 10px;
  height: 30px;
`;

export const ActiveRoute = styled.span`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
`;

export const InactiveRoute = styled.span`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey2};
  font-weight: 400;
  cursor-pointer;
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey2};
`;
