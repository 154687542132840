import styled from "styled-components";

export const Container = styled.div`
  padding: 33px 20px;
  background-color: ${({ theme }) => theme.grey3};
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(45, 58, 58, 0.02);
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ImageContainer = styled.div`
  width: 25%;
  overflow: hidden;
  height: 100%;

  img {
    width: 100%;
    border-radius: 6.92727px;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 0px 15px;
  h4 {
    color: ${({ theme }) => theme.black};
    line-height: 27px;
    font-size: 18px;
  }

  p {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.grey2};
    line-height: 21px;
    margin-top: 8px;
  }

  button {
    margin-top: 50px;
  }
`;
export const StatusText = styled.p`
  margin-top: 0px !important;
`;
export const StatusBox = styled.div`
  display: flex;
  align-items: "center";
  margin-top: 10px;
  font-style: italic;
`;
