import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Search,
  DefaultWallet,
  DeviceBox,
  ScrollContainer,
} from "../components";
import {
  Container,
  CenterContent,
  CenterDevices,
  SearchContainer,
} from "./style";
import { togglemodal, getFormDetails, getMyDevices } from "../methods/actions";
import { PageLoader } from "./pageloader";

export const MyDevices = () => {
  const address = useSelector((state) => state.ConnectWalletReducer.address);
  const mydevices = useSelector((state) => state.DeviceReducer.mydevices);
  const mydeviceTotalPage = useSelector(
    (state) => state.DeviceReducer.mydeviceTotalPage
  );
  const mydeviceCurrentPage = useSelector(
    (state) => state.DeviceReducer.mydeviceCurrentPage
  );

  const searchmydevicename = useSelector(
    (state) => state.GeneralReducer.searchmydevicename
  );
  const gettingmydevices = useSelector(
    (state) => state.LoaderReducer.gettingmydevices
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let newPage = mydeviceCurrentPage + 1;
    if (mydeviceTotalPage > mydeviceCurrentPage) {
      dispatch(getMyDevices(address, newPage, 30, searchmydevicename));
    }
    if (searchmydevicename) {
      dispatch(getMyDevices(address, 1, 30, searchmydevicename));
    }
  }, [
    dispatch,
    address,
    mydeviceCurrentPage,
    mydeviceTotalPage,
    searchmydevicename,
  ]);

  return (
    <>
      {!address ? (
        <CenterContent>
          <DefaultWallet onClick={() => dispatch(togglemodal(true, 1))} />
        </CenterContent>
      ) : (
        <>
          <SearchContainer margin="100px">
            <Search
              placeholder="Search Devices"
              value={searchmydevicename}
              handleChange={(e) =>
                dispatch(
                  getFormDetails({
                    props: ["searchmydevicename"],
                    value: e.target.value,
                  })
                )
              }
            />
          </SearchContainer>
          <ScrollContainer height={"500px"}>
            <Container>
              {gettingmydevices & (mydevices.length === 0) ? (
                <PageLoader />
              ) : mydevices.length > 0 ? (
                mydevices.map((each, i) => {
                  return (
                    <DeviceBox
                      key={i}
                      link={`/my_device/statistics/${each.tokenId}/${each.deviceId}`}
                      img={"/assets/img/nft.svg"}
                      // img={each.imageUri}
                      title={each.deviceName}
                      number={`#${each.tokenId}`}
                      onClick={() => {
                        dispatch(togglemodal(true, 2));
                        dispatch(
                          getFormDetails({
                            props: ["tokenId"],
                            value: each.tokenId,
                          })
                        );
                        dispatch(
                          getFormDetails({
                            props: ["deviceName"],
                            value: each.deviceName,
                          })
                        );
                      }}
                      connected={
                        each.simulatedSessionResponse
                          ? each.simulatedSessionResponse.hasActiveSession
                          : false
                      }
                      isp={each.isp}
                      speed={each.connectionSpeed}
                      tier={each.deviceTier}
                      manufacturer={each.manufacturer}
                    />
                  );
                })
              ) : (
                <CenterDevices>
                  <CenterDevices>
                    <img src="/assets/img/light.svg" alt="empty" />
                  </CenterDevices>{" "}
                  You have no devices!
                </CenterDevices>
              )}
            </Container>
          </ScrollContainer>{" "}
        </>
      )}
    </>
  );
};
