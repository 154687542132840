import _const from "../../constants/types";
import axios from "axios";
import config from "../config";

//Get Distributed Rewards
export const getDistributedRewards = (
  deviceId,
  LookbackPeriodInHours,
  ChartLookbackPeriodInDays,
  ChartLookbackPeriodInMonths,
  ChartLookbackPeriodInYears
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DISTRIBUTED_REWARDS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Reward/stats?deviceId=${deviceId}&LookbackPeriodInHours=${LookbackPeriodInHours}&ChartLookbackPeriodInDays=${ChartLookbackPeriodInDays}&ChartLookbackPeriodInMonths=${ChartLookbackPeriodInMonths}&ChartLookbackPeriodInYears=${ChartLookbackPeriodInYears}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DISTRIBUTED_REWARDS,
          payload: false,
        });
        dispatch({
          type: _const.DISTRIBUTED_REWARDS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DISTRIBUTED_REWARDS,
          payload: false,
        });
      });
  };
};

//Get Staked Statistics
export const getStakedStatistics = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_STAKED_STATISTICS,
      payload: true,
    });
    axios
      .get(`https://api-staking.wicrypt.com/api/v1/stake-report/overview`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_STAKED_STATISTICS,
          payload: false,
        });
        dispatch({
          type: _const.STAKED_STATISTICS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_STAKED_STATISTICS,
          payload: false,
        });
      });
  };
};

//Get claim history
export const getClaimHistory = (
  deviceId,
  walletAddress,
  periodInHours,
  periodInDays
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_CLAIMING_HISTORY,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Reward/claim-histroy?DeviceId=${deviceId}&WalletAddress=${walletAddress}&LookbackPeriodInHours=${periodInHours}&ChartLookbackPeriodInDays=${periodInDays}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_CLAIMING_HISTORY,
          payload: false,
        });
        dispatch({
          type: _const.CLAIM_HISTORY,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_CLAIMING_HISTORY,
          payload: false,
        });
      });
  };
};
