export const lightMode = {
  blue: "#25346A",
  blue_gradient: "#25346A",
  yellow: "#E5B910",
  white: "#FFFFFF",
  white2: "#FBFBFB",
  grey: "#C4C4C4",
  grey2: "#6B6B6B",
  default_text_color: "#000000",
  default_card_color: "#F4F4F4",
  default_highlight_color: "#D3D3D3",
  grey3: "#F4F4F4",
  grey4: "#9C9C9C",
  grey5: "#B6B6B6",
  grey6: "#676767",
  grey7: "666666",
  light_grey: "rgba(102, 102, 102, 1)",
  gradient_black: "rgba(0, 0, 0, 1)",
  black: "#161626",
  black2: "#000000",
  red: "#E66A62",
  green: "#5FBE91",

  //Text sizes
  textLg: "7.125rem",
  textMd: "3rem",
  textSm: "2rem",
  textXs: "1rem",
  textXXs: "0.7rem"
};

export const darkMode = {
  //  darkMode colors
};

export const generalColors = {
  tint: "rgba(0, 0, 0, 0.4)"
};

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
  default_text_color: "#FFFFFF"
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};
