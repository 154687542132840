import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFormDetails, getMarketStatistics } from "../../methods/actions";
import { MarketStatistics } from "../../container";

export const Market = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //FInd a better way to handle this
    dispatch(
      getFormDetails({
        props: ["activetab"],
        value: 1,
      })
    );
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [{ id: 1, name: "Statistics" }],
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 0,
      })
    );
    //This block of code
    dispatch(getMarketStatistics());
  }, [dispatch]);

  return (
    <>
      <MarketStatistics />
    </>
  );
};
