import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 10px 0px 10px 0px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  //margin: 10px 0px 10px 0px;
`;

export const HalfContainer = styled.div`
  width: 48%;
  margin: 0px 0px 0px 0px;
`;

export const CenterContent = styled.div`
  margin-top: 100px;
`;

export const InfoLabels = styled.div`
  display: inline-flex;
  align-items: center;

  h6 {
    margin-right: 5px;
  }
`;
