import styled from "styled-components";
//import { size } from "../../theme";

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopNav = styled.div`
  background: ${({ theme }) => theme.blue_gradient};
  width: 100%;
  flex: 3;
  overflow: hidden;
`;

export const Header = styled.header`
  margin-top: 32px;
  padding: 0px 20px;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
`;

export const HeaderTitle = styled.h1`
  font-size: 1.5rem;
  padding-bottom: 25px;
  color: ${({ theme }) => theme.white2};
`;

export const HeaderInfo = styled.p`
  color: ${({ theme }) => theme.white2};
  line-height: 21px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
`;

export const LogoContainer = styled.div`
  padding: 20px 20px 0px 20px;
`;

export const NavDetails = styled.div`
  flex: 1;
  padding: 0px 20px;
  max-height: ${(props) => `calc(100vh - ${props.height}px)`};
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.grey};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.grey};
`;
