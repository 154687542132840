import { ArrowCard } from "../components";
import { HalfContainer, FlexContainer } from "./style";
import { Loader } from "../components";
import { useSelector } from "react-redux";
import {
  numberWithCommas,
  precise_round,
  numberWithCommaswithoutdecimals,
} from "../methods/helper";
import { getFormDetails } from "../methods/actions";
import { useDispatch } from "react-redux";

export const MarketStatistics = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);
  const gettingMarketStatistics = useSelector(
    (state) => state.LoaderReducer.gettingMarketStatistics
  );
  return (
    <>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            description={"This is the current selling price of WNT Tokens."}
            pos={1}
            label={"Market Price"}
            value={
              gettingMarketStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                "$" + numberWithCommas(stats.marketPrice, 3)
              )
            }
            subvalue={
              gettingMarketStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                Number(precise_round(stats.priceChangePercentage24h, 4))
              )
            }
            handlemouse={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "This is the current selling price of WNT Tokens.",
                })
              )
            }
            handlemouseleave={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"Market Cap"}
            pos={2}
            description={"This is the current value of WNT in supply"}
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "This is the current value of WNT in supply",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingMarketStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                "$" +
                numberWithCommaswithoutdecimals(
                  precise_round(stats.marketCap, 2)
                )
              )
            }
            naturalnumber={true}
            subvalue={
              gettingMarketStatistics ? (
                <Loader color={"#E5b910"} />
              ) : (
                Number(precise_round(stats.marketCapChangePercentage24h, 2))
              )
            }
          />
        </HalfContainer>
      </FlexContainer>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label={"Circulating Supply"}
            description={"This is the total WNT amount in circulation."}
            handlemouse={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "This is the total WNT amount in circulation.",
                })
              )
            }
            handlemouseleave={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingMarketStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(stats.circulatingSupply)
              )
            }
            subvalue={"WNT"}
            color={"grey2"}
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"Max Supply"}
            description={
              "This is the maximum amount of WNT that can ever  exist."
            }
            pos={3}
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the maximum amount of WNT that can ever  exist.",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingMarketStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(stats.maxSupply)
              )
            }
            subvalue={"WNT"}
            color={"grey2"}
          />
        </HalfContainer>
      </FlexContainer>
    </>
  );
};
