import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CitiesCard, ScrollContainer, Search } from "../components";
import { getDeviceCities, getFormDetails } from "../methods/actions";
import { PageLoader } from "./pageloader";
import { numberWithCommaswithoutdecimals } from "../methods/helper";
import { CenterDevices, Card, SearchContainer } from "./style";

export const Cities = () => {

  const dispatch = useDispatch();
  const {currentCityPage, alldevicecities, totalCityPages} = useSelector((state) => state.DeviceReducer);
  const city = useSelector((state) => state.GeneralReducer.city);
  const gettingDeviceCities = useSelector(
    (state) => state.LoaderReducer.gettingDeviceCities
  );

  useEffect(() => {
    let currPage =  currentCityPage + 1
    if(totalCityPages > currentCityPage){
      dispatch(getDeviceCities(city, currPage, 30));
    }
  }, [dispatch, city, currentCityPage, totalCityPages ]);

  return (
    <>
      <SearchContainer margin="100px">
        <Search
          placeholder="Search  Cities"
          handleChange={(e) =>
            dispatch(
              getFormDetails({
                props: ["city"],
                value: e.target.value,
              })
            )
          }
        />
      </SearchContainer>
      {gettingDeviceCities && alldevicecities.length === 0 ? (
        <PageLoader />
      ) : (
        <>
          <ScrollContainer height={"600px"}>
            {alldevicecities.length > 0 ? (
              alldevicecities.map((city, i) => (
                <Card key={i}>
                  <CitiesCard
                    city={city.city}
                    flag={city.flagIconUrl}
                    country={city.country}
                    no_of_devices={`${numberWithCommaswithoutdecimals(
                      city.totalDevice
                    )} Device(s) deployed (${
                      city.percentageActive
                    }% currently active)`}
                    link={`/city/stats/${city.city}`}
                  />
                </Card>
              ))
            ) : (
              <CenterDevices>
                <CenterDevices>
                  <img src="/assets/img/light.svg" alt="empty" />
                </CenterDevices>
                Sorry, No cities have been pin pointed for the devices
              </CenterDevices>
            )}
          </ScrollContainer>
        </>
      )}
    </>
  );
};
