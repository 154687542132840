import React from "react";
import {
  RadioButtonLabel,
  RadioButtonContainer,
  RadioButtonInput
} from "./style";

export const RadioButton = ({ onClick, label, checked, disabled, name }) => {
  return (
    <RadioButtonContainer onClick={onClick}>
      <RadioButtonInput
        type={"radio"}
        checked={checked}
        disabled={disabled}
        name={name}
      />
      <RadioButtonLabel htmlFor={name}>{label}</RadioButtonLabel>
    </RadioButtonContainer>
  );
};
