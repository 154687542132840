import styled from "styled-components";

export const Container = styled.div`
  // &::-webkit-scrollbar {
  //   width: 0px;
  // }

  // /* Track */
  // &::-webkit-scrollbar-track {
  //   border-radius: 10px;
  // }

  // /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background-color: ${({ theme }) => theme.grey};
  //   border-radius: 10px;
  // }

  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background-color: ${({ theme }) => theme.grey};
  }
`;
