import _const from "../../constants/types";
import axios from "axios";
import config from "../config";

//Gets all Outliers
export const getAllOutliers = (page, pageNumber, walletAddress) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ALL_OUTLIERS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/OutlierNft?page=${page}&pageNumber=${pageNumber}&walletAddress=${walletAddress}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_ALL_OUTLIERS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_OUTLIERS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ALL_OUTLIERS,
          payload: false,
        });
      });
  };
};

//Gets Outliers detail
export const getOutlierDetails = (
  walletAddress,
  lookbackPeriodInHours,
  chartLookbackPeriodInDays
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_OUTLIER_DETAIL,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/OutlierNft/Detail?walletAddress=${walletAddress}&lookbackPeriodInHours=${lookbackPeriodInHours}&chartLookbackPeriodInDays=${chartLookbackPeriodInDays}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_OUTLIER_DETAIL,
          payload: false,
        });
        dispatch({
          type: _const.OUTLIER_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_OUTLIER_DETAIL,
          payload: false,
        });
      });
  };
};

//Gets a list of NFT Devices for a particular outlier
export const getOutlierDevices = (
  walletAddress,
  pageNumber,
  pageSize,
  searchoutlierdevices
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_OUTLIER_DEVICES,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Device/MyNfts?walletAddress=${walletAddress}&pageNumber=${pageNumber}&pageSize=${pageSize}&deviceName=${searchoutlierdevices}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_OUTLIER_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.OUTLIER_DEVICES,
          payload: res.data.data.records,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_OUTLIER_DEVICES,
          payload: false,
        });
      });
  };
};

//Get outlier statistics
export const getOutlierStatistics = (
  lookbackPeriodInHours,
  chartLookbackPeriodInDays
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_OUTLIER_STATISTICS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Statistics/Overview?lookbackPeriodInHours=${lookbackPeriodInHours}&chartLookbackPeriodInDays=${chartLookbackPeriodInDays}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_OUTLIER_STATISTICS,
          payload: false,
        });
        dispatch({
          type: _const.OUTLIER_STATISTICS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_OUTLIER_STATISTICS,
          payload: false,
        });
      });
  };
};

//Clear outliers
export const clearAllOutliers = () => {
  return (dispatch) => {
    dispatch({
      type: _const.CLEAR_ALL_OUTLIERS,
      payload: true,
    });
  };
};
