import { Container, Text, ImageContainer } from "./style";
import { Button } from "../button";

export const DefaultWallet = ({ onClick }) => {
  return (
    <Container>
      <ImageContainer>
        <img src={"/assets/gif/walletAnimation.gif"} alt={""} />
      </ImageContainer>
      <Text>Connect your wallet to view devices</Text>
      <Button
        buttonClass={"secondary"}
        onClick={onClick}
        label={"Connect Wallet"}
      />
    </Container>
  );
};
