import React from "react";
import { useDispatch } from "react-redux";
import { togglemodal, getFormDetails } from "../../methods/actions";
import {
  Card,
  DeviceName,
  FlexContainer,
  SFlexContainer,
  Details,
  EarnedAmount,
} from "./style";

const TopEarnerCard = ({ deviceName, pos, amt, amtInusd, earner }) => {
  let dispatch = useDispatch();
  return (
    <Card
      pos={pos}
      onClick={() => {
        dispatch(togglemodal(true, 5));
        dispatch(
          getFormDetails({
            props: ["earner"],
            value: earner,
          })
        );
      }}
    >
      <SFlexContainer>
        <FlexContainer>
          <div>
            <img
              src={
                pos === 1
                  ? "./assets/svgs/gold.svg"
                  : pos === 2
                  ? "./assets/svgs/bronze.svg"
                  : "./assets/svgs/silver.svg"
              }
              alt="firstpos"
            />
          </div>
          <Details>
            <DeviceName> {deviceName}</DeviceName>
            <EarnedAmount> {amt + " WNT ~ $" + amtInusd}</EarnedAmount>
          </Details>
        </FlexContainer>
        <div>
          <img src={"/assets/svgs/arrow.svg"} alt={"arrow"} />
        </div>
      </SFlexContainer>
    </Card>
  );
};

export default TopEarnerCard;
