//Search function
const searchFunction = (query, array, searchpath) => {
  query = query.toLowerCase();
  let expression = `.*${query}.*`;
  let regex = new RegExp(expression, "g");
  let result = array.filter((each) => {
    return each[searchpath].toLowerCase().match(regex);
  });
  return result;
};

function genMAC() {
  var hexDigits = "0123456789ABCDEF";
  var macAddress = "";
  for (var i = 0; i < 6; i++) {
    macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
    macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
    if (i !== 5) macAddress += ":";
  }
  return macAddress;
}

//Format Number
const numberWithCommas = (n, c, d, t) => {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  let s = n < 0 ? "-" : "";
  let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  let j;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

//chek that number is valid
const validateamount = (value) => {
  return /^[1-9]\d*$/.test(value);
};

//format transaction hash
const formathash = (hash, count = 7) => {
  if (hash) {
    let firsthalf = hash.slice(0, count);
    let secondhalf = hash.slice(-3);
    let formattedhash = firsthalf + "..." + secondhalf;
    return formattedhash;
  }
};

//Duration
const durationMinutes = () => {
  var arr = [];
  for (let i = 1; i < 61; i++) {
    arr.push(
      <option key={i} value={i}>
        {i + " Minute(s)"}
      </option>
    );
  }
  return arr;
};

function numberWithCommaswithoutdecimals(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

function searchItem(text, array) {
  let output = [];
  if (text) {
    array.forEach((item) => {
      if (text.toLowerCase() === item.slice(0, text.length).toLowerCase()) {
        output.push(item);
      }
    });
  }
  return output;
}

//Round off Numbers
function precise_round(num, dec) {
  if (typeof num !== "number" || typeof dec !== "number") return false;

  var num_sign = num >= 0 ? 1 : -1;

  return (
    Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)
  ).toFixed(dec);
}

function calcDistance(lat1, lat2, lon1, lon2) {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  // calculate the result
  return c * r;
}

export {
  searchFunction,
  genMAC,
  numberWithCommas,
  validateamount,
  durationMinutes,
  formathash,
  numberWithCommaswithoutdecimals,
  precise_round,
  calcDistance,
  searchItem,
};
