import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterContainer,
  FilterSection,
  CenterFilter,
  IconContainer,
} from "./style";
import {
  togglemodal,
  getFormDetails,
  updateLeaderBoard,
} from "../../../methods/actions";
import moment from "moment";

const Filter = () => {
  const dispatch = useDispatch();
  const selectedTimeline = useSelector(
    (state) => state.GeneralReducer.selectedTimeline
  );
  const leaderboardstart = useSelector(
    (state) => state.GeneralReducer.leaderboardstart
  );
  const leaderboardstop = useSelector(
    (state) => state.GeneralReducer.leaderboardstop
  );
  const selectedWeek = useSelector(
    (state) => state.GeneralReducer.selectedWeek
  );
  let currentWeek = useSelector((state) => state.GeneralReducer.currentWeek);
  let maxWeek = useSelector((state) => state.GeneralReducer.maxWeek);

  const handleLeft = () => {
    if (selectedWeek > 1) {
      dispatch(updateLeaderBoard());
      dispatch(
        getFormDetails({
          props: ["selectedWeek"],
          value: selectedWeek - 1,
        })
      );
      dispatch(
        getFormDetails({
          props: ["selectedTimeline"],
          value: {},
        })
      );
    }
  };

  const handleRight = () => {
    if (currentWeek + maxWeek > selectedWeek) {
      dispatch(updateLeaderBoard());
      dispatch(
        getFormDetails({
          props: ["selectedWeek"],
          value: selectedWeek + 1,
        })
      );
      dispatch(
        getFormDetails({
          props: ["selectedTimeline"],
          value: {},
        })
      );
    }
  };

  return (
    <CenterFilter>
      <FilterContainer>
        <FilterSection>
          {" "}
          <div onClick={() => handleLeft()}>
            <img src="./assets/svgs/caretLeft.svg" alt="caretLeft" />
          </div>
          <p>
            {" "}
            {selectedTimeline.timeline === "Custom" && leaderboardstart !== ""
              ? moment(leaderboardstart).format("DD/MM/YYYY") +
                " - " +
                moment(leaderboardstop).format("DD/MM/YYYY")
              : !selectedTimeline.timeline
              ? "Week " + selectedWeek
              : selectedTimeline.timeline}
          </p>
          <div onClick={() => handleRight()}>
            <img src="./assets/svgs/caretRight.svg" alt="caretLeft" />
          </div>
        </FilterSection>
      </FilterContainer>
      <IconContainer onClick={() => dispatch(togglemodal(true, 3))}>
        {" "}
        <img src="./assets/svgs/filter.svg" alt="filter" />
        <p> Filter</p>
      </IconContainer>
    </CenterFilter>
  );
};

export default Filter;
