
import { useSelector, useDispatch } from "react-redux";
import { Loader, DeviceOverview } from "../components";
import { copyToClipboard } from "../methods/actions";
import { formathash } from "../methods/helper";
import { OutlierContainer, WalletAddress, Hash, Title } from "./style";

export const OutlierNavDetail = () => {
  const dispatch = useDispatch();
  const outlierdetail = useSelector(
    state => state.OutlierReducer.outlierdetail
  );
  const clipboardText = useSelector(state => state.GeneralReducer.clipboardText);
  const gettingOutlierDetail = useSelector(
    state => state.LoaderReducer.gettingOutlierDetail
  );

  return (
      <DeviceOverview
        breadcrumb={""}
        title={
          <div>
            <Title> Outlier</Title>
            <OutlierContainer>
              <img src={outlierdetail.avatarUrl} height="20px" alt={""} />
              <WalletAddress>
                {gettingOutlierDetail ? <Loader color={"#E5B910"} /> :  formathash(outlierdetail.walletAddress)}
              </WalletAddress>
              <Hash
                onClick={() =>
                  dispatch(copyToClipboard(outlierdetail.walletAddress))
                }
                src={
                  clipboardText === outlierdetail.walletAddress
                    ? "/assets/svgs/activehash.svg"
                    : "/assets/svgs/hash.svg"
                }
                alt={"hash"}
              />
            </OutlierContainer>
          </div>
        }
      ></DeviceOverview>
  );
};
