import _const from "../../constants/types";
import axios from "axios";
import config from "../config";

//Gets all Inputs
export const getFormDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: _const.FILL_OUT_FORM,
      payload: payload,
    });
  };
};

//Toggle Modal
export const togglemodal = (payload, value) => {
  return (dispatch) => {
    dispatch({
      type: _const.TOGGLE_MODAL,
      payload,
      value,
    });
  };
};

//Copy text
export const copyToClipboard = (copyText) => {
  const textField = document.createElement("textarea");
  textField.innerText = copyText;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  return (dispatch) => {
    dispatch({
      type: _const.COPIED_TO_CLIPBOARD,
      payload: copyText,
    });
  };
};

//Search Explorer
export const searchExplorer = (searchParam) => {
  return (dispatch) => {
    dispatch({
      type: _const.SEARCHING_EXPLORER,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Search?searchParam=${searchParam}`)
      .then((res) => {
        dispatch({
          type: _const.SEARCHING_EXPLORER,
          payload: false,
        });
        dispatch({
          type: _const.SEARCH_RESULTS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.SEARCHING_EXPLORER,
          payload: false,
        });
      });
  };
};

//Get user location
export const getUserLocation = () => {
  return (dispatch) => {
    let location = navigator.geolocation;
    if (location) {
      location.getCurrentPosition(function (loc) {
        dispatch(
          getFormDetails({
            props: ["center"],
            value: [loc.coords.latitude, loc.coords.longitude],
          })
        );
      }, showError);
    } else {
      alert("The browser does not upport geolocation");
    }
  };
};

function showError(error) {
  if (error.PERMISSION_DENIED) {
    alert("You have denied the request for geolocation");
  }
}

//Get ISPS
export const getIsps = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ISP,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Device/isps`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_ISP,
          payload: false,
        });
        dispatch({
          type: _const.ALL_ISP,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ISP,
          payload: false,
        });
      });
  };
};
