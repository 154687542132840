import React from "react";
import { useSelector } from "react-redux";
import EarnersCard from "../../../components/earnerscard";
import { ContainerFlex, Title } from "./style";
import { numberWithCommas } from "../../../methods/helper";
import moment from "moment";

const OtherEarners = () => {
  let earners = useSelector((state) => state.DeviceReducer.earners);
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);
  const selectedTimeline = useSelector(
    (state) => state.GeneralReducer.selectedTimeline
  );
  const selectedWeek = useSelector(
    (state) => state.GeneralReducer.selectedWeek
  );
  const leaderboardstart = useSelector(
    (state) => state.GeneralReducer.leaderboardstart
  );
  const leaderboardstop = useSelector(
    (state) => state.GeneralReducer.leaderboardstop
  );

  return (
    <div>
      <ContainerFlex>
        <Title>
          {" "}
          Others{" "}
          {!selectedTimeline.timeline
            ? "from Week " + selectedWeek
            : selectedTimeline.timeline === "Custom" && leaderboardstart !== ""
            ? "from" +
              moment(leaderboardstart).format("DD/MM/YYYY") +
              " - " +
              moment(leaderboardstop).format("DD/MM/YYYY")
            : " from " + selectedTimeline.timeline}{" "}
        </Title>
      </ContainerFlex>
      {earners.slice(3).map((each) => {
        return (
          <EarnersCard
            key={each.rank}
            pos={each.rank}
            deviceName={each.deviceName}
            amt={numberWithCommas(each.totalRewards, 2)}
            amtInusd={numberWithCommas(
              each.totalRewards * stats.marketPrice,
              2
            )}
            earner={each}
          />
        );
      })}
    </div>
  );
};

export default OtherEarners;
