import { ConnectorNames } from "./types";
import Metamask from "./walleticons/metamask.svg";
import WalletConnect from "./walleticons/walletconnect.svg";
// import FortMatic from "./walleticons/fortmatic.svg";
// import CoinBase from "./walleticons/coinbase.svg";

const connectors = [
  {
    title: "Metamask",
    icon: "metamask",
    image: Metamask,
    connectorId: ConnectorNames.Injected
  },
  {
    title: "WalletConnect",
    icon: "walletconnect",
    image: WalletConnect,
    connectorId: ConnectorNames.WalletConnect
  }
  // {
  //   title: "Fortmatic",
  //   icon: "fortmatic",
  //   image: FortMatic,
  //   connectorId: ConnectorNames.WalletConnect
  // },
  // {
  //   title: "Coinbase",
  //   icon: "coinbase",
  //   image: CoinBase,
  //   connectorId: ConnectorNames.CoinBase
  // }
];

export default connectors;
export const connectorLocalStorageKey = "connectorId";
