import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Statistics, Transaction, ClaimHistory } from "./container";
import { getFormDetails } from "../../methods/actions";

export const DeviceStats = () => {
  const dispatch = useDispatch();
  const activetab = useSelector((state) => state.GeneralReducer.activetab);

  const onedevicestatistics = useSelector(
    (state) => state.DeviceReducer.onedevicestatistics
  );

  useEffect(() => {
    //Find a better way to handle this
    dispatch(
      getFormDetails({
        props: ["activetab"],
        value: 1,
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 2,
      })
    );
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [
          { id: 1, name: "Statistics" },
          { id: 2, name: "Transactions" },
          { id: 3, name: "Claim History" },
        ],
      })
    );
    dispatch(
      getFormDetails({
        props: ["newroute"],
        value: { id: 2, title: onedevicestatistics.deviceName },
      })
    );
    dispatch(
      getFormDetails({
        props: ["oldroutes"],
        value: [
          { id: 2, link: "/", title: "Home" },
          { id: 1, link: "/devices", title: "Devices" },
        ],
      })
    );
    //This block of code
  }, [dispatch, onedevicestatistics.deviceName]);

  return (
    <>
      {activetab === 1 ? (
        <Statistics />
      ) : activetab === 2 ? (
        <Transaction />
      ) : (
        <ClaimHistory />
      )}
    </>
  );
};
