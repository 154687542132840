import { ArrowCard } from "../components";
import { HalfContainer, FlexContainer } from "./style";
import { Loader } from "../components";
import { useSelector, useDispatch } from "react-redux";
import {
  numberWithCommaswithoutdecimals,
  precise_round,
} from "../methods/helper";
import { getFormDetails } from "../methods/actions";

export const StakedStatistics = () => {
  const dispatch = useDispatch();
  const stakedstats = useSelector((state) => state.RewardReducer.stakedstats);
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);
  const gettingStakedStatistics = useSelector(
    (state) => state.LoaderReducer.gettingStakedStatistics
  );
  return (
    <>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label={"Staked accounts"}
            pos={1}
            description={
              "This is the total number of unique wallets that have staked their WNT."
            }
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the total number of unique wallets that have staked their WNT.",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingStakedStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(stakedstats.numberOfStakingAccount)
              )
            }
            // subvalue={
            //   gettingStakedStatistics ? (
            //     <Loader color={"#E5B910"} />
            //   ) : (
            //     stakedstats.priceChangePercentage24h
            //   )
            // }
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"Estimated APR"}
            pos={2}
            description={
              "This is the analysed  interest for WNT staked on our platform. "
            }
            handlemouse={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the analysed  interest for WNT staked on our platform. ",
                })
              )
            }
            handlemouseleave={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingStakedStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(stakedstats.estimatedAPR) + "%"
              )
            }
            naturalnumber={true}
            // subvalue={
            //   gettingStakedStatistics ? (
            //     <Loader color={"#E5b910"} />
            //   ) : (
            //     stakedstats.marketCapChangePercentage24h
            //   )
            // }
          />
        </HalfContainer>
      </FlexContainer>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label="Total Staked WNT"
            description={
              "This is the total amount of WNT currently locked on our staking platform."
            }
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the total amount of WNT currently locked on our staking platform.",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingStakedStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(
                  precise_round(stakedstats.totalStakedWNT, 2)
                )
              )
            }
            subvalue={"WNT"}
            color={"grey2"}
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            pos={3}
            description={
              "This is the percentage of total WNT supply staked on our platform."
            }
            label={"Supply Staked"}
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the percentage of total WNT supply staked on our platform.",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingStakedStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(stakedstats.supplyStaked) + "%"
              )
            }
            //subvalue={-0.2}
            color={"grey2"}
          />
        </HalfContainer>
      </FlexContainer>
    </>
  );
};
