
import { useSelector} from "react-redux";
import { DeviceOverview, CityName, Loader} from "../components";

export const CityNavDetails = () => {

  const citydevicestatistics = useSelector(
    state => state.DeviceReducer.citydevicestatistics
  );
  const gettingCityStatistics = useSelector(
    state => state.LoaderReducer.gettingCityStatistics
  );

  return (
    <DeviceOverview
    title={
      gettingCityStatistics ? (
        <Loader />
      ) : (
        citydevicestatistics.city + ", " + citydevicestatistics.country
      )
    }
  >
    <CityName
      flag={
        gettingCityStatistics ? <Loader /> : citydevicestatistics.flagIconUrl
      }
      name={
        gettingCityStatistics ? <Loader /> : citydevicestatistics.country
      }
    />
  </DeviceOverview>
  );
};
