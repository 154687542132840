import styled from "styled-components";

export const Container = styled.div`
  h5 {
    font-size: ${({ size }) => size};
    color: ${({ theme }) => theme.yellow};
    font-weight: normal;
  }

  p {
    color: #a7a9bc;
    margin-top: 0px;
    font-size: 0.64rem;
  }
`;
