import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { renderToStaticMarkup } from "react-dom/server";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Polyline,
} from "react-leaflet";
import { divIcon, marker } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  MapBody,
  AddressContainer,
  ConnectionStatus,
  Distance,
  DistanceContainer,
  MarkerIcons,
} from "./style";
import {
  getAllDevices,
  togglemodal,
  getMarketStatistics,
  getFormDetails,
  getIsps,
} from "../../methods/actions";
import { formathash } from "../../methods/helper";
import Leaflet from "leaflet";
import connectors from "../../methods/utils/connectorConfig";
import { MapOptions } from "./mapoptions";
import { calcDistance, precise_round } from "../../methods/helper";
import Isps from "./isps";
import DefaultdeviceIcon from "./deviceIcon";
import { TopMapOptions } from "./topoptions";
import L from "leaflet";

function PopupContainer({ name = "", isp = "MTN", speed = "", active = "", manufacturer= "" }) {
  return (
    <Popup>
      <p className="popupText"> {`Name: ` + name} </p>{" "}
      <p className="popupText"> {`ISP: ` + isp}</p>
      <p className="popupText"> {`Connection Speed: ` + speed}</p>
      <p class="popupText">{ manufacturer && `Manufacturer/Blockchain/OS: ${manufacturer}`}</p>
      <ConnectionStatus active={active} className="popupText">
        {" "}
        {active ? `Connected ` : `Not Connected`}
      </ConnectionStatus>
    </Popup>
  );
}

function Markers({ data }) {
  const dispatch = useDispatch();
  const map = useMap();
  const center = useSelector((state) => state.GeneralReducer.center);
  const selectedisp = useSelector((state) => state.GeneralReducer.selectedisp);
  const activemapoption = useSelector(
    (state) => state.GeneralReducer.activemapoption
  );
  const onedevice = useSelector(
    (state) => state.DeviceReducer.onedevicestatistics
  );

  const handleDistance = useCallback(() => {
    let pointers = [];
    map.on("click", function (e) {
      if (pointers.length === 2) {
        pointers = [];
        dispatch(
          getFormDetails({
            props: ["point"],
            value: 0,
          })
        );
        dispatch(
          getFormDetails({
            props: ["polyline"],
            value: [],
          })
        );
      } else if (pointers.length === 1) {
        pointers.push([e.latlng.lat, e.latlng.lng]);
        dispatch(
          getFormDetails({
            props: ["polyline"],
            value: pointers,
          })
        );
        dispatch(
          getFormDetails({
            props: ["point"],
            value: 2,
          })
        );
      } else {
        pointers.push([e.latlng.lat, e.latlng.lng]);
        dispatch(
          getFormDetails({
            props: ["point"],
            value: 1,
          })
        );
      }
    });
  }, [dispatch, map]);

  useEffect(() => {
    if (center[0] !== undefined) {
      map.flyTo(center, 15, { duration: 3 });
      if (onedevice.deviceName) {
        L.popup()
          .setLatLng(center)
          .setContent(
            `<div>  
            <p class="popupText"> Name: ${onedevice.deviceName} </p>
            <p class="popupText"> ISP: ${onedevice.isp}</p>
            <p class="popupText"> Connection Speed: ${
              onedevice.connectionSpeed
            }</p>
            ${
              onedevice.manufacturer
                && `<p class="popupText"> Manufacturer/Blockchain/OS: ${onedevice.manufacturer}</p>`
            } 
            ${
              onedevice.isActive
                ? '<p class="popupText green"> Connected</p>'
                : '<p class="popupText red"> Not Connected</p>'
            } 
           
          </div>`
          )
          .openOn(map)
      }
    }
    if (activemapoption === 1) {
      handleDistance();
    } else {
      map.off("click");
      dispatch(
        getFormDetails({
          props: ["point"],
          value: 0,
        })
      );
      dispatch(
        getFormDetails({
          props: ["polyline"],
          value: [],
        })
      );
    }
    if (activemapoption === 4) {
      map.flyTo([6.44943, 7.49281], 3, { duration: 2 });
    }
  }, [center, map, activemapoption, onedevice, handleDistance, dispatch]);

  const setZoom = (marker) => {
    if (map._zoom <= 15) {
      map.flyTo([marker.position.latitude, marker.position.longitude], 15, {
        duration: 3,
      });
    }
  };
  const iconMarkup = renderToStaticMarkup(
    <MarkerIcons color={selectedisp.color} />
  );
  const deviceIcon = divIcon({
    className: "k2",
    html: iconMarkup,
  });

  let newdata = selectedisp.isp
    ? data.filter((each) => each.isp.includes(selectedisp.isp))
    : data;

  return (
    newdata.length > 0 &&
    newdata.map((marker, index) => {
      return (
        <Marker
          position={[marker.position.latitude, marker.position.longitude]}
          icon={
            marker.isp.includes(selectedisp.isp)
              ? deviceIcon
              : DefaultdeviceIcon
          }
          key={index}
          eventHandlers={{
            click: () => setZoom(marker),
          }}
        >
          <PopupContainer
            name={marker.deviceName}
            isp={marker.isp}
            speed={marker.connectionSpeed}
            active={marker.sessionResponse.hasActiveSession}
            manufacturer={marker.manufacturer}
          />
        </Marker>
      );
    })
  );
}

export const MapLayout = () => {
  const dispatch = useDispatch();

  const alldevices = useSelector((state) => state.DeviceReducer.alldevices);
  const point = useSelector((state) => state.GeneralReducer.point);
  const polyline = useSelector((state) => state.GeneralReducer.polyline);
  const address = useSelector((state) => state.ConnectWalletReducer.address);
  const activemapoption = useSelector(
    (state) => state.GeneralReducer.activemapoption
  );
  const walletInUse = useSelector(
    (store) => store.ConnectWalletReducer.walletInUse
  );

  const alldeviceCurrentPage = useSelector(
    (state) => state.DeviceReducer.alldeviceCurrentPage
  );
  const alldeviceTotalPage = useSelector(
    (state) => state.DeviceReducer.alldeviceTotalPage
  );

  useEffect(()=>{
    dispatch(getIsps());
    dispatch(getMarketStatistics());
  }, [])

  useEffect(() => {
    let newPage = alldeviceCurrentPage + 1;
    if (alldeviceTotalPage > alldeviceCurrentPage) {
      dispatch(getAllDevices(newPage, 100));
    }
  }, [dispatch, alldeviceCurrentPage, alldeviceTotalPage]);

  const corner1 = Leaflet.latLng(-90, -200);
  const corner2 = Leaflet.latLng(90, 200);
  const bounds = Leaflet.latLngBounds(corner1, corner2);
  const limeOptions = { color: "white" };

  const {
    REACT_APP_MAP_BOX_TOKEN,
    REACT_APP_MAP_BOX_USERNAME,
    REACT_APP_MAP_BOX_STYLE_ID,
  } = process.env;

  return (
    <MapBody id="map">
      <MapContainer
        zoomControl={false}
        maxBoundsViscosity={1.0}
        maxBounds={bounds}
        continuousWorld={true}
        center={[6.44943, 7.49281]}
        zoom={3}
        //minZoom={1}
        //scrollWheelZoom={false}
        animate={true}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          accessToken={REACT_APP_MAP_BOX_TOKEN}
          url={`https://api.mapbox.com/styles/v1/${REACT_APP_MAP_BOX_USERNAME}/${REACT_APP_MAP_BOX_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${REACT_APP_MAP_BOX_TOKEN}`}
          attribution=""
        />
        <Markers data={alldevices} />
        <Polyline pathOptions={limeOptions} positions={polyline} />
      </MapContainer>
      <DistanceContainer>
        {point === 2 ? (
          <Distance>
            {" "}
            {precise_round(
              calcDistance(
                polyline[0][0],
                polyline[1][0],
                polyline[0][1],
                polyline[1][1]
              ),
              2
            ) + " km"}{" "}
          </Distance>
        ) : point === 1 ? (
          <Distance>Place second point</Distance>
        ) : point === 0 && activemapoption === 1 ? (
          <Distance>Place first point</Distance>
        ) : null}{" "}
      </DistanceContainer>
      {activemapoption === 3 ? <Isps /> : null}
      <MapOptions />
      <TopMapOptions />
      {address ? (
        <AddressContainer onClick={() => dispatch(togglemodal(true, 1))}>
          {" "}
          <img
            src={connectors[walletInUse === "Metamask" ? 0 : 1].image}
            height={20}
            style={{ marginRight: 10, marginLeft: 10 }}
            alt="wallet-icon"
          />
          {formathash(address)}
        </AddressContainer>
      ) : (
        ""
      )}
    </MapBody>
  );
};
