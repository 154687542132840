import styled from "styled-components";
import { size } from "../../theme";

export const TabContainer = styled.div`
  margin-top: 10px;
`;

export const TabLabel = styled.label`
  display: inline-flex;
  justify-content: center;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 25%;
  text-align: center;
  color: #ffffff;
  position: relative;
  transition: 0.25s background ease;
  cursor: pointer;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  border-bottom: ${(props) => (props.active ? "3px solid #FFFFFF" : "none")};
  margin-bottom: 0.5px;
  &::after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    display: block;
    bottom: 0;
    opacity: 0;
    left: 0;
    transition: 0.25s ease;
  }
  &:hover::after {
    opacity: 1;
  }
  :hover {
    border-bottom: 3px solid #ffffff;
    transition: border-bottom 2s;
  }
  @media (max-width: ${size.mobileM}) {
    font-size: 0.7rem;
  }
  :active {
    border-bottom: 3px solid #ffffff;
    opacity: 1;
    transition: border-bottom 2s opacity 2s;
  }
`;

export const TabInput = styled.input`
  display: none;
`;
