import { Container, Title, ActiveRoute, InactiveRoute } from "./style";
import {Link} from "react-router-dom"

export const BreadCrumb = ({ oldroutes, newroute }) => {
  return (
    <Container>
      <Title>
        {oldroutes.map(each => {
          return(<Link to={each.link}>  
            <InactiveRoute >{each.title + "- "}</InactiveRoute>
          </Link>)
        })}
        <ActiveRoute> {newroute.title}</ActiveRoute>
      </Title>
    </Container>
  );
};
