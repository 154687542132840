import { combineReducers } from "redux";

//importing all reducer files
import GeneralReducer from "./generalReducer";
import ConnectWalletReducer from "./connectwalletReducer";
import LoaderReducer from "./loaderReducer";
import DeviceReducer from "./deviceReducer";
import BlockHeightReducer from "./blockheightReducer";
import OutlierReducer from "./outlierReducer";
import RewardReducer from "./rewardReducer";
import MarketReducer from "./marketReducer"

export default combineReducers({
  GeneralReducer,
  ConnectWalletReducer,
  LoaderReducer,
  DeviceReducer,
  BlockHeightReducer,
  OutlierReducer,
  RewardReducer,
  MarketReducer
});
