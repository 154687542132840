import { Container } from "./style";

export const SmallValue = ({ size, value, label }) => {
  return (
    <Container size={size}>
      <h5>{value}</h5>
      <p>{label}</p>
    </Container>
  );
};
