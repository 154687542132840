import _const from "../../constants/types";
import axios from "axios";
import config from "../config";

//Gets market statistics
export const getMarketStatistics = () => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_MARKET_STATISTICS,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/Statistics/wnt-market-stats`
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_MARKET_STATISTICS,
          payload: false
        });
        dispatch({
          type: _const.MARKET_STATISTICS,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_MARKET_STATISTICS,
          payload: false
        });
      });
  };
};
