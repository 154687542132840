import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;

  button {
    margin-top: 51px;
  }
`;

export const Text = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.grey2};
  font-size: 0.85rem;
  margin-top: 60px;
`;

export const ImageContainer = styled.div`
  width: 100px;
  height: 100px;

  img {
    width: 100%;
  }
`;
