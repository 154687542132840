import ReduxThunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import combineReducers from "./methods/reducers";
import Themeing from "./Themeing";

const store = createStore(combineReducers, {}, applyMiddleware(ReduxThunk));

function App() {
  return (
    <Provider store={store}>
      <Themeing />
    </Provider>
  );
}

export default App;
