import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding:0;
        font-size: 1rem;
        font-family: 'Poppins', sans-serif;
        
    }
    html {
        margin: 0;
        padding: 0;
        
    }    
    body{
        margin: 0;
        padding:0;
        height: 100%;
        overflow: hidden;
        
    }

    h1, h2, h3, h4, h6, h5, h6, p {
        margin: 0;
        padding: 0;
    }
    
    h1 {
        font-size: 1.88rem;
        font-weight: 600;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3{
        font-size: 1.28rem;
        font-weight: 500;
    }

    h4 {
        font-size: 1.13rem;
        font-weight: 600;
    }

    h5 {
        font-size: 0.85rem;
    }

    h6 {
        font-size: 0.77rem;
        font-weight: 500;
    }

    p {
        font-size: 1rem;
        font-weight: normal;
    }
    a{
        text-decoration: none;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    input[type="number"] {
    -moz-appearance: textfield;
    }

    @keyframes beatHeart {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.1);
        }
        40% {
          transform: scale(1);
        }
        60% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      } 

    .custom-icon{
        height: 8px;
        width: 8px;
        background-color: #15CF74;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        animation: .8s infinite beatHeart;
    }
    .link-decoration{
        text-decoration: none;
    }
    .grey{
        color: #6B6B6B;
        font-size:0.8rem;
        cursor: pointer;
    }
    .mr-5{
        margin-right: 5px;
    }
    .ml-5{
        margin-left: 5px;
    }
    .mt-2{
        margin-top: 2px;
    }
    .popupText{
        font-size: 0.8rem;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
    .green{
        color: green;
    }
    .red{
        color: red;
    }
    .navlink{
        float: right;
        color: #c4c4c4;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 0.8rem;
    }
    .react-calendar{
        background: #F4F4F4;
        border: none;
    }
    .react-calendar__month-view__days__day--neighboringMonth{
        color: #25346A;
    }
    .react-calendar__tile{
        color: #25346A;
    }
    .react-calendar__month-view__days__day--weekend {
        color: #25346A;
    }
    .react-calendar__tile--active {
        background: #E5B910;
        color: #fefefe !important;
        border-radius: 100%;
        height: 50px;
        width: 50px;
    }

    
`;
