import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchFunction } from "../../../../methods/helper";
import { getFormDetails } from "../../../../methods/actions";
import { Search } from "../../../../components";

const SearchSection = () => {
  let dispatch = useDispatch();

  const searchdeviceName = useSelector(
    (state) => state.GeneralReducer.searchdeviceName
  );
  const devicesbyvolume = useSelector(
    (state) => state.DeviceReducer.devicesbyvolume
  );
  const alldevicesincity = useSelector(
    (state) => state.DeviceReducer.alldevicesincity
  );
  const devicesbyconnections = useSelector(
    (state) => state.DeviceReducer.devicesbyconnections
  );

  const implementSearch = (value) => {
    dispatch(
      getFormDetails({
        props: ["searchdeviceName"],
        value: value,
      })
    );
    let res1 = searchFunction(searchdeviceName, devicesbyvolume, "deviceName");
    let res2 = searchFunction(searchdeviceName, alldevicesincity, "deviceName");
    let res3 = searchFunction(
      searchdeviceName,
      devicesbyconnections,
      "deviceName"
    );
    dispatch(
      getFormDetails({
        props: ["searchVolumeResult"],
        value: res1,
      })
    );
    dispatch(
      getFormDetails({
        props: ["searchDevicesInCityResult"],
        value: res2,
      })
    );
    dispatch(
      getFormDetails({
        props: ["searchConnectionsResult"],
        value: res3,
      })
    );
  };

  return (
    <Search
      placeholder={"Search Devices"}
      value={searchdeviceName}
      handleChange={(e) => implementSearch(e.target.value)}
    />
  );
};

export default SearchSection;
