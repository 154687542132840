import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TopNavContainer, SearchInput, SearchContainer, Search } from "./style";
import { Link } from "react-router-dom";
import SearchResult from "./search";
import { searchExplorer, getFormDetails } from "../../methods/actions";

const TopNav = ({ layout }) => {
  const dispatch = useDispatch();
  const searchParam = useSelector((state) => state.GeneralReducer.searchParam);

  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const searchResultsRef = useRef(null);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      dispatch(searchExplorer(searchParam));
      setSearchResultsVisible(!searchResultsVisible)
    }
  };

  const handleSearchClick = () => {
    dispatch(searchExplorer(searchParam));
    setSearchResultsVisible(!searchResultsVisible)
  };

  const handleClickOutside = (event) => {
    if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
      setSearchResultsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <TopNavContainer layout={layout}>
      <SearchContainer>
        <SearchInput
          onChange={(e) =>
            dispatch(
              getFormDetails({
                props: ["searchParam"],
                value: e.target.value,
              })
            )
          }
          onKeyPress={handleKeyPress}
          type="text"
          placeholder="Search By Device Name or BSSID"
        />
        <Search
          onClick={handleSearchClick}
          src="/assets/svgs/search.svg"
          alt="search-icon"
        />
        {searchResultsVisible && (
          <div ref={searchResultsRef}>
            <SearchResult />
          </div>
        )}
      </SearchContainer>
      <div>
        <Link to="/outliers" className="navlink">
          Outliers
        </Link>
        <Link to="/market" className="navlink">
          Market
        </Link>
        <Link to="/block_height" className="navlink">
          Blocks
        </Link>
        <Link to="/devices" className="navlink">
          Devices
        </Link>
      </div>
    </TopNavContainer>
  );
};

export default TopNav;
