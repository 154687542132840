import styled from "styled-components";

export const InputBox = styled.input`
  border: none;
  border-bottom: 0.5px solid #C4C4C4;
  color: ${({ theme }) => theme.grey4};
  font-size: 14px;
  padding: 10px 0px 20px 50px;
  margin: 20px 0px 5px 0px;
  width: 90%;
  background: transparent;
  height: 100%;
  font-weight: 400;
  line-height: 21px;
  &:focus {
    outline: none;
    border-bottom: 2px solid #C4C4C4;
  }
  ::placeholder {
    color: ${({ theme }) => theme.grey4};
    font-size: 14px;
    font-weight: 300;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.grey4};
    font-size: 14px;
    font-weight: 400;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${({ theme }) => theme.grey4};
    font-size: 14px;
    font-weight: 400;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const SearchIcon = styled.img`
  position: absolute;
  bottom: 8px;
  left: 3%;
  top: 43%;
`;
