import { useDispatch } from "react-redux";
import { TabLabel, TabContainer, TabInput } from "./style";
import { getFormDetails } from "../../methods/actions";

export const Tabs = ({ tablist, selectedtab }) => {
  const dispatch = useDispatch();
  return (
    <TabContainer>
      {tablist.map((each) => {
        return (
          <span
            onClick={() =>
              dispatch(
                getFormDetails({
                  props: ["activetab"],
                  value: each.id,
                })
              )
            }
            key={each.id}
          >
            <TabInput type="radio" id={each.id} name="tab" />
            <TabLabel htmlFor="tab1" active={each.id === selectedtab}>
              {each.name}
            </TabLabel>
          </span>
        );
      })}
    </TabContainer>
  );
};
