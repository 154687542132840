import styled from "styled-components";

export const BubleContainer = styled.div`
  display: inline-block;
  position: absolute;
  bottom: ${(props) =>
    props.pos === 1
      ? "-317%"
      : props.pos === 2
      ? "-310%"
      : props.pos === 3
      ? "-320%"
      : "131%"};
  right: ${(props) => (props.pos === 2 || props.pos === 3 ? "0" : "auto")};
  min-width: 200px;
  min-height: 72px;
  //height: auto;
  background-color: #000000b8;
  border-radius: 4px;
  color: #ffffff;
  :before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: ${(props) => (props.pos === 2 || props.pos === 3 ? "auto" : "10px")};
    right: ${(props) => (props.pos === 2 || props.pos === 3 ? "5px" : "auto")};
    top: ${(props) => (props.pos === 3 ? "-27.5%" : "auto")};
    bottom: ${(props) =>
      props.pos === 1
        ? "72px"
        : props.pos === 2
        ? "72px"
        : props.pos === 3
        ? "56px"
        : "-20px"};
    border: 10px solid;
    border-color: #000000b8 transparent transparent #000000b8;
    transform: ${(props) =>
      props.pos === 1
        ? "rotate(270deg)"
        : props.pos === 2 || props.pos === 3
        ? "rotate(540deg)"
        : "none"};
  }
`;

export const DetailText = styled.div`
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
  font-size: ${({ theme }) => theme.textXXs};
`;

export const DetailContainer = styled.span`
  position: relative;
`;
