import _const from "../../constants/types";
import moment from "moment";

const initialState = {
  devicestatistics: {},
  deviceDetails: {},
  latestdevices: [],
  mydevices: [],
  connected: false,
  connectionerr: "",
  alldevicecities: [],
  citydevicestatistics: {},
  alldevicesincity: [],
  devicegraphlabels: [],
  devicegraphvalues: [],
  onedevicestatistics: {},
  alldevices: [],
  deviceTransactions: [],
  alldeviceCurrentPage: 0,
  alldeviceTotalPage: 1,
  mydeviceCurrentPage: 0,
  mydeviceTotalPage: 1,
  onedeviceearnlabel: [],
  onedeviceearnvalue: [],
  devicesInCitiesTotalPage: 1,
  devicesInCitiesCurrentPage: 0,
  devicesbyvolume: [],
  devicebyvolumeCurrentPage: 0,
  devicebyvolumeTotalPages: 1,
  devicesbyconnections: [],
  devicesbyconnectionsCurrentPage: 0,
  devicesbyconnectionsTotalPages: 1,
  totalrewardedDevices: 0,
  deviceRewards: 0,
  earners: [],
  earnerscurrentpage: 0,
  earnerstotalpage: 1,
  latestDevice: {},
  currentCityPage: 0,
  totalCityPages: 1
};

const DeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ALL_DEVICES:
      return {
        ...state,
        alldevices: [...state.alldevices, ...action.payload.records],
        alldeviceCurrentPage: action.payload.currentPage,
        alldeviceTotalPage: action.payload.totalPages,
      };

    case _const.ALL_MY_DEVICES:
      let output = [];
      if (action.payload.currentPage === 1) {
        output = action.payload.records;
      } else {
        output = [...state.mydevices, ...action.payload.records];
      }
      return {
        ...state,
        mydevices: output,
        mydeviceCurrentPage: action.payload.currentPage,
        mydeviceTotalPage: action.payload.totalPages,
      };
    case _const.ALL_DEVICE_STATISTICS:
      return {
        ...state,
        devicestatistics: action.payload,
      };

    case _const.ALL_DEVICE_STATISTICS_CHARTPOINT:
      let dates = [];
      let pointvalues = [];
      if (action.payload.chartPoints.length !== 0) {
        let data = action.payload.chartPoints;
        data.forEach((each) => {
          dates.push(moment(each.dateTime).format("MMMM Do YYYY"));
          pointvalues.push(each.count);
        });
      }
      return {
        ...state,
        deviceDetails: action.payload,
        devicegraphlabels: dates,
        devicegraphvalues: pointvalues,
      };

    case _const.ALL_LATEST_DEVICES:
      return { ...state, latestdevices: action.payload };

    case _const.ALL_DEVICE_CITIES:
      return { ...state,
         alldevicecities: [...state.alldevicecities, ...action.payload.records],
         currentCityPage: action.payload.currentPage, 
         totalCityPages: action.payload.totalPages };

    case _const.DEVICES_BY_VOLUME:
      let volumeres = [];
      if (action.payload.page === 1) {
        volumeres = action.payload.devices;
      } else {
        volumeres = [...state.devicesbyvolume, ...action.payload.devices];
      }
      return {
        ...state,
        devicesbyvolume: volumeres,
        devicebyvolumeCurrentPage: action.payload.page,
        devicebyvolumeTotalPages: action.payload.totalPages,
      };

    case _const.DEVICES_BY_CONNECTIONS:
      let connectionres = [];
      if (action.payload.page === 1) {
        connectionres = action.payload.devices;
      } else {
        connectionres = [
          ...state.devicesbyconnections,
          ...action.payload.devices,
        ];
      }
      return {
        ...state,
        devicesbyconnections: connectionres,
        devicebyconnectionsCurrentPage: action.payload.page,
        devicebyconnectionsTotalPages: action.payload.totalPages,
      };

    case _const.DEVICE_LEADERBOARD:
      let leaderres = [];
      if (action.payload.result.currentPage === 1) {
        leaderres = action.payload.result.records;
      } else {
        leaderres = [...state.earners, ...action.payload.result.records];
      }
      return {
        ...state,
        deviceRewards: action.payload.distributedRewards,
        totalrewardedDevices: action.payload.totalDevices,
        earners: leaderres,
        earnerscurrentpage: action.payload.result.currentPage,
        earnerstotalpage: action.payload.result.totalPages,
      };

    case _const.UPDATE_LEADERBOARD:
      return {
        ...state,
        earners: [],
        deviceRewards: 0,
        totalrewardedDevices: 0,
        earnerscurrentpage: 0,
        earnerstotalpage: 1,
      };

    case _const.ALL_DEVICES_IN_CITY:
      let res = [];
      if (action.payload.currentPage === 1) {
        res = action.payload.records;
      } else {
        res = [...state.alldevicesincity, ...action.payload.records];
      }
      return {
        ...state,
        alldevicesincity: res,
        devicesInCitiesCurrentPage: action.payload.currentPage,
        devicesInCitiesTotalPage: action.payload.totalPages,
      };

    case _const.SIMULATION_FAILED:
      return { ...state, connectionerr: action.payload };

    case _const.DEVICE_CONNECTED:
      return { ...state, connected: true, mydevices: [] };

    case _const.CITY_DEVICE_STATISTICS:
      return {
        ...state,
        citydevicestatistics: action.payload,
        onedevicestatistics: {},
      };

    case _const.CLEAR_OLD_STATISTICS:
      return {
        ...state,
        onedevicestatistics: {},
        onedeviceearnlabel: [],
        onedeviceearnvalue: [],
      };

    case _const.ONE_DEVICE_STATISTICS:
      let key = [];
      let value = [];
      if (action.payload.earnings.earningChartPoints.length !== 0) {
        let data = action.payload.earnings.earningChartPoints;
        data.forEach((each) => {
          key.push(moment(each.date).format("MMMM Do YYYY"));
          value.push(each.amount + "WNT");
        });
      }
      return {
        ...state,
        onedevicestatistics: action.payload,
        onedeviceearnlabel: key,
        onedeviceearnvalue: value,
      };

    case _const.DEVICE_TRANSACTIONS:
      return { ...state, deviceTransactions: action.payload };

    case _const.LATEST_DEVICE:
      return{...state, latestDevice: action.payload}

    default:
      return state;
  }
};

export default DeviceReducer;
