import { useDispatch, useSelector } from "react-redux";
import { DeviceOverview, CityName, Loader } from "../components";
import { Spacer } from "../components/deviceOverview/style";
import { DeviceOwner } from "./style";
import { copyToClipboard } from "../methods/actions";
import { formathash } from "../methods/helper";

export const DeviceNavDetails = () => {
  const dispatch = useDispatch();
  const onedevicestatistics = useSelector(
    (state) => state.DeviceReducer.onedevicestatistics
  );
  const gettingOneDeviceStatistics = useSelector(
    (state) => state.LoaderReducer.gettingOneDeviceStatistics
  );
  const clipboardText = useSelector(
    (state) => state.GeneralReducer.clipboardText
  );

  return (
    <>
      <DeviceOverview
        title={
          <>
            {gettingOneDeviceStatistics ? (
              <Loader color={"#E5B910"} />
            ) : (
              onedevicestatistics.deviceName
            )}
          </>
        }
        isRedDot={true}
        active={onedevicestatistics.isActive}
      >
        <CityName
          flag={onedevicestatistics.flagIconUrl}
          name={
            gettingOneDeviceStatistics ? (
              <Loader color={"#E5B910"} />
            ) : onedevicestatistics ? (
              onedevicestatistics.city
            ) : (
              "--"
            )
          }
        />
        <Spacer />
        <CityName
          flag={"/assets/svgs/wifi-alt.svg"}
          name={
            gettingOneDeviceStatistics ? (
              <Loader color={"#E5B910"} />
            ) : onedevicestatistics ? (
              onedevicestatistics.connectionSpeed
            ) : (
              "--"
            )
          }
        />
        <CityName
          flag={""}
          name={
            gettingOneDeviceStatistics ? (
              <Loader color={"#E5B910"} />
            ) : `ISP: ` + onedevicestatistics ? (
              onedevicestatistics.isp
            ) : (
              "--"
            )
          }
        />
        <CityName
          flag={""}
          name={
            gettingOneDeviceStatistics ? (
              <Loader color={"#E5B910"} />
            ) : onedevicestatistics ? (
              <DeviceOwner
                onClick={() =>
                  dispatch(copyToClipboard(onedevicestatistics.walletAddress))
                }
                active={clipboardText === onedevicestatistics.walletAddress}
              >
                {" "}
                {formathash(onedevicestatistics.walletAddress)}{" "}
              </DeviceOwner>
            ) : (
              "--"
            )
          }
        />
      </DeviceOverview>
    </>
  );
};
