import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recreateWeb3 } from "./methods/utils/useAuth";
import { ConnectionBody, ConnectDevice, Timeline, MoreInfo } from "./container";
import SelectRange from "./container/carlendar";
import { Modal } from "./components";
import { togglemodal } from "./methods/actions";

const ModalOptions = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(recreateWeb3());
  }, [dispatch]);

  const type = useSelector((state) => state.GeneralReducer.type);
  const modalState = useSelector((state) => state.GeneralReducer.modalState);

  return (
    <>
      {modalState && type === 1 ? (
        <Modal
          icon={"/assets/icons/connectwallet.png"}
          setModal={() => dispatch(togglemodal(false, 0))}
          children={<ConnectionBody />}
          title="Connect Wallet"
        />
      ) : modalState && type === 2 ? (
        <Modal
          icon={"/assets/icons/wifi.png"}
          setModal={() => dispatch(togglemodal(false, 0))}
          children={<ConnectDevice />}
          title="Connect To Device"
        />
      ) : modalState && type === 3 ? (
        <Modal
          icon={"/assets/icons/carlendar.svg"}
          setModal={() => dispatch(togglemodal(false, 0))}
          children={<Timeline />}
          title="Timeline"
        />
      ) : modalState && type === 4 ? (
        <Modal
          icon={"/assets/icons/carlendar.svg"}
          setModal={() => dispatch(togglemodal(false, 0))}
          children={<SelectRange />}
          title="Timeline"
        />
      ) : modalState && type === 5 ? (
        <Modal
          icon={"/assets/icons/wifi.png"}
          setModal={() => dispatch(togglemodal(false, 0))}
          children={<MoreInfo />}
          title="Earner's Timeline Information"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ModalOptions;
