import { InputBox, InputContainer, InputIcon } from "./style";
import { Label } from "../label";

export const Input = ({
  label,
  placeholder,
  disabled,
  value,
  handleChange,
  handleClick,
  icon
}) => {
  return (
    <InputContainer>
      <Label label={label} />
      <InputBox
        placeholder={placeholder}
        defaultValue={value}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
      {icon ? (
        <InputIcon src={icon} onClick={handleClick} alt="input-icon" />
      ) : (
        ""
      )}
    </InputContainer>
  );
};
