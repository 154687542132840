import _const from "../../constants/types";
import moment from "moment";

const initialState = {
  history: [],
  rewards: [],
  stakedstats: {
    totalStakedWnt: 0,
    stakedAccount: 0,
    esitmatedAPR: 0,
    supplyStaked: 0,
  },
  totalrewards: 0,
  totalrewardsinwnt: 0,
  rewarddates: [],
  rewardamts: [],
  totalclaimed: 0,
};

const Rewardreducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.DISTRIBUTED_REWARDS:
      let dates = [];
      let pointvalues = [];
      if (action.payload.chartPoints.length !== 0) {
        let data = action.payload.chartPoints;
        data.forEach((each) => {
          dates.push(moment(each.date).format("MMMM Do YYYY"));
          pointvalues.push(each.totalReward);
        });
      }

      return {
        ...state,
        rewards: action.payload,
        totalrewards: action.payload.totalRewarInUsd,
        totalrewardsinwnt: action.payload.totalReward,
        rewarddates: dates,
        rewardamts: pointvalues,
      };

    case _const.STAKED_STATISTICS:
      return { ...state, stakedstats: action.payload };

    case _const.CLAIM_HISTORY:
      return {
        ...state,
        history: action.payload.claimHistory,
        totalclaimed: action.payload.wntClaimed,
      };

    default:
      return state;
  }
};

export default Rewardreducer;
