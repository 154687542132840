import {
  Container,
  CardLabel,
  CardValue,
  Rate,
  Percentage,
  RightValue,
  PercentageContainer,
  InfoLabels,
} from "./style";
import InfoDetails from "../../components/infoDetails";
import { Link } from "react-router-dom";

export const ArrowCard = ({
  label,
  value,
  percentage,
  subvalue,
  percentsubvalue,
  link,
  handlechange,
  naturalnumber,
  handlemouse,
  handlemouseleave,
  pos,
  description,
}) => {
  return (
    <>
      {link ? (
        <Link className={"link-decoration"} to={link}>
          <Container link={link}>
            <div>
              {" "}
              {/* <CardLabel> {label}</CardLabel> */}
              <InfoLabels>
                {" "}
                <CardLabel>{label} </CardLabel>{" "}
                {description ? (
                  <InfoDetails
                    handlemouse={handlemouse}
                    handlemouseleave={handlemouseleave}
                    currentdescription={description}
                    pos={pos}
                  />
                ) : null}
              </InfoLabels>
              <div>
                <CardValue> {value}</CardValue>
                <Rate height={"10px"} color={subvalue > 0 ? "green" : "red"}>
                  {subvalue ? (
                    <img
                      className="mr-5"
                      src={
                        subvalue > 0
                          ? "/assets/svgs/upward.svg"
                          : "/assets/svgs/downward.svg"
                      }
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  {subvalue ? subvalue : ""}
                </Rate>
              </div>
            </div>
            <RightValue>
              {percentage ? (
                <PercentageContainer>
                  <Percentage color={percentage > 0 ? "green" : "red"}>
                    {" "}
                    <img
                      className="mr-5"
                      src={
                        percentage > 0
                          ? "/assets/svgs/upward.svg"
                          : "/assets/svgs/downward.svg"
                      }
                      alt=""
                    />
                    {percentage}
                    {naturalnumber ? "" : "%"}
                  </Percentage>
                  <Rate color={"grey2"}>{percentsubvalue}</Rate>
                </PercentageContainer>
              ) : (
                ""
              )}
              {handlechange ? (
                <div onClick={handlechange}>
                  <img src={"/assets/svgs/arrow.svg"} alt={"arrow"} />
                </div>
              ) : (
                ""
              )}

              <img src={"/assets/svgs/arrow.svg"} alt={"arrow"} />
            </RightValue>
          </Container>
        </Link>
      ) : (
        <Container
          link={handlechange}
          onClick={handlechange ? handlechange : console.log("")}
        >
          <div>
            {" "}
            {/* <CardLabel> {label}</CardLabel> */}
            <InfoLabels>
              {" "}
              <CardLabel>{label} </CardLabel>{" "}
              {description ? (
                <InfoDetails
                  handlemouse={handlemouse}
                  handlemouseleave={handlemouseleave}
                  currentdescription={description}
                  pos={pos}
                />
              ) : null}
            </InfoLabels>
            <div>
              <CardValue> {value}</CardValue>
              {typeof subvalue === "number" ? (
                <Rate color={subvalue > 0 ? "green" : "red"}>
                  {subvalue ? (
                    <img
                      height={"10px"}
                      className="mr-5"
                      src={
                        subvalue > 0
                          ? "/assets/svgs/upward.svg"
                          : "/assets/svgs/downward.svg"
                      }
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  {subvalue} {naturalnumber ? "%" : ""}
                </Rate>
              ) : (
                <Rate color={"grey2"}>{subvalue ? subvalue : ""}</Rate>
              )}
            </div>
          </div>
          <RightValue>
            {percentage ? (
              <PercentageContainer>
                <Percentage color={percentage > 0 ? "green" : "red"}>
                  {" "}
                  <img
                    className="mr-5"
                    src={
                      percentage > 0
                        ? "/assets/svgs/upward.svg"
                        : "/assets/svgs/downward.svg"
                    }
                    alt=""
                  />
                  {percentage}
                  {naturalnumber ? "" : "%"}
                </Percentage>
                <Rate color={"grey2"}>{percentsubvalue}</Rate>
              </PercentageContainer>
            ) : (
              ""
            )}

            {handlechange ? (
              <div onClick={handlechange}>
                <img src={"/assets/svgs/arrow.svg"} alt={"arrow"} />
              </div>
            ) : (
              ""
            )}
          </RightValue>
        </Container>
      )}
    </>
  );
};
