import styled from "styled-components";

export const MapBody = styled.div`
  height: 100vh;
  width: 100%;
`;

export const CustomIcon = styled.div`
  height: 10px;
  width: 10px;
  background-color: #aafbb0;
  border-radius: 50%;
`;

export const AddressContainer = styled.div`
  background: rgba(212, 212, 212, 0.21);
  backdrop-filter: blur(4.58px);
  font-size: ${({ theme }) => theme.textXXs};
  border-radius: 60px;
  padding:10px;
  position absolute;
  color: white;
  bottom:10%;
  min-width: 100px;
  z-index:1000;
  left: 65%;
`;

export const MapIconContainer = styled.div`
  position absolute;
  bottom:10%;
  z-index:1000;
  right: 3%;
  
`;

export const TopMapIconContainer = styled.div`
  position absolute;
  top:10%;
  z-index:1000;
  right: 3%;
  
`;

export const IconContainer = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 100%;
  background: ${(props) => (props.active ? props.theme.yellow : "#eaeaff")};
  margin-top: 10px;
  margin-botttom: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
`;

export const ConnectionStatus = styled.p`
  color: ${(props) => (props.active ? "green" : props.theme.red)};
`;

export const Distance = styled.div`
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.yellow};
  color: ${(props) => props.theme.white};
  font-weight: 500;
  text-align: center;
`;

export const DistanceContainer = styled.div`
  position: absolute;
  bottom: 24%;
  z-index: 1000;
  right: 7%;
  font-size: 12px;
`;

export const IspsContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 10000;
  right: 0;
  background-color: #fbfbfb;
  border-radius: 8px;
  height: 100%;
  padding: 20px;
  width: 300px;
`;

export const IspHeader = styled.div`
  border-bottom: 0.5px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 30px;
  height: 10%;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-left: 10px;
  }
`;

export const ISPFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-left: 10px;
    animation: 0.8s infinite beatHeart;
  }
`;

export const IspBody = styled.div`
  padding: 5px;
  height: 90%;
  overflow-y: scroll;
`;

export const IspOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  p {
    height: 12px;
    width: 12px;
    background-color: ${(props) => (props.color ? props.color : "#15CF74")};
    border: 1px solid #d9d9d9;
    border-radius: 100%;
    margin-left: 0px;
    margin-right: 20px;
    padding-bottom: 0px;
  }
  h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => (props.active ? props.theme.black2 : props.theme.grey)};
  }
`;

export const Check = styled.div``;

export const MarkerIcons = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${(props) => (props.color ? props.color : "#15CF74")};
  border: 2px solid #d9d9d9;
  border-radius: 100%;
  animation: 0.8s infinite beatHeart;
`;

export const CenterDevices = styled.div`
  text-align: center;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey};
`;
