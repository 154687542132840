import styled from "styled-components";
import { device } from "../../theme";

export const Container = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.blue_gradient};
  padding: 0px;
  width: 100%;
  // left: 0;
  // top: 20.7%;
  bottom: 0;
  @media (max-width: ${device.laptop}) {
    // top: 18%;
    bottom: 0;
    padding: 20px 42px 0px;
    width: calc(100% - 84px);
  }
`;

export const TabContainer = styled.div``;
