import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.default_card_color};
  box-shadow: 0px 0px 8.17112px rgba(45, 58, 58, 0.02);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const Claimed = styled.p`
  color: ${({ theme }) => theme.green};
  font-size: 14px;
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;
export const WalletAddress = styled.p`
    margin-right: 10px;
    font-weight: 500,
    font-size: 16px;
    color: ${({ theme }) => theme.black};
    line-height: 24px;

`;
export const TimeStamp = styled.p`
  color: ${({ theme }) => theme.grey2};
  margin-left: 10px;
  font-size: ${({ theme }) => theme.textXXs};
  font-weight: 400;
`;
