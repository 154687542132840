import {
  Container,
  PriceText,
  PriceValue,
  PriceOverview,
  PriceInput,
  PriceBase,
  BillingContainer
} from "./style";

export const BillingRate = ({
  price,
  priceValue,
  rate,
  placeholder,
  handlechange,
  ...restProps
}) => {
  return (
    <Container>
      <h5>Set billing rate</h5>
      <BillingContainer>
        <PriceInput placeholder={placeholder} {...restProps} />
        <PriceOverview>
          <PriceText type="number" onChange={handlechange} value={price} />
          <PriceValue>{priceValue}</PriceValue>
        </PriceOverview>
        <PriceBase>{rate}</PriceBase>
      </BillingContainer>
    </Container>
  );
};
