import styled from "styled-components";

export const Card = styled.div`
  padding: 10px;
  background: ${(props) =>
    props.pos === 1
      ? "rgba(221, 156, 1, 0.2)"
      : props.pos === 2
      ? "rgba(187, 187, 187, 0.2)"
      : "rgba(205, 127, 50, 0.2)"};
  box-shadow: 0px 17.3636px 21.4492px rgba(231, 231, 231, 0.15);
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

export const SFlexContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const DeviceName = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  font-weight: 500;
  line-height: 21px;
`;

export const EarnedAmount = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.grey2};
  margin-top: 5px;
`;

export const Details = styled.div`
  margin-left: 30px;
`;
