import { CardWrapper, WalletName } from "./style";

export const WalletOptions = ({
  connectwallet,
  img,
  walletname,
  termsandconditions
}) => {
  return (
    <div>
      <CardWrapper disabled={!termsandconditions} onClick={connectwallet}>
        <img width={40} src={img} alt={walletname} />
      </CardWrapper>
      <WalletName>{walletname}</WalletName>
    </div>
  );
};
