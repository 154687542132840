import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 10px 0px 10px 0px;
`;

export const SearchContainer = styled.div`
  //margin-top: ${({ margin }) => margin};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HalfContainer = styled.div`
  width: 48%;
  margin: 0px;
`;

export const CenterContent = styled.div`
  margin-top: 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
`;

export const SectionWrapper = styled.div`
  margin-top: 40px;
`;

export const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    //justify-content: space-between;
  }
`;

export const InfoWrapper = styled.div`
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  background: #ffffff;
  border-radius: 2.22648px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ConnectDeviceContainer = styled.div`
  padding: 20px;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  & div {
    cursor: pointer;
    display: flex;
    align-items: center;

    & p {
      margin-left: 8px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
`;
export const CardCont = styled.div`
  margin-top: 10px;
`;

export const LoaderContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 50%;
`;

export const CenterDevices = styled.div`
  text-align: center;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey};
`;

export const Card = styled.div`
  margin-top: 10px;
`;

export const InfoLabels = styled.div`
  display: inline-flex;
  align-items: center;

  h6 {
    margin-right: 5px;
  }
`;
export const OutlierContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 11px;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.grey4};
  }
`;
export const WalletAddress = styled.p`
  color: ${({ theme }) => theme.white} !important;
  font-size: 18px !important;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Hash = styled.img`
  cursor: pointer;
`;

export const InfoView = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Info = styled.div`
  margin-top: 18px;
`;

export const CaretUpContainer = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
`;

export const CaretDownContainer = styled.div`
  position: absolute;
  bottom: 0;
  cursor: pointer;
`;

export const CaretContainer = styled.div`
  position: relative;
  height: 40px;
  margin-left: 10px;
`;

export const BlockContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DeviceOwner = styled.p`
  cursor: pointer;
  color: ${(props) =>
    props.active ? props.theme.yellow : props.theme.grey} !important;
`;

export const TimelineContainer = styled.div`
  padding: 30px;
`;

export const Option = styled.li`
  background: #ffffff;
  border: 0.511962px solid #e5b910;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const OptionContainer = styled.ul`
  list-style: none;
`;

export const Check = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  border: 1px solid #e5b910;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const CheckActive = styled.div`
  height: 8px;
  width: 8px;
  background-color: #e5b910;
  border-radius: 100%;
`;

export const CarlendarContainer = styled.div`
  margin: 20px;
`;

export const DetailContainer = styled.ul`
  padding: 10px 20px;
`;

export const DetailTitle = styled.p`
  padding: 0px 10px;
  padding-left: 0px;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid;
`;

export const DescriptionKey = styled.li`
  font-size: 12px;
  list-style: none;
  padding: 3px 0px;
  //text-transform: uppercase;
`;

export const DescriptionValue = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  text-transform: capitalize;
`;

export const Price = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  color: green;
`;
