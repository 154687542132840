import _const from "../../constants/types";
import moment from "moment";

const initialState = {
  alloutlierslist: [],
  outlierdetail: {
    avatarUrl:
      "https://res.cloudinary.com/chukwuemekanweke/image/upload/v1637146323/wicrypt_media_assets/explorer_assets/outlier_avatars/Frame_7008.png",
    walletAddress: "",
    numberOfDevices: 0,
    numberOfConnections: 0,
    wntBalance: 0,
    wntusdEquivalent: 0,
    totalWNTStaked: 0,
    earning: { totalEarnings: 0, earningChartPoints: [] },
  },
  outlierdevices: [],
  outlierStatistics: {},
  alloutliersCurrentPage: 0,
  alloutliersTotalPage: 1,
  earningvalues: [],
  earninglabel: [],
};

const OutlierReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ALL_OUTLIERS:
      let output = [];
      if (action.payload.currentPage === 1) {
        output.push(...action.payload.records);
      } else {
        output = [...state.alloutlierslist, ...action.payload.records];
      }
      return {
        ...state,
        alloutlierslist: output,
        alloutliersCurrentPage: action.payload.currentPage,
        alloutliersTotalPage: action.payload.totalPages,
      };

    case _const.CLEAR_ALL_OUTLIERS:
      return {
        ...state,
        alloutlierslist: [],
        alloutliersCurrentPage: 0,
        alloutliersTotalPage: 1,
      };
    case _const.OUTLIER_DETAILS:
      let dates = [];
      let pointvalues = [];
      if (action.payload.earning.earningChartPoints !== 0) {
        let data = action.payload.earning.earningChartPoints;
        data.forEach((each) => {
          dates.push(moment(each.date).format("MMMM Do YYYY"));
          pointvalues.push(each.amount + "WNT");
        });
      }
      return {
        ...state,
        outlierdetail: action.payload,
        earninglabel: dates,
        earningvalues: pointvalues,
      };

    case _const.OUTLIER_DEVICES:
      return { ...state, outlierdevices: action.payload };

    case _const.OUTLIER_STATISTICS:
      return { ...state, outlierStatistics: action.payload };

    default:
      return state;
  }
};

export default OutlierReducer;
