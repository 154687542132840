import styled from "styled-components";

export const Container = styled.div`
  //margin-top: 230px;
`;

export const CardCont = styled.div`
  margin-top: 20px;
  width: 48%;

  h6 {
    color: ${({ theme }) => theme.grey7};
  }

  h3 {
    margin-top: 5.89px;
    margin-bottom: 3.76px;
    color: ${({ theme }) => theme.gradient_black};
  }

  main {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 4.62px;
    }
  }
`;

export const Rating = styled.aside`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24.4px;
`;

export const GreenCircle = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.green};
  border-radius: 30px;
`;
export const RedCircle = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.red};
  border-radius: 30px;
`;

export const StatContainer = styled.div`
  margin-top: 20px;
`;

export const Filters = styled.div`
  display: flex;

  main {
    padding: 2px 5px;
    background-color: ${({ theme }) => theme.white};
    margin-right: 5px;
    font-size: 0.63rem;
    cursor: pointer;
    &:last-of-type {
      margin-left: 0px;
    }
  }
`;

export const StatisticsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CardLabel = styled.h6`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey2};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  //margin: 10px 0px 10px 0px;
`;

export const HalfContainer = styled.div`
  width: 48%;
  margin: 10px 0px 10px 0px;
`;

export const EarningContainer = styled.div`
  margin-top: 10px;
`;
