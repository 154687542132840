import styled from "styled-components";

export const PrimaryBtn = styled.button`
  background-color: ${({ theme }) => theme.blue};
  padding: ${({ padding }) => padding || "11px 53px"};
  font-size: ${({ fontSize }) => fontSize || "0.85rem"};
  color: ${({ theme }) => theme.white};
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.2;
  }
`;

export const SecondaryBtn = styled.button`
  background-color: transparent;
  padding: ${({ padding }) => padding || "11px 53px"};
  font-size: ${({ fontSize }) => fontSize || "0.85rem"};
  color: ${({ theme }) => theme.yellow};
  border-radius: 30px;
  border: 2px solid ${({ theme }) => theme.yellow};
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.2;
  }
`;

export const SecondaryAltBtn = styled.button`
  background-color: transparent;
  padding: ${({ padding }) => padding || "11px 53px"};
  font-size: ${({ fontSize }) => fontSize || "0.85rem"};
  color: ${({ theme }) => theme.blue};
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.blue};
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.2;
  }
`;
