import { useSelector } from "react-redux";
import Detail from "./detail";
import InfoIcon from "./infoicon";
import { DetailContainer } from "./style";

const AllDetail = ({
  currentdescription,
  pos,
  handlemouse,
  handlemouseleave,
}) => {
  const description = useSelector((state) => state.GeneralReducer.description);
  return (
    <DetailContainer onMouseEnter={handlemouse} onMouseLeave={handlemouseleave}>
      {description === currentdescription ? (
        <Detail currentdescription={currentdescription} pos={pos} />
      ) : (
        ""
      )}
      <div>
        <InfoIcon currentdescription={currentdescription} />
      </div>
    </DetailContainer>
  );
};

export default AllDetail;
