import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Statistics, Devices } from "./container";
import { getFormDetails, getCityDeviceStatistics } from "../../methods/actions";
import { useParams } from "react-router-dom";

export const CityStats = () => {
  let dispatch = useDispatch();
  const activetab = useSelector((state) => state.GeneralReducer.activetab);
  const citydevicestatistics = useSelector(
    (state) => state.DeviceReducer.citydevicestatistics
  );

  let { cityname } = useParams();

  useEffect(() => {
    dispatch(getCityDeviceStatistics(cityname));
  }, [dispatch, cityname]);

  useEffect(() => {
    dispatch(
      getFormDetails({
        props: ["center"],
        value: [citydevicestatistics.latitude, citydevicestatistics.longitude],
      })
    );
    //Find a better way to handle this
    dispatch(
      getFormDetails({
        props: ["activetab"],
        value: 1,
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 5,
      })
    );
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [
          { id: 1, name: "Statistics" },
          { id: 2, name: "Devices" },
        ],
      })
    );
    dispatch(
      getFormDetails({
        props: ["newroute"],
        value: { id: 1, title: citydevicestatistics.city },
      })
    );
    dispatch(
      getFormDetails({
        props: ["oldroutes"],
        value: [
          { id: 1, link: "/", title: "Home" },
          { id: 2, link: "/devices", title: "Cities" },
        ],
      })
    );
    //This block of code
  }, [dispatch, citydevicestatistics]);

  return <>{activetab === 1 ? <Statistics /> : <Devices />}</>;
};
