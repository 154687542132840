import { useDispatch, useSelector } from "react-redux";
import { MapIconContainer, IconContainer } from "./style";
import { getUserLocation, getFormDetails } from "../../methods/actions";

export const MapOptions = () => {
  const dispatch = useDispatch();
  const activemapoption = useSelector(
    (state) => state.GeneralReducer.activemapoption
  );
  function handleUserLocation() {
    if (activemapoption === 2) {
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 0,
        })
      );
    } else {
      dispatch(getUserLocation());
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 2,
        })
      );
    }
  }

  function handlePointers() {
    if (activemapoption === 1) {
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 0,
        })
      );
      dispatch(
        getFormDetails({
          props: ["point"],
          value: 0,
        })
      );
    } else {
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 1,
        })
      );
    }
  }

  function handleSignature() {
    if (activemapoption === 3) {
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 0,
        })
      );
    } else {
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 3,
        })
      );
    }
  }

  return (
    <MapIconContainer>
      <IconContainer
        active={activemapoption === 1}
        onClick={() => handlePointers()}
      >
        <img src="/assets/icons/ruler.svg" alt="ruler" />
      </IconContainer>
      <IconContainer
        active={activemapoption === 2}
        onClick={() => handleUserLocation()}
      >
        <img src="/assets/icons/navigation.svg" alt="naviagtion" />
      </IconContainer>
      <IconContainer
        active={activemapoption === 3}
        onClick={() => handleSignature()}
      >
        <img src="/assets/icons/signature.svg" alt="signature" />
      </IconContainer>
    </MapIconContainer>
  );
};
