import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.default_card_color};
  box-shadow: 0px 0px 8.17112px rgba(45, 58, 58, 0.02);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  :hover {
    background: ${(props) =>
      props.link
        ? props.theme.default_highlight_color
        : props.theme.default_card_color};
    transition: background 3s;
    transition-timing-function: ease;
  }
`;

export const RewardContainer = styled.div`
  background: ${({ theme }) => theme.default_card_color};
  box-shadow: 0px 0px 8.17112px rgba(45, 58, 58, 0.02);
  border-radius: 8px;
  position: relative;
`;

export const ChartDetails = styled.div`
  padding: 17.06px 20px 0px 17.06px;
  h6 {
    font-size: ${({ theme }) => theme.textXXs};
    color: ${({ theme }) => theme.grey2} !important;
  }

  h5 {
    font-size: 1.28rem;
    margin-top: 5.98px;
    font-weight: normal;
    color: ${({ theme }) => theme.gradient_black};
  }
`;

export const Filter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16.52px 22px;
  display: flex;
`;

export const CardLabel = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey2};
`;

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImageContainer = styled.div`
  margin-right: 20px;
  margin-top: 30px;
`;

export const Percentage = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.yellow};
  display: flex;
  align-items: center;
`;

export const PercentageContainer = styled.div`
  margin-right: 30px;
`;

export const Rate = styled.p`
  font-size: 0.6rem;
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.yellow};
  margin-top: 5.76px;
  text-decoration: none;
`;

export const PercentageArrow = styled.div`
  display: flex;
`;

export const ChartContainer = styled.div``;

export const FilterOption = styled.div`
  background-color: #ffffff;
  color: ${(props) => (props.active ? props.theme.blue : props.theme.black2)};
  padding: 2px;
  text-align: center;
  font-size: 10px;
  margin: 5px;
  width: 20px;
  line-height: 15px;
  border-radius: 4px;
  cursor: pointer;
`;
export const InfoLabels = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;

  h6 {
    margin-right: 5px;
  }
`;
