import styled from "styled-components";

export const Container = styled.div``;

export const CardCont = styled.div`
  margin-top: 10px;
  h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BlockNumber = styled.h3`
  color: ${({ theme }) => theme.black};
`;

export const FlexContainer = styled.div`
  display: flex;
`;
