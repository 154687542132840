import styled from "styled-components";

export const Container = styled.div`
  padding: 19px 20px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 2.22648px;
`;

export const BillingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PriceInput = styled.input`
  border: none;
  margin-top: 16px;
  margin: 16px auto;
  background: transparent;

  &::placeholder {
    font-size: 0.75rem;
    text-align: center;
    color: ${({ theme }) => theme.grey5};
  }
`;

export const PriceOverview = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceText = styled.input`
  text-align: center;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.black2};
  width: 100px;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const PriceValue = styled.h4`
  font-size: 1rem;
  margin-left: 8px;
  color: ${({ theme }) => theme.grey6};
  text-align: center;
`;

export const PriceBase = styled.h5`
  margin-top: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.grey6};
`;
