import styled from "styled-components";
import { generalColors } from "../../theme";

export const Tint = styled.div`
  background-color: ${generalColors.tint};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
`;

export const ModalContainer = styled.div`
  width: 381px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.grey3};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const ModalHeader = styled.div`
  padding: 30px 34.8px;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    margin-left: 19.6px;
    color: ${({ theme }) => theme.black};
    flex: 1;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;

    i {
      color: ${({ theme }) => theme.black};
    }
  }
`;

export const InfoContainer = styled.div`
  //padding: 30px 20px;
`;
