import {
  InputBox,
  InputContainer,
  InputIcon,
  DropDownContentContainer,
  DropDownContent
} from "./style";
import { Label } from "../label";

export const Dropdown = ({
  label,
  placeholder,
  value,
  handleChange,
  icon,
  content,
  showcontent,
  disabled,
  handleDropdown
}) => {
  return (
    <InputContainer onClick={handleDropdown}>
      <Label label={label} />
      <InputBox placeholder={placeholder} disabled={disabled} value={value} />
      {showcontent ? (
        <DropDownContentContainer>
          {content.map((each, i) => {
            return (
              <DropDownContent onClick={handleChange} key={i}>
                {each}
              </DropDownContent>
            );
          })}
        </DropDownContentContainer>
      ) : (
        ""
      )}

      {icon ? <InputIcon src={icon} alt="input-icon" /> : ""}
    </InputContainer>
  );
};
