import { useSelector } from "react-redux";
import {
  DeviceOverview,
  CityName,
  Loader
} from "../components";
import {
  numberWithCommaswithoutdecimals,
  formathash
} from "../methods/helper";
import { useHistory } from "react-router";
import moment from "moment";
import {
  InfoView,
  Info,
  CaretUpContainer,
  CaretDownContainer,
  CaretContainer,
  BlockContainer
} from "./style";

export const BlockstatnavDetails = () => {
  const history = useHistory();
  const selectedblock = useSelector(
    state => state.BlockHeightReducer.selectedblock
  );

  const blockDetail = useSelector(
    state => state.BlockHeightReducer.blockDetail
  );
  const gettingBlockDetail = useSelector(
    state => state.LoaderReducer.gettingBlockDetail
  );

  const handleTopBlock = blockNumber => {
    if (blockNumber > 0) {
      let newBlock = blockNumber - 1;
      history.push(`/block/stats/${newBlock}`);
      window.location.reload(true);
    }
  };

  const handleBottomBlock = (blockNumber, lastblock) => {
    if (lastblock > blockNumber) {
      let newBlock = blockNumber + 1;
      history.push(`/block/stats/${newBlock}`);
      window.location.reload(true);
    }
  };

  return (
    <>
      <DeviceOverview
        title={
          <BlockContainer>
            {`Block ${numberWithCommaswithoutdecimals(
              blockDetail.blockNumber
            )}`}{" "}
            <CaretContainer>
              <CaretUpContainer
                onClick={() => handleTopBlock(blockDetail.blockNumber)}
              >
                <img
                  src={"/assets/svgs/caret-up.svg"}
                  height={"20px"}
                  alt={"arrow"}
                />
              </CaretUpContainer>
              <CaretDownContainer
                onClick={() =>
                  handleBottomBlock(
                    blockDetail.blockNumber,
                    selectedblock.blockNumber
                  )
                }
              >
                <img
                  src={"/assets/svgs/caret-down.svg"}
                  height={"20px"}
                  alt={"arrow"}
                />
              </CaretDownContainer>
            </CaretContainer>
          </BlockContainer>
        }
       
      >
        <InfoView>
          <CityName
            flag={"/assets/svgs/clock.svg"}
            name={
              gettingBlockDetail ? (
                <Loader />
              ) : (
                moment(blockDetail.blockTimeStamp).format("Do MMMM YYYY, h:mm")
              )
            }
          />
          <CityName
            flag={"/assets/svgs/switch.svg"}
            name={
              gettingBlockDetail ? (
                <Loader />
              ) : (
                `${numberWithCommaswithoutdecimals(
                  blockDetail.numberOfConnections
                )} connections`
              )
            }
          />
          <Info>
            <CityName
              flag={"/assets/svgs/blockHeight.svg"}
              name={
                gettingBlockDetail ? <Loader /> : formathash(blockDetail.hash)
              }
            />
          </Info>
        </InfoView>
      </DeviceOverview>
    
    </>
  );
};
