const _const = {
  TOKEN: "whajjOOks%%--s",
  STORAGE_TOKEN: "axa&sqq_storage",
  POLYGON_RPC_URL:
    "https://polygon-mainnet.g.alchemy.com/v2/pTHwvLT7CJWUKgvtLmwKtPS_PmhwQmTD",

  CRYPOCURRENCY: "BNB",
  CURRENCY: "BUSD",
  SHARE_CURRENCY: "VBUSD",
  HIDE_AMOUNT: "HIDE_AMOUNT",

  NETWORK_CHAINID: "0x38",

  QUEUE: "QUEUE",
  BACKGROUND_QUEUE: "BACKGROUND_QUEUE",
  ADDRESS: "ADDRESS",
  APYS: "ALL_APYS",
  NATIVE_BALANCE: "NATIVE_BALANCE",

  PRISTINE: "ALL_PRISTINE",
  //providerSet
  WEB3SETPROVIDER: "WEB3_SET_PROVIDER",

  //web3HasDisconnected
  WEB3_WALLETCONNECT_HAS_DISCONNECTED: "WEB3_WALLETCONNECT_DISCONNECTED",

  //NewNetworkChange
  NETWORK_PROVIDER_HAS_CHANGED: "SELECTED_NETWORK_PROVIDER"
};

export default _const;
