import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.default_card_color};
  box-shadow: 0px 0px 8.17112px rgba(45, 58, 58, 0.02);
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 10px;
  // padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 85px;
  cursor: pointer;
  :hover {
    background: ${(props) =>
      props.link
        ? props.theme.default_highlight_color
        : props.theme.default_card_color};
    transition: background 3s;
    transition-timing-function: ease;
  }
`;

export const CardLabel = styled.h6`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey2};
`;

export const CardValue = styled.h3`
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.default_text_color};
  font-weight: 500;
  margin-top: 6.89px;
`;

export const Rate = styled.p`
  font-size: 0.6rem;
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.yellow};
  margin-top: 5.76px;
  display: flex;
  align-items: center;
`;

export const Percentage = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.yellow};
  display: flex;
  align-items: center;
`;

export const PercentageContainer = styled.div`
  margin-right: 30px;
  text-align: right;
`;

export const RightValue = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ArrowContainer = styled.div`
  cursor: pointer;
`;

export const InfoLabels = styled.div`
  display: inline-flex;
  align-items: center;
  //margin-bottom: 10px;

  h6 {
    margin-right: 5px;
  }
`;
