import { Container, Title, RedDot, MoreInfo } from "./style";

export const DeviceOverview = ({
  title,
  children,
  isRedDot,
  active,
  // tabs
}) => {
  return (
    <Container>
      <Title>
        <h4>{title}</h4>
        {isRedDot && <RedDot active={active} />}
      </Title>

      <MoreInfo>{children}</MoreInfo>
    </Container>
  );
};
