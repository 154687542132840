import _const from "../../constants/types";

const initialState = {
  modalState: false,
  type: 0,
  termsandconditions: false,
  macAddress: "",
  durationstate: 1,
  duration: 1,
  rate: 0,
  tokenId: "",
  showcontent: false,
  deviceName: "",
  searchdeviceName: "",
  clipboardText: "",
  searchWalletAddress: "",
  searchoutlierdevices: "",
  searchmydevicename: "",
  description: "",
  center: [],
  city: "",
  onlinestatus: "",
  outlierRes: [],
  deviceRes: [],
  transcRes: [],
  cityRes: [],
  addressRes: [],
  searchRes: 0,
  searchParam: "",
  expectres: false,
  currtabs: [],
  activetab: 1,
  hasdets: 0,
  oldroutes: [],
  newroute: [],
  activemapoption: 0,
  polyline: [],
  point: 0,
  isplist: [],
  colors: [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ],
  selectedisp: {},
  selectedTimeline: {},
  timelineList: [
    { id: "1", timeline: "Last Week" },
    { id: "Week2", timeline: "Two weeks ago" },
    { id: "Month", timeline: "A Month ago" },
    { id: "Custom", timeline: "Custom" },
  ],
  leaderboardstart: "",
  leaderboardstop: "",
  searchEarners: "",
  searchEarnersResult: [],
  searchConnectionsResult: [],
  searchVolumeResult: [],
  searchDevicesInCityResult: [],
  selectedWeek: 0,
  currentWeek: 1,
  maxWeek: 1,
  earner: {},
};

const GeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.FILL_OUT_FORM:
      return {
        ...state,
        [action.payload.props]: action.payload.value,
      };

    case _const.TOGGLE_MODAL:
      return { ...state, modalState: action.payload, type: action.value };

    case _const.DEVICE_CONNECTED:
      return { ...state, rate: 0 };

    case _const.COPIED_TO_CLIPBOARD:
      return { ...state, clipboardText: action.payload };

    case _const.SEARCH_RESULTS:
      return {
        ...state,
        outlierRes: action.payload.outlierResponse,
        deviceRes: action.payload.devicesResponse,
        transcRes: action.payload.transactionResponse,
        cityRes: action.payload.cityResponse,
        addressRes: action.payload.walletAddressResponse,
        searchRes:
          action.payload.outlierResponse.length +
          action.payload.devicesResponse.length +
          action.payload.transactionResponse.length +
          action.payload.cityResponse.length +
          action.payload.walletAddressResponse.length,
      };

    case _const.ALL_ISP:
      return { ...state, isplist: action.payload };

    case _const.CURRENT_WEEK:
      return {
        ...state,
        selectedWeek: action.payload.week,
        currentWeek: action.payload.week,
        maxWeek: action.payload.allowedEstimateIntoTheFuture,
      };

    default:
      return state;
  }
};

export default GeneralReducer;
