export const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
  BSC: "bsc",
  MathWallet: "mathwallet",
  CoinBase: "coinbase"
};

//used For Contract Creator
export const WalletConnectorNames = {
  InjectedWallet: "metamask",
  BinanceWallet: "bscwallet",
  WalletConnect: "walletConnect"
};
