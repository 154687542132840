import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormDetails } from "../../methods/actions";
import { OutlierOverview, OutlierDevices } from "../../container";
import { ClaimHistory } from "../deviceStats/container";

export const OutlierDetail = () => {
  const dispatch = useDispatch();
  const activetab = useSelector(state => state.GeneralReducer.activetab);
  
  useEffect(() => {
    //Find a better way to handle this 
    dispatch(
      getFormDetails({
        props: ["activetab"],
        value: 1
    }));
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 1
    }));
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [{ id: 1, name: "Overview" }, { id: 2, name: "Devices" }, { id: 3, name: "Claim History" }]
      }));
      //This block of code 
  }, [dispatch]);

  return (
    <>
      {activetab === 1 ? <OutlierOverview /> : activetab === 2 ?  <OutlierDevices /> : <ClaimHistory />}
    </>
  );
};
