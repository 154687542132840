import _const from "../_const";

const initialState = {
  address: "",
  walletInUse: "",
  chainId: 0,
  nativeBalance: "0.00"
};

const ConnectWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ADDRESS:
      return { ...state, ...action.payload };
    case _const.NATIVE_BALANCE:
      return { ...state, nativeBalance: action.payload };
    case _const.PRISTINE:
      return { ...state, address: "", nativeBalance: "0.0000" };

    default:
      return state;
  }
};

export default ConnectWalletReducer;
