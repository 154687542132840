import React from "react";
import { useSelector } from "react-redux";
import EarnersCard from "../../../components/earnerscard";
import { ContainerFlex, Title, CenterDevices } from "./style";
import { precise_round } from "../../../methods/helper";

const SearchResult = () => {
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);
  const searchEarnersResult = useSelector(
    (state) => state.GeneralReducer.searchEarnersResult
  );
  const searchEarners = useSelector(
    (state) => state.GeneralReducer.searchEarners
  );

  return (
    <div>
      <ContainerFlex>
        <Title> Search Result(s)</Title>
      </ContainerFlex>
      {searchEarnersResult.length > 0 ? (
        searchEarnersResult.map((each) => {
          return (
            <EarnersCard
              key={each.rank}
              pos={each.rank}
              deviceName={each.deviceName}
              amt={precise_round(each.totalRewards, 2)}
              amtInusd={precise_round(each.totalRewards * stats.marketPrice, 2)}
              earner={each}
            />
          );
        })
      ) : (
        <CenterDevices>
          <CenterDevices>
            <img src="/assets/img/light.svg" alt="empty" />
          </CenterDevices>{" "}
          {searchEarners +
            " did not receive any rewards within the range selected"}
        </CenterDevices>
      )}
    </div>
  );
};

export default SearchResult;
