import { Container, CountryName } from "./style";

export const CityName = ({ flag, name }) => {
  return (
    <Container>
      <img src={flag} height="15px" alt={""} />
      <CountryName>{name}</CountryName>
    </Container>
  );
};
