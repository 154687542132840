import styled from "styled-components";
import { size } from "../../theme";

export const CardWrapper = styled.button`
  cursor: pointer;
  position: relative;
  display: ${p => (p.show === false ? "none" : "flex")};
  align-items: center;
  height: 60px;
  width: 60px;
  margin-bottom: 10px;
  background: #d3d3d3;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3.51724px 36.0517px rgba(0, 0, 0, 0.06);
  border-radius: 14.7847px;
  padding: 16px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: #d3d3d3;
    border-radius: 8px;
  }
  @media (max-width: ${size.mobileM}) {
    margin: 0 auto;
  }
`;

export const WalletName = styled.p`
  color: ${({ theme }) => theme.grey2};
  font-size: ${({ theme }) => theme.textXXs};
  text-align: center;
  font-weight: ${({ theme }) => theme.textNormal};
`;
