import _const from "../../constants/types";

const initialState = {
  gettingmydevices: false,
  gettinglatestdevices: false,
  gettingDeviceCities: false,
  gettingdevicestatistics: false,
  startingSimulation: false,
  gettingLatestBlocks: false,
  gettingBlockDetail: false,
  gettingTransactionDetail: false,
  gettingBlockHeightStatistics: false,
  gettingOutlierDetail: false,
  gettingOutlierDevices: false,
  gettingOutlierStatistics: false,
  gettingDeviceTransactions: false,
  gettingAllOutliers: false,
  gettingDevicesInCity: false,
  gettingBlockHash: false,
  gettingClaimHistory: false,
  gettingMarketStatistics: false,
  gettingRewards: false,
  gettingStakedStatistics: false,
  gettingOneDeviceStatistics: false,
  searchingExplorer: false,
  gettingCityStatistics: false,
  gettingDeviceLeaderBoard: false,
  gettingStatisticsChartpoint: false,
  gettingLatestDevices: false,
  gettingBlockHeightTransactions: false,
  gettingBlockTime: false
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.GETTING_DEVICE_STATISTICS:
      return { ...state, gettingdevicestatistics: action.payload };

    case _const.GETTING_MY_DEVICES:
      return { ...state, gettingmydevices: action.payload };

    case _const.GETTING_LATEST_DEVICES:
      return { ...state, gettinglatestdevices: action.payload };

    case _const.GETTING_DEVICE_CITIES:
      return { ...state, gettingDeviceCities: action.payload };

    case _const.STARTING_SIMULATION:
      return { ...state, startingSimulation: action.payload };

    case _const.GETTING_LATEST_BLOCKS:
      return { ...state, gettingLatestBlocks: action.payload };

    case _const.GETTING_BLOCK_DETAIL:
      return { ...state, gettingBlockDetail: action.payload };

    case _const.GETTING_TRANSACTION_DETAIL:
      return { ...state, gettingTransactionDetail: action.payload };

    case _const.GETTING_BLOCKHEIGHT_STATISTICS:
      return { ...state, gettingBlockHeightStatistics: action.payload };

    case _const.GETTING_OUTLIER_DETAIL:
      return { ...state, gettingOutlierDetail: action.payload };

    case _const.GETTING_OUTLIER_DEVICES:
      return { ...state, gettingOutlierDevices: action.payload };

    case _const.GETTING_ALL_OUTLIERS:
      return { ...state, gettingAllOutliers: action.payload };

    case _const.GETTING_OUTLIER_STATISTICS:
      return { ...state, gettingOutlierStatistics: action.payload };

    case _const.GETTING_DEVICES_IN_CITY:
      return { ...state, gettingDevicesInCity: action.payload };

    case _const.GETTING_DEVICE_TRANSACTIONS:
      return {
        ...state,
        gettingDeviceTransactions: action.payload,
      };

    case _const.GETTING_BLOCK_HASH:
      return { ...state, gettingBlockHash: action.payload };

    case _const.GETTING_MARKET_STATISTICS:
      return { ...state, gettingMarketStatistics: action.payload };

    case _const.GETTING_DISTRIBUTED_REWARDS:
      return { ...state, gettingRewards: action.payload };

    case _const.GETTING_STAKED_STATISTICS:
      return { ...state, gettingStakedStatistics: action.payload };

    case _const.GETTING_CLAIMING_HISTORY:
      return { ...state, gettingClaimHistory: action.payload };

    case _const.GETTING_ONE_DEVICE_STATISTICS:
      return { ...state, gettingOneDeviceStatistics: action.payload };

    case _const.GETTING_DEVICE_STATISTICS_CHARTPOINT:
      return{...state, gettingStatisticsChartpoint: action.payload}

    case _const.SEARCHING_EXPLORER:
      return { ...state, searchingExplorer: action.payload };

    case _const.GETTING_CITY_STATISTICS:
      return { ...state, gettingCityStatistics: action.payload };

    case _const.GETTING_DEVICE_LEADERBOARD:
      return { ...state, gettingDeviceLeaderBoard: action.payload };

    case _const.GETTING_LATEST_DEVICES:
      return{...state, gettingLatestDevices: action.payload}

    case _const.GETTING_BLOCKHEIGHT_TRANSACTIONS:
      return{...state, gettingBlockHeightTransactions: action.payload}

    case _const.GETTING_BLOCKHEIGHT_TIME:
      return{...state, gettingBlockTime: action.payload}

    default:
      return state;
  }
};

export default LoaderReducer;
