

const InfoIcon = () => {
  return (
    <img
      className="ml-5"
      src="/assets/img/infoicon.svg"
      alt="infoIcon"
    />
  );
};

export default InfoIcon;
