import React from "react";
import { ArrowCard, Loader } from "../../../components";
import { useSelector } from "react-redux";
import { HalfContainer, FlexContainer } from "./style";
import {
  numberWithCommaswithoutdecimals,
  numberWithCommas,
} from "../../../methods/helper";

const RewardSummary = () => {
  const totalrewardedDevices = useSelector(
    (state) => state.DeviceReducer.totalrewardedDevices
  );
  const deviceRewards = useSelector(
    (state) => state.DeviceReducer.deviceRewards
  );
  const gettingDeviceLeaderBoard = useSelector(
    (state) => state.LoaderReducer.gettingDeviceLeaderBoard
  );
  return (
    <FlexContainer>
      <HalfContainer>
        <ArrowCard
          label={"Distributed Rewards "}
          description={""}
          value={
            // gettingDeviceLeaderBoard ? (
            //   <Loader color={"#E5B910"} />
            // ) : (
            //   numberWithCommas(deviceRewards, 2)
            // )

            numberWithCommas(deviceRewards, 2)
          }
          subvalue={"WNT"}
          link={""}
        />
      </HalfContainer>
      <HalfContainer>
        <ArrowCard
          label={"Total Devices"}
          pos={3}
          description={""}
          value={
            // gettingDeviceLeaderBoard ? (
            //   <Loader color={"#E5B910"} />
            // ) : (
            //   numberWithCommaswithoutdecimals(totalrewardedDevices)
            // )
            numberWithCommaswithoutdecimals(totalrewardedDevices)
          }
          subvalue={"."}
          link={""}
        />
      </HalfContainer>
    </FlexContainer>
  );
};

export default RewardSummary;
