import _const from "../../constants/types";

const initialState = {
    marketStatistics: {
      marketPrice: 0,
      circulatingSupply: 0,
      maxSupply: 0,
      marketCapChangePercentage24h: 0,
      marketCap: 0,
      priceChangePercentage24h: 0
    }
};

const MarketReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.MARKET_STATISTICS:
      return { ...state, marketStatistics: action.payload };

    default:
      return state;
  }
};

export default MarketReducer;
