import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowCard, CardContainer, Loader } from "../../../../components";
import InfoDetails from "../../../../components/infoDetails";
import {
  CardCont,
  StatisticsContainer,
  Hash,
  CardTitle,
  CardLabel,
  InfoLabels,
} from "./style";
import {
  getBlockHashDetail,
  copyToClipboard,
  getFormDetails,
} from "../../../../methods/actions";
import { formathash } from "../../../../methods/helper";
import { useParams } from "react-router-dom";
import config from "../../../../methods/config";

export const Overview = () => {
  const dispatch = useDispatch();

  const blockHashDetail = useSelector(
    (state) => state.BlockHeightReducer.blockHashDetail
  );
  const gettingBlockHash = useSelector(
    (state) => state.LoaderReducer.gettingBlockHash
  );
  const clipboardText = useSelector(
    (state) => state.GeneralReducer.clipboardText
  );

  let { blockHash } = useParams();

  useEffect(() => {
    dispatch(getBlockHashDetail(blockHash));
  }, [dispatch, blockHash]);

  return (
    <>
      <StatisticsContainer>
        <CardCont>
          <CardContainer>
            <CardTitle>
              <InfoLabels>
                <CardLabel>Block Hash </CardLabel>
                <InfoDetails
                  currentdescription={
                    "This is the current reference number for the block in this session."
                  }
                  pos={1}
                  handlemouse={() =>
                    dispatch(
                      getFormDetails({
                        props: ["description"],
                        value:
                          "This is the current reference number for the block in this session.",
                      })
                    )
                  }
                  handlemouseleave={() =>
                    dispatch(
                      getFormDetails({
                        props: ["description"],
                        value: "",
                      })
                    )
                  }
                />
              </InfoLabels>
            </CardTitle>
            <main
              onClick={() =>
                dispatch(
                  copyToClipboard(
                    config.platform + `block/hash/` + blockHashDetail.hash
                  )
                )
              }
            >
              <h3>
                {gettingBlockHash ? (
                  <Loader color={"#E5B910"} />
                ) : (
                  formathash(blockHashDetail.hash, 4)
                )}
              </h3>
              <Hash
                src={
                  clipboardText ===
                  config.platform + `block/hash/` + blockHashDetail.hash
                    ? "/assets/svgs/activehash.svg"
                    : "/assets/svgs/hash.svg"
                }
                alt={"hash"}
              />
            </main>
          </CardContainer>
        </CardCont>

        <CardCont>
          <CardContainer>
            <CardTitle>Previous Block</CardTitle>
            <main
              onClick={() =>
                dispatch(
                  copyToClipboard(
                    config.platform +
                      `block/hash/` +
                      blockHashDetail.previousBlockHash
                  )
                )
              }
            >
              <h3>
                {gettingBlockHash ? (
                  <Loader color={"#E5B910"} />
                ) : (
                  formathash(blockHashDetail.previousBlockHash, 4)
                )}
              </h3>
              <Hash
                src={
                  clipboardText ===
                  config.platform +
                    `block/hash/` +
                    blockHashDetail.previousBlockHash
                    ? "/assets/svgs/activehash.svg"
                    : "/assets/svgs/hash.svg"
                }
                alt={"hash"}
              />
            </main>
          </CardContainer>
        </CardCont>
      </StatisticsContainer>
      <CardCont>
        <ArrowCard
          label={"Total Transactions"}
          handlechange={() =>
            dispatch(
              getFormDetails({
                props: ["activetab"],
                value: 2,
              })
            )
          }
          value={
            gettingBlockHash ? (
              <Loader color={"#E5B910"} />
            ) : (
              blockHashDetail.transactionCount
            )
          }
          subvalue={
            gettingBlockHash ? (
              <Loader color={"#E5B910"} />
            ) : (
              blockHashDetail.transactionRateChange
            )
          }
        />
      </CardCont>
    </>
  );
};
