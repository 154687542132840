import styled from "styled-components";

export const Info = styled.div`
  h5 {
    color: ${({ theme }) => theme.black};
    padding-bottom: 8px;
  }

  main {
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.grey4};

    p {
      margin-left: 9.3px;
      font-size: 0.75rem;
    }
  }
`;
