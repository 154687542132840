import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search, ScrollContainer, OutlierCard } from "../components";
import { Container, CenterContent, SearchContainer } from "./style";
import {
  getAllOutliers,
  getFormDetails,
  copyToClipboard,
  // clearAllOutliers,
} from "../methods/actions";
import { formathash } from "../methods/helper";
import { PageLoader } from "./pageloader";

export const AllOutliers = () => {
  const alloutlierslist = useSelector(
    (state) => state.OutlierReducer.alloutlierslist
  );
  const gettingAllOutliers = useSelector(
    (state) => state.LoaderReducer.gettingAllOutliers
  );
  const searchWalletAddress = useSelector(
    (state) => state.GeneralReducer.searchWalletAddress
  );
  const alloutliersCurrentPage = useSelector(
    (state) => state.OutlierReducer.alloutliersCurrentPage
  );
  const alloutliersTotalPage = useSelector(
    (state) => state.OutlierReducer.alloutliersTotalPage
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let newPage = alloutliersCurrentPage + 1;
    if (alloutliersTotalPage > alloutliersCurrentPage) {
      dispatch(getAllOutliers(newPage, 30, searchWalletAddress));
    }
    // if (searchWalletAddress) {
    //   dispatch(getAllOutliers(1, 30, searchWalletAddress));
    // }
  }, [
    dispatch,
    searchWalletAddress,
    alloutliersCurrentPage,
    alloutliersTotalPage,
  ]);


  const handleSearch = (e) =>{
    let inputValue = e.target.value
    dispatch(
      getFormDetails({
        props: ["searchWalletAddress"],
        value: e.target.value,
      })
    );
    // Define a regular expression pattern for a wallet address
    const walletAddressPattern = /^0x[a-fA-F0-9]{40}$/;

      // Check if the input value matches the wallet address pattern
      if (walletAddressPattern.test(inputValue) || inputValue == "") {
        // If the pattern is matched, make a request to the backend
        dispatch(getAllOutliers(1, 30, inputValue));
      } else {
        // If the pattern is not matched, handle the invalid input (e.g., show an error message)
        console.error('Invalid wallet address');
      }
  }
  return (
    <>
      <Container>
        <SearchContainer>
          <Search
            placeholder="Search Outliers"
            value={searchWalletAddress}
            handleChange={(e) => handleSearch(e)}
          />
        </SearchContainer>

        <ScrollContainer height={"500px"}>
          {gettingAllOutliers & (alloutlierslist.length === 0) ? (
            <PageLoader />
          ) : alloutlierslist.length > 0 ? (
            alloutlierslist.map((outlier, i) => {
              return (
                <OutlierCard
                  key={i}
                  address={formathash(outlier.walletAddress)}
                  avatar={outlier.avatarUrl}
                  flag={outlier.flagIconUrl}
                  country={outlier.country}
                  copy={() => dispatch(copyToClipboard(outlier.walletAddress))}
                  no_of_devices={`${outlier.numberOfDevices} Devices deployed (${outlier.activeDevicePercentage}% currently active)`}
                  link={`/outliers/${outlier.walletAddress}`}
                />
              );
            })
          ) : (
            <CenterContent>
              <CenterContent>
                <img src="/assets/img/light.svg" alt="empty" />
              </CenterContent>
              No search result found!
            </CenterContent>
          )}
        </ScrollContainer>
      </Container>
    </>
  );
};
