import {
  Container,
  ChartContainer,
  ChartDetails,
  Filter,
  DetailContainer,
  ImageContainer,
  Percentage,
  PercentageContainer,
  Rate,
  PercentageArrow,
  FilterOption,
  InfoLabels,
  CardLabel,
} from "./style";
import { Line } from "react-chartjs-2";
import InfoDetails from "../../components/infoDetails";
import { Link } from "react-router-dom";

export const EarningComponent = ({
  filterElements,
  chartLabels,
  chartData,
  title,
  amount,
  link,
  percentage,
  percentsubvalue,
  label,
  triggerfilter,
  currfilter,
  description,
  handlemouse,
  handlemouseleave,
  pos,
  subvalue,
}) => {
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 80);
    gradient.addColorStop(0, "rgba(37, 52, 106, 0.2)");
    gradient.addColorStop(1, "rgba(196, 196, 196, 0)");

    return {
      labels: chartLabels,
      datasets: [
        {
          label: label ? label : "dataset",
          fill: true,
          lineTension: 0.4,
          backgroundColor: gradient,
          borderColor: "#25346A",
          borderWidth: 1.2,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#25346A",
          pointBackgroundColor: "#25346A",
          pointBorderWidth: 0,
          pointHoverRadius: 2,
          pointHoverBackgroundColor: "#25346A",
          pointHoverBorderColor: "#25346A",
          pointHoverBorderWidth: 0,
          pointRadius: 0.2,
          pointHitRadius: 10,
          data: chartData,
        },
      ],
    };
  };

  const lineOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },

    lineTension: 0.4,

    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <Container link={link}>
      {link ? (
        <Link className={"link-decoration"} to={link}>
          {/* <Filter>{filterElements}</Filter> */}
          <Filter>
            {filterElements.map((each, i) => {
              return (
                <FilterOption
                  key={i}
                  active={each === currfilter}
                  onClick={() => triggerfilter(each)}
                >
                  {" "}
                  {each}
                </FilterOption>
              );
            })}
          </Filter>
          <DetailContainer>
            <ChartDetails>
              <InfoLabels>
                {" "}
                <h6>{title} </h6>{" "}
                <InfoDetails
                  handlemouse={handlemouse}
                  handlemouseleave={handlemouseleave}
                  currentdescription={description}
                  pos={pos}
                />
              </InfoLabels>
              <h3 style={{ color: "black" }}>{amount}</h3>
            </ChartDetails>
            <PercentageArrow>
              {percentage ? (
                <PercentageContainer>
                  <Percentage color={percentage > 0 ? "green" : "red"}>
                    {" "}
                    <img
                      className="mr-5"
                      src={
                        percentage > 0
                          ? "/assets/svgs/upward.svg"
                          : "/assets/svgs/downward.svg"
                      }
                      alt=""
                    />
                    {percentage}%
                  </Percentage>
                  <Rate color={"grey2"}>{percentsubvalue}</Rate>
                </PercentageContainer>
              ) : (
                ""
              )}

              {link ? (
                <ImageContainer>
                  <img src={"/assets/svgs/arrow.svg"} alt={"arrow"} />
                </ImageContainer>
              ) : (
                ""
              )}
            </PercentageArrow>
          </DetailContainer>
          <ChartContainer>
            <Line data={data} options={lineOptions} height={50} />
          </ChartContainer>
        </Link>
      ) : (
        <>
          <Filter>
            {filterElements.map((each, i) => {
              return (
                <FilterOption
                  key={i}
                  active={each === currfilter}
                  onClick={() => triggerfilter(each)}
                >
                  {" "}
                  {each}
                </FilterOption>
              );
            })}
          </Filter>
          <DetailContainer>
            <ChartDetails>
              <InfoLabels>
                {" "}
                <h6>{title} </h6>
                <InfoDetails
                  handlemouse={handlemouse}
                  handlemouseleave={handlemouseleave}
                  currentdescription={description}
                  pos={pos}
                />
              </InfoLabels>
              <h3>{amount}</h3>
              {subvalue ? <CardLabel> {subvalue}</CardLabel> : null}
            </ChartDetails>
            <PercentageArrow>
              {percentage ? (
                <PercentageContainer>
                  <Percentage color={percentage > 0 ? "green" : "red"}>
                    {" "}
                    <img
                      className="mr-5"
                      src={
                        percentage > 0
                          ? "/assets/svgs/upward.svg"
                          : "/assets/svgs/downward.svg"
                      }
                      alt=""
                    />
                    {percentage}%
                  </Percentage>
                  <Rate color={"grey2"}>{percentsubvalue}</Rate>
                </PercentageContainer>
              ) : (
                ""
              )}
            </PercentageArrow>
          </DetailContainer>
          <ChartContainer>
            <Line data={data} options={lineOptions} height={50} />
          </ChartContainer>
        </>
      )}
    </Container>
  );
};
