import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { lightMode, darkMode } from "./theme";
import { GlobalStyles } from "./global-styles";
import { AppRouter } from "./appRouter";
import { recreateWeb3 } from "./methods/utils/useAuth";

const Themeing = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(recreateWeb3());
  }, [dispatch]);
  //temp state for the theme, we could toggle from context API store or redux store
  const theme = "light";

  return (
    <ThemeProvider theme={theme === "light" ? lightMode : darkMode}>
      <GlobalStyles />
      <AppRouter />
    </ThemeProvider>
  );
};

export default Themeing;
