import { useDispatch, useSelector } from "react-redux";
import {
  SearchResultContainer,
  Title,
  ResultSummary,
  TransactionName,
  CenterDevices,
} from "./style";
import {
  LatestDeviceComponent,
  OutlierCard,
  CitiesCard,
  ArrowContainer,
  CityName,
} from "../../components";
import { PageLoader } from "../../container";
import { getFormDetails, copyToClipboard } from "../../methods/actions";
import {
  formathash,
  numberWithCommaswithoutdecimals,
} from "../../methods/helper";
import moment from "moment";

const SearchResult = () => {
  const dispatch = useDispatch();

  const searchingExplorer = useSelector(
    (state) => state.LoaderReducer.searchingExplorer
  );
  const searchRes = useSelector((state) => state.GeneralReducer.searchRes);
  const deviceRes = useSelector((state) => state.GeneralReducer.deviceRes);
  const outlierRes = useSelector((state) => state.GeneralReducer.outlierRes);
  const transcRes = useSelector((state) => state.GeneralReducer.transcRes);
  const cityRes = useSelector((state) => state.GeneralReducer.cityRes);
  const addressRes = useSelector((state) => state.GeneralReducer.addressRes);

  return (
    <SearchResultContainer
      onClick={() =>
        dispatch(
          getFormDetails({
            props: ["expectres"],
            value: false,
          })
        )
      }
    >
      {searchingExplorer ? (
        <>
          <PageLoader color={"#E5B910"} />
        </>
      ) : (
        <>
          {searchRes === 0 ? (
            <CenterDevices>
              <CenterDevices>
                <img src="/assets/img/light.svg" alt="empty" />
              </CenterDevices>{" "}
              No search result!
            </CenterDevices>
          ) : (
            <div>
              <ResultSummary> Search results ({searchRes})</ResultSummary>

              {deviceRes.length > 0 ? (
                <>
                  <Title> Devices</Title>
                  {deviceRes.map((each) => {
                    return (
                      <LatestDeviceComponent
                        link={`/my_device/statistics/${each.tokenId}/${each.deviceId}`}
                        title={each.deviceName}
                        flag={each.flagIconUrl}
                        active={each.hasActiveSession}
                        countryName={each.city + "," + each.country}
                        lastactive={
                          each.lastTrafficUpdate === 0
                            ? "Never Active"
                            : moment(each.lastTrafficUpdate).format(
                                "DD-MM-YYYY, h:mm a"
                              )
                        }
                      />
                    );
                  })}
                </>
              ) : null}
              {outlierRes.length > 0 ? (
                <>
                  <Title> Outliers</Title>
                  {outlierRes.map((each) => {
                    return (
                      <OutlierCard
                        key={each.outlierId}
                        address={formathash(each.walletAddress)}
                        avatar={each.avatarUrl}
                        //flag={each.flagIconUrl}
                        //country={each.country}
                        copy={() =>
                          dispatch(copyToClipboard(each.walletAddress))
                        }
                        no_of_devices={`${each.numberOfDevices} Device${
                          each.numberOfDevices > 1 ? "s" : ""
                        }  deployed (${
                          each.percentageActive
                        }% currently active)`}
                        link={`/outliers/${each.walletAddress}`}
                      />
                    );
                  })}
                </>
              ) : null}
              {addressRes.length > 0 ? (
                <>
                  <Title> Wallet Address</Title>
                  {addressRes.map((each) => {
                    return (
                      <OutlierCard
                        key={each.outlierId}
                        address={formathash(each.walletAddress)}
                        avatar={each.avatarUrl}
                        //flag={each.flagIconUrl}
                        //country={each.country}
                        copy={() =>
                          dispatch(copyToClipboard(each.walletAddress))
                        }
                        no_of_devices={`${each.numberOfDevices} Device ${
                          each.numberOfDevices > 1 ? "s" : ""
                        } (${each.percentageActive}% currently active)`}
                        link={`/outliers/${each.walletAddress}`}
                      />
                    );
                  })}
                </>
              ) : null}
              {cityRes.length > 0 ? (
                <>
                  <Title> Cities</Title>
                  {cityRes.map((city) => {
                    return (
                      <CitiesCard
                        city={city.city}
                        flag={city.flagIconUrl}
                        country={city.country}
                        no_of_devices={`${numberWithCommaswithoutdecimals(
                          city.numberOfDevices
                        )} Device(s) deployed (${
                          city.percentageActive
                        }% currently active)`}
                        link={`/city/stats/${city.city}`}
                      />
                    );
                  })}
                </>
              ) : null}

              {transcRes.length > 0 ? (
                <>
                  <Title> Transactions</Title>
                  {transcRes.map((transaction) => {
                    return (
                      <ArrowContainer
                        link={`/transaction_stats/${transaction.transactionId}`}
                      >
                        <div>
                          <TransactionName>
                            {transaction.transactionId
                              ? formathash(transaction.transactionId)
                              : ""}
                          </TransactionName>
                          <CityName
                            flag={"/assets/svgs/clock.svg"}
                            name={moment(transaction.sessionTimeStamp).format(
                              "Do MMMM YYYY, h:mm"
                            )}
                          />
                        </div>
                      </ArrowContainer>
                    );
                  })}
                </>
              ) : null}
            </div>
          )}{" "}
        </>
      )}
    </SearchResultContainer>
  );
};

export default SearchResult;
