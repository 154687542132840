import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ScrollContainer,
  Search,
  HistoryContainer,
} from "../../../../components";
import { PageLoader } from "../../../../container";
import {
  getFormDetails,
  getClaimHistory,
  copyToClipboard,
} from "../../../../methods/actions";
import React from "react";
import { SearchContainer, CenterDevices } from "../../../../container/style";
import { formathash } from "../../../../methods/helper";

export const ClaimHistory = () => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.RewardReducer.history);
  const gettingClaimHistory = useSelector(
    (state) => state.LoaderReducer.gettingClaimHistory
  );
  const clipboardText = useSelector(
    (state) => state.GeneralReducer.clipboardText
  );

  let { deviceId } = useParams();
  let { walletAddress } = useParams();

  useEffect(() => {
    dispatch(
      getClaimHistory(
        deviceId ? deviceId : "",
        walletAddress ? walletAddress : "",
        "",
        ""
      )
    );
  }, [dispatch, walletAddress, deviceId]);

  return (
    <React.Fragment>
      <SearchContainer margin="220px">
        {history.length > 0 ? (
          <Search
            placeholder={"Search Reward Claims"}
            handleChange={(e) =>
              dispatch(
                getFormDetails({
                  props: ["searchoutlierdevices"],
                  value: e.target.value,
                })
              )
            }
          />
        ) : null}
      </SearchContainer>
      <ScrollContainer height={"500px"}>
        {gettingClaimHistory ? (
          <PageLoader />
        ) : (
          <React.Fragment>
            {history.length > 0 ? (
              history.map((claim, i) => (
                <HistoryContainer
                  key={i}
                  address={
                    claim.walletAddress.toLowerCase() ===
                    "internal wallet address"
                      ? "Internal Wallet"
                      : formathash(claim.walletAddress)
                  }
                  claimed={claim.amount}
                  time={claim.createdAt}
                  copy={() => dispatch(copyToClipboard(claim.walletAddress))}
                  copied={clipboardText === claim.walletAddress}
                />
              ))
            ) : (
              <CenterDevices>
                <CenterDevices>
                  <img src="/assets/img/light.svg" alt="empty" />
                </CenterDevices>{" "}
                This outlier has no claim history yet!
              </CenterDevices>
            )}
          </React.Fragment>
        )}
      </ScrollContainer>
    </React.Fragment>
  );
};
