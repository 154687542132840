import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FlexContent = styled.div`
  display: flex;
  p {
    font-size: 0.8rem;
    margin-right: 5px;
  }
`;

export const RedDot = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${(props) =>
    props.active ? props.theme.green : props.theme.red};
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 50%;
  margin-top: 5px;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.black};
`;

export const TextContainer = styled.div`
  margin-left: 14px;

  h5 {
    line-height: 21px;
    font-weight: 500;
    padding-bottom: 8px;
  }
`;
