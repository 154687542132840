import { useSelector } from "react-redux";
import {
  ScrollContainer,
  ArrowContainer,
  CityName,
} from "../../../../components";
import { PageLoader } from "../../../../container";
import { Spacer } from "../../../../components/deviceOverview/style";
import { CardCont, BlockNumber } from "./style";
import { numberWithCommaswithoutdecimals } from "../../../../methods/helper";

import moment from "moment";

export const LatestBlocks = () => {
  const latestblocks = useSelector(
    (state) => state.BlockHeightReducer.latestblocks
  );
  const gettingLatestBlocks = useSelector(
    (state) => state.LoaderReducer.gettingLatestBlocks
  );

  return (
    <ScrollContainer>
      {gettingLatestBlocks ? (
        <PageLoader />
      ) : (
        latestblocks.map((block, i) => (
          <CardCont key={i}>
            <ArrowContainer link={`/block/stats/${block.blockNumber}`}>
              <div>
                <BlockNumber>
                  {numberWithCommaswithoutdecimals(block.blockNumber)}
                </BlockNumber>
                <main>
                  <CityName
                    flag={"/assets/svgs/clock.svg"}
                    name={moment(block.blockTimeStamp).format(
                      "Do MMMM YYYY, h:mma"
                    )}
                  />
                  <Spacer />
                  <CityName
                    flag={"/assets/svgs/switch.svg"}
                    name={block.transactionCount + " connections"}
                  />
                </main>
              </div>
            </ArrowContainer>
          </CardCont>
        ))
      )}
    </ScrollContainer>
  );
};
