import styled from "styled-components";

export const Container = styled.div`
  margin-top: 10px;
`;

export const CardOverviewContainer = styled.div`
  margin-top: 10px;

  h6 {
    color: ${({ theme }) => theme.grey2};
  }

  h3 {
    margin-top: 6.89px;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.gradient_black};
  }

  span {
  }

  main {
    display: flex;
    align-items: center;

    img {
      margin-right: 11px;
      &:last-of-type {
        margin-left: 11.67px;
      }
    }
  }
`;

export const Hash = styled.img`
  // &:hover {
  //   transform: translateX(-10px);
  //   transition-duration: 2s;
  // }
`;

export const CardTitle = styled.h6`
  color: ${({ theme }) => theme.grey2};
`;

export const InfoLabels = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;

  h6 {
    margin-right: 5px;
  }
`;
