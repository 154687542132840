import { useDispatch, useSelector } from "react-redux";
import { TopMapIconContainer, IconContainer } from "./style";
import { getFormDetails } from "../../methods/actions";

export const TopMapOptions = () => {
  const dispatch = useDispatch();
  const activemapoption = useSelector(
    (state) => state.GeneralReducer.activemapoption
  );

  function handleZoom() {
    if (activemapoption === 4) {
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 0,
        })
      );
    } else {
      dispatch(
        getFormDetails({
          props: ["activemapoption"],
          value: 4,
        })
      );
    }
  }

  return (
    <TopMapIconContainer>
      <IconContainer
        active={activemapoption === 4}
        onClick={() => handleZoom()}
      >
        <img src="/assets/icons/expand.svg" alt="signature" />
      </IconContainer>
    </TopMapIconContainer>
  );
};
