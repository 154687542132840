import { Container, Claimed, FlexContainer, WalletAddress, TimeStamp } from "./style";
import moment from "moment"

export const HistoryContainer = ({address, claimed, time, copy, copied}) => {
  return (
    <Container>
         <div>
             <FlexContainer>
             <WalletAddress>{address} </WalletAddress>
             <img
                src={
                  copied
                    ? "/assets/svgs/activehash.svg"
                    : "/assets/svgs/hash.svg"
                }
                alt={"hash"}
                onClick={copy}
              />
              </FlexContainer>
              <FlexContainer>
              <img src={"/assets/svgs/clock.svg"} height="15px" alt={"clock"} />
              <TimeStamp> {moment(time).format(
                    "Do MMMM YYYY, h:mm"
                  )}</TimeStamp>
              </FlexContainer>
              
         </div>
         <div>
             <Claimed> {claimed} WNT</Claimed>
         </div>
    </Container>
  );
};
