export const WICRYPT_EXPLORER = "/";
export const BLOCK_HEIGHT = "/block_height";
export const OUTLIERS = "/outliers";
export const OUTLIERSDETAIL = "/outliers/:walletAddress";
export const MARKET = "/market";
export const COMPONENTS = "/components";
export const MY_DEVICE_STATS = "/my_device/statistics/:tokenId/:deviceId";
export const DEVICES = "/devices";
export const CITY_STATS = "/city/stats/:cityname";
export const BLOCKS_STATS = "/block/stats/:blockId";
export const BLOCKS_HASH = "/block/hash/:blockHash";
export const TRANSACTION_STATS = "/transaction_stats/:transactionId";
export const STAKED = "/staked";
export const LEADERBOARD = "/leaderboard";
