import styled from "styled-components";

export const RadioButtonLabel = styled.label`
  color: ${({ theme }) => theme.grey2};
  font-size: ${({ theme }) => theme.textXXs};
  margin-left: 10px;
`;

export const RadioButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
`;

export const RadioButtonInput = styled.input``;
