import { ArrowContainer } from "../arrowContainer";
import { CityName } from "../cityName";

import { Info } from "./style";

export const CitiesCard = ({
  city,
  flag,
  country,
  no_of_devices,
  onClick,
  link
}) => {
  return (
    <ArrowContainer onClick={onClick} link={link}>
      <Info>
        <h5>{city}</h5>
        <CityName name={country} flag={flag} />
        <main>
          <img src={"/assets/svgs/devices.svg"} alt={""} />
          <p>{no_of_devices}</p>
        </main>
      </Info>
    </ArrowContainer>
  );
};
