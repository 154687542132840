import React from "react";
import { useSelector } from "react-redux";
import TopEarnerCard from "../../../components/topearnerCard";
import { numberWithCommas } from "../../../methods/helper";

const TopEarners = () => {
  let earners = useSelector((state) => state.DeviceReducer.earners);
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);

  return (
    <div>
      {earners.slice(0, 3).map((each) => {
        return (
          <TopEarnerCard
            key={each.rank}
            pos={each.rank}
            deviceName={each.deviceName}
            amt={numberWithCommas(each.totalRewards, 2)}
            amtInusd={numberWithCommas(
              each.totalRewards * stats.marketPrice,
              2
            )}
            earner={each}
          />
        );
      })}
    </div>
  );
};

export default TopEarners;
