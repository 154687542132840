import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CityName,
  CardContainer,
  ArrowCard,
  ArrowContainer,
  Loader,
} from "../../components";
import {
  CardCont,
  StatisticsContainer,
} from "../blockStat/container/overview/style";
import { Container, CardOverviewContainer, Hash, CardTitle } from "./style";
import {
  getBlockTransactionDetail,
  copyToClipboard,
  getFormDetails,
} from "../../methods/actions";
import { formathash, precise_round } from "../../methods/helper";
import filesize from "filesize";
import { useParams } from "react-router-dom";
import config from "../../methods/config";

export const TransactionStats = () => {
  const dispatch = useDispatch();

  const blockTransactionDetail = useSelector(
    (state) => state.BlockHeightReducer.blockTransactionDetail
  );
  const gettingTransactionDetail = useSelector(
    (state) => state.LoaderReducer.gettingTransactionDetail
  );

  const clipboardText = useSelector(
    (state) => state.GeneralReducer.clipboardText
  );

  let { transactionId } = useParams();

  useEffect(() => {
    //Find a better way to handle this
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [],
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 4,
      })
    );
    dispatch(
      getFormDetails({
        props: ["newroute"],
        value: { id: 1, title: "Transactions" },
      })
    );
    dispatch(
      getFormDetails({
        props: ["oldroutes"],
        value: [
          { id: 1, link: "/", title: "Home" },
          { id: 2, link: "/block_height", title: "Blockheight" },
        ],
      })
    );
    //This block of code
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBlockTransactionDetail(transactionId));
  }, [dispatch, transactionId]);

  return (
    <>
      <Container>
        <CardOverviewContainer>
          <CardContainer>
            <h6>Outlier</h6>
            <main>
              <img
                height="30px"
                src={blockTransactionDetail.onwerAvatarUrl}
                alt={""}
              />
              <h3>
                {gettingTransactionDetail ? (
                  <Loader />
                ) : (
                  formathash(blockTransactionDetail.ownerWalletAddress)
                )}
              </h3>
              <Hash
                src={
                  clipboardText ===
                  config.platform +
                    "outliers/" +
                    blockTransactionDetail.ownerWalletAddress
                    ? "/assets/svgs/activehash.svg"
                    : "/assets/svgs/hash.svg"
                }
                onClick={() =>
                  dispatch(
                    copyToClipboard(
                      config.platform +
                        "outliers/" +
                        blockTransactionDetail.ownerWalletAddress
                    )
                  )
                }
                alt={""}
              />
            </main>
          </CardContainer>
        </CardOverviewContainer>
        <CardOverviewContainer>
          <ArrowContainer
            link={`/my_device/statistics/${blockTransactionDetail.tokenid}/${blockTransactionDetail.deviceId}`}
          >
            <div>
              <h6>Device</h6>
              <h3>
                {gettingTransactionDetail ? (
                  <Loader color={"#E5B910"} />
                ) : (
                  blockTransactionDetail.deviceName
                )}
              </h3>
              <CityName
                flag={blockTransactionDetail.countryFlagUrl}
                name={
                  gettingTransactionDetail ? (
                    <Loader />
                  ) : blockTransactionDetail.city ? (
                    blockTransactionDetail.city
                  ) : "-- ," + blockTransactionDetail.country ? (
                    blockTransactionDetail.country
                  ) : (
                    "--"
                  )
                }
              />
            </div>
          </ArrowContainer>
        </CardOverviewContainer>
        <StatisticsContainer>
          <CardCont>
            <ArrowCard
              value={
                gettingTransactionDetail ? (
                  <Loader color={"#E5B910"} />
                ) : blockTransactionDetail.dataUsage !== undefined ? (
                  filesize(blockTransactionDetail.dataUsage)
                ) : (
                  ""
                )
              }
              naturalnumber={true}
              subvalue={
                gettingTransactionDetail ? (
                  <Loader color={"#E5B910"} />
                ) : (
                  blockTransactionDetail.dataUsedPercentageChange
                )
              }
              label={"Data Used"}
              description={
                "This is the total amount of data used on this session"
              }
              handlemouse={(e) =>
                dispatch(
                  getFormDetails({
                    props: ["description"],
                    value:
                      "This is the total amount of data used on this session",
                  })
                )
              }
              handlemouseleave={(e) =>
                dispatch(
                  getFormDetails({
                    props: ["description"],
                    value: "",
                  })
                )
              }
            />
          </CardCont>

          <CardCont>
            <CardContainer>
              <CardTitle>Mac address</CardTitle>
              <main>
                <h3>
                  {gettingTransactionDetail ? (
                    <Loader color={"#E5B910"} />
                  ) : (
                    formathash(blockTransactionDetail.hostMacHash, 4)
                  )}
                </h3>
                <Hash
                  onClick={() =>
                    dispatch(
                      copyToClipboard(blockTransactionDetail.hostMacHash)
                    )
                  }
                  src={
                    clipboardText === blockTransactionDetail.hostMacHash
                      ? "/assets/svgs/activehash.svg"
                      : "/assets/svgs/hash.svg"
                  }
                  alt={""}
                />
              </main>
            </CardContainer>
          </CardCont>
        </StatisticsContainer>
        <CardCont>
          <ArrowCard
            value={
              gettingTransactionDetail ? (
                <Loader color={"#E5B910"} />
              ) : (
                precise_round(blockTransactionDetail.upTime, 2) + "Secs"
              )
            }
            subvalue={
              gettingTransactionDetail ? (
                <Loader color={"#E5B910"} />
              ) : (
                Number(
                  precise_round(
                    blockTransactionDetail.uptimePercentageChange,
                    2
                  )
                )
              )
            }
            naturalnumber={true}
            label={"Uptime"}
            description={
              "This refers to the total time for which this hotspot hub actively delivers internet service"
            }
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This refers to the total time for which this hotspot hub actively delivers internet service",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
          />
        </CardCont>
      </Container>
    </>
  );
};
