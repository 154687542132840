import { Container, Info } from "./style";
import { Link } from "react-router-dom";

export const ArrowContainer = ({ onClick, children, link }) => {
  return (
    <>
      {link ? (
        <Link className={"link-decoration"} to={link}>
          <Container>
            <Info>{children}</Info>
            {onClick ? (
              <div onClick={onClick}>
                <img src={"/assets/svgs/chevronLeft.svg"} alt={"arrow"} />
              </div>
            ) : (
              ""
            )}

            <img src={"/assets/svgs/chevronLeft.svg"} alt={"arrow"} />
          </Container>
        </Link>
      ) : (
        <Container>
          <Info>{children}</Info>
          {onClick ? (
            <div onClick={onClick}>
              <img src={"/assets/svgs/chevronLeft.svg"} alt={"arrow"} />
            </div>
          ) : (
            ""
          )}
        </Container>
      )}
    </>
  );
};
