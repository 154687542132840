import { ArrowContainer } from "../arrowContainer";
import { CityName } from "../cityName";
import { RedDot, TextContainer, Title, FlexContent } from "./style";

export const LatestDeviceComponent = ({
  flag,
  countryName,
  title,
  link,
  active,
  lastactive,
  tier,
}) => {
  return (
    <ArrowContainer link={link}>
      <RedDot active={active} />
      <TextContainer>
        <Title>
          <FlexContent>
            <p> {title}</p>
            {/* {tier ? (
              tier === "TIER1" ? (
                <img src="/assets/img/tierone.svg" alt="device-tier" />
              ) : tier === "TIER2" ? (
                <img src="/assets/img/tiertwo.svg" alt="device-tier" />
              ) : (
                <img src="/assets/img/tierthree.svg" alt="device-tier" />
              )
            ) : (
              ""
            )} */}
          </FlexContent>
          <CityName flag={"/assets/svgs/clock.svg"} name={`${lastactive}`} />
          <CityName flag={flag} name={countryName} />
        </Title>
      </TextContainer>
    </ArrowContainer>
  );
};
