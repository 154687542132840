import styled from "styled-components";

export const Container = styled.div`
  padding: 14px 31.01px;
  border-radius: 8px;
  box-shadow: 0px 17.3636px 21.4492px rgba(231, 231, 231, 0.15);
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.grey3};
  width: 85%;
  margin-top: 10px;
  margin-bottom: 10px;
  img {
    cursor: pointer;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: flex-start;
`;
