import { PrimaryBtn, SecondaryBtn, SecondaryAltBtn } from "./style";

export const Button = ({
  buttonClass,
  padding,
  fontSize,
  onClick,
  label,
  ...restProps
}) => {
  if (buttonClass === "primary") {
    return (
      <PrimaryBtn
        padding={padding}
        fontSize={fontSize}
        onClick={onClick}
        {...restProps}
        data-testid="primary"
      >
        {label}
      </PrimaryBtn>
    );
  } else if (buttonClass === "secondary") {
    return (
      <SecondaryBtn
        padding={padding}
        fontSize={fontSize}
        onClick={onClick}
        data-testid={"secondary"}
        {...restProps}
        
      >
        {label}
      </SecondaryBtn>
    );
  } else if (buttonClass === "secondary-alt") {
    return (
      <SecondaryAltBtn
        padding={padding}
        fontSize={fontSize}
        onClick={onClick}
        data-testid={"secondary-alt"}
        {...restProps}
      >
        {label}
      </SecondaryAltBtn>
    );
  } else {
    return <p data-testid = {"wrong-button-class"}>attach valid btn_class</p>;
  }
};
