import styled from "styled-components";

export const InputBox = styled.input`
  border: none;
  border-bottom: 1px solid #d2cbcb;
  color: ${({ theme }) => theme.grey2};
  font-size: ${({ theme }) => theme.textXXs};
  margin-top: 10px;
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
    border-bottom: 2px solid #d2cbcb;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const InputIcon = styled.img`
  position: absolute;
  bottom: 8px;
  right: 0;
`;

export const DropDownContent = styled.p`
  color: ${({ theme }) => theme.grey2};
  font-size: ${({ theme }) => theme.textXXs};
  padding: 2px;
  cursor: pointer;
`;

export const DropDownContentContainer = styled.div`
  background: #fefefe;
  position: absolute;
  width: 100%;
  padding: 10 px;
  box-shadow: 10px 10px 5px #aaaaaa;
  border-radius: 8 px;
  max-height: 150px;
  overflow-y: scroll;
`;
