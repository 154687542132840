import { ModalHeader, Tint, ModalContainer, InfoContainer } from "./style";

export const Modal = ({ children, icon, title, setModal }) => {
  return (
    <>
      <Tint onClick={() => setModal(false)} />
      <ModalContainer>
        <ModalHeader>
          <img src={icon} alt={""} />
          <p>{title}</p>
          <button onClick={() => setModal(false)}>
            <i className="fas fa-times"></i>
          </button>
        </ModalHeader>
        <InfoContainer>{children || `Enter content here`}</InfoContainer>
      </ModalContainer>
    </>
  );
};
