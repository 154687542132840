import styled from "styled-components";

export const Info = styled.div`
  h5 {
    color: ${({ theme }) => theme.black};
    padding-bottom: 8px;
  }

  main {
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.grey4};

    p {
      margin-left: 9.3px;
      font-size: 0.75rem;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 11px;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.grey4};
  }
`;

export const CountryContainer = styled.div`
  margin-left: 20%;
`;

export const WalletAddress = styled.p`
  color: ${({ theme }) => theme.black2} !important;
  font-size: ${({ theme }) => theme.textXs} !important;
  margin-left: 10px;
`;

export const Hash = styled.img`
  margin-left: 10px;
  &:hover {
    transform: translateX(-10px);
    transition-duration: 2s;
  }
`;

export const Country = styled.p`
  color: ${({ theme }) => theme.grey4} !important;
  font-size: ${({ theme }) => theme.textXXs} !important;
  text-transform: capitalize;
`;

export const DevicesNumber = styled.p`
  color: ${({ theme }) => theme.grey4} !important;
  font-size: ${({ theme }) => theme.textXXs} !important;
`;
