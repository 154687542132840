import { useEffect, useState } from "react";
import { MapLayout } from "../../components/map";
import {
  InfoContainer,
  MapContainer,
  Toggle,
  CloseToggle,
  MobileNavContainer,
  FloatingBtn,
} from "./style";
import MapNav from "../../components/topnav";
import { SideBar } from "../sidebar";
import ModalOptions from "../../modalOptions";

export const MainLayout = ({ title, descr, children }) => {
  const [layout, setlayoutStatus] = useState(true);
  const [mobileState, setMobileState] = useState(true);
  const [screenSize, detectingSize] = useState(false);

  useEffect(() => {
    detectingSize(/iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent));
  }, []);

  return (
    <>
      {screenSize ? (
        !mobileState ? (
          <MobileNavContainer>
            <FloatingBtn onClick={() => setMobileState(!mobileState)}>
              <img height={30} src="/assets/svgs/turn.svg" alt="floatingbtn" />
            </FloatingBtn>
            <MapLayout />
          </MobileNavContainer>
        ) : (
          <MobileNavContainer>
            <InfoContainer layout={layout}>
              <FloatingBtn onClick={() => setMobileState(!mobileState)}>
                <img height={30} src="assets/svgs/turn.svg" alt="floatingbtn" />
              </FloatingBtn>
              <ModalOptions />
              <SideBar title={title} descr={descr} children={children} />
            </InfoContainer>
          </MobileNavContainer>
        )
      ) : (
        <MapContainer>
          <InfoContainer layout={layout}>
            <SideBar title={title} descr={descr} children={children} />
            <ModalOptions />
          </InfoContainer>
          <MapNav layout={layout} />
          {layout ? (
            <Toggle onClick={() => setlayoutStatus(!layout)}>
              {" "}
              <img
                style={{ marginLeft: "15px" }}
                src="/assets/svgs/toggleopen.svg"
                alt="toggle"
              />
            </Toggle>
          ) : (
            <CloseToggle onClick={() => setlayoutStatus(!layout)}>
              {" "}
              <img
                style={{ marginLeft: "15px" }}
                src="/assets/svgs/toggleclose.svg"
                alt="toggle"
              />
            </CloseToggle>
          )}

          <MapLayout />
        </MapContainer>
      )}
    </>
  );
};
