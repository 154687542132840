import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
`;

export const CardCont = styled.div``;

export const CenterDevices = styled.div`
  text-align: center;
  margin-top: 100px;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey};
`;

export const Option = styled.div`
  color: ${(props) => (!props.active ? props.theme.grey2 : props.theme.white)};
  font-size: 13px;
  padding: 5px 10px;
  background: ${(props) => (props.active ? props.theme.yellow : "#FBFBFB")};
  border-radius: 26px;
  //margin: 0px 10px;
  cursor: pointer;
  width: 25%;
  text-align: center;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  //justify-content: center;
`;
