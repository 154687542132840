import React from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  DeviceName,
  FlexContainer,
  SFlexContainer,
  EarnedAmount,
  Details,
} from "./style";
import { togglemodal, getFormDetails } from "../../methods/actions";

const EarnersCard = ({ pos, deviceName, amt, amtInusd, earner }) => {
  let dispatch = useDispatch();
  return (
    <Card
      onClick={() => {
        dispatch(togglemodal(true, 5));
        dispatch(
          getFormDetails({
            props: ["earner"],
            value: earner,
          })
        );
      }}
    >
      <SFlexContainer>
        <FlexContainer>
          <div>
            <p> #{pos}</p>
          </div>
          <Details>
            <DeviceName> {deviceName}</DeviceName>
            <EarnedAmount> {amt + " WNT ~ $" + amtInusd}</EarnedAmount>
          </Details>
        </FlexContainer>
        <div>
          <img src={"/assets/svgs/arrow.svg"} alt={"arrow"} />
        </div>
      </SFlexContainer>
    </Card>
  );
};

export default EarnersCard;
