import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WalletOptions, RadioButton, Button } from "../components";
import { disconnect } from "../methods/contracts/contractSetup";
import { login } from "../methods/utils/useAuth";
import connectors from "../methods/utils/connectorConfig";
import { connectorLocalStorageKey } from "../methods/utils/config";
import { getFormDetails, togglemodal } from "../methods/actions";
import {
  SectionWrapper,
  SectionBody,
  InfoWrapper,
  ActionContainer
} from "./style";

export const ConnectionBody = () => {
  const dispatch = useDispatch();

  const termsandconditions = useSelector(
    state => state.GeneralReducer.termsandconditions
  );
  const address = useSelector(store => store.ConnectWalletReducer.address);
  const walletInUse = useSelector(
    store => store.ConnectWalletReducer.walletInUse
  );
  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("disconnect", () => {});
    }
    // eslint-disable-next-line
  }, []);

  const disconnectWallet = () => {
    togglemodal(false, 0);
    dispatch(disconnect());
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleagreement = () => {
    dispatch(
      getFormDetails({
        props: ["termsandconditions"],
        value: !termsandconditions
      })
    );
  };

  let connectInfo = {
    network: null,
    protocol: null,
    wallet: null,
    chainId: 137
  };
  return (
    <>
      {!address ? (
        <React.Fragment>
          <SectionWrapper>
            <SectionBody>
              {connectors.map((entry, i) => (
                <WalletOptions
                  key={i}
                  connectwallet={() => {
                    // 1. should connect to the right network
                    // 2. switch to correct address
                    // 3. continue with wallet connection

                    dispatch(
                      login(entry.connectorId, connectInfo.chainId, entry.title)
                    );

                    window.localStorage.setItem(
                      connectorLocalStorageKey,
                      entry.connectorId
                    );
                  }}
                  img={entry.image}
                  walletname={entry.title}
                  termsandconditions={termsandconditions}
                />
              ))}
            </SectionBody>
          </SectionWrapper>
          <div>
            <InfoWrapper>
              Read and agree to
              <a
                href="https://wicrypt.com/terms-condition.html"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  fontSize: "0.8rem",
                  color: "#6F89E4"
                }}
              >
                {" "}
                (Wicrypt User Agreement)
              </a>{" "}
            </InfoWrapper>
          </div>
          <div>
            <InfoWrapper>
              <RadioButton
                onClick={handleagreement}
                label={"I have read and agreed"}
                checked={termsandconditions}
                disabled={false}
                name={"termsandconditions"}
              />
            </InfoWrapper>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SectionWrapper>
            <SectionBody>
              {connectors.map((entry, i) => (
                <WalletOptions
                  key={i}
                  disabled={true}
                  img={entry.image}
                  walletname={entry.title}
                  termsandconditions={termsandconditions}
                />
              ))}
            </SectionBody>
          </SectionWrapper>
          <div>
            <InfoWrapper>Disconnect {walletInUse}?</InfoWrapper>
          </div>
          <ActionContainer>
            <div>
              <Button
                buttonClass={"primary"}
                padding={""}
                fontSize={""}
                onClick={disconnectWallet}
                label={"Yes"}
              />
            </div>

            <div>
              <Button
                buttonClass={"secondary-alt"}
                padding={""}
                fontSize={""}
                onClick={() => dispatch(togglemodal(false, 0))}
                label={"No"}
              />
            </div>
          </ActionContainer>
        </React.Fragment>
      )}
    </>
  );
};
