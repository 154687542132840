import * as ROUTES from "./constants/routes";
import {
  BlockHeight,
  DeviceStats,
  Market,
  Outliers,
  WicryptExplorer,
  Devices,
  CityStats,
  BlockStat,
  TransactionStats,
  OutlierDetail,
  BlockHash,
  Staked,
  LeaderBoard,
} from "./pages";
import config from "../src/methods/config";

export const routeBuilder = [
  {
    title: config.appName,
    description:
      "The Wicrypt explorer is an intelligent mapping system that allows users to see all the Wicrypt Hotspot Hubs near them. Providing easy connection and seamless experience.",
    path: ROUTES.WICRYPT_EXPLORER,
    component: WicryptExplorer,
  },
  {
    title: "Devices",
    description: "",
    path: ROUTES.DEVICES,
    component: Devices,
  },
  {
    title: "Block Height",
    description: "",
    path: ROUTES.BLOCK_HEIGHT,
    component: BlockHeight,
  },
  {
    title: "Outliers",
    description: "",
    path: ROUTES.OUTLIERS,
    component: Outliers,
  },
  {
    title: "Outlier",
    description: "",
    path: ROUTES.OUTLIERSDETAIL,
    component: OutlierDetail,
  },
  {
    title: "Market",
    description: "",
    path: ROUTES.MARKET,
    component: Market,
  },
  {
    title: "",
    description: "",
    path: ROUTES.MY_DEVICE_STATS,
    component: DeviceStats,
  },
  {
    title: "",
    description: "",
    path: ROUTES.CITY_STATS,
    component: CityStats,
  },
  {
    title: "",
    description: "",
    path: ROUTES.BLOCKS_STATS,
    component: BlockStat,
  },
  {
    title: "",
    description: "",
    path: ROUTES.BLOCKS_HASH,
    component: BlockHash,
  },
  {
    title: "",
    description: "",
    path: ROUTES.TRANSACTION_STATS,
    component: TransactionStats,
  },
  {
    title: "WNT Staked",
    description: "",
    path: ROUTES.STAKED,
    component: Staked,
  },
  {
    title: "Distributed Rewards",
    description: "",
    path: ROUTES.LEADERBOARD,
    component: LeaderBoard,
  },
];
