import styled from "styled-components";

export const InputBox = styled.input`
  border: none;
  border-bottom: 1px solid #d2cbcb;
  color: ${({ theme }) => theme.grey2};
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
    border-bottom: 2px solid #d2cbcb;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const InputIcon = styled.img`
  position: absolute;
  bottom: 8px;
  right: 0;
  width: 10px;
  height: 10px;
`;
