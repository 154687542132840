import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CardContainer,
  EarningComponent,
  SmallValue,
  ArrowCard,
  Loader,
} from "../../../../components";
import {
  Container,
  CardCont,
  GreenCircle,
  RedCircle,
  CardLabel,
  StatisticsContainer,
  FlexContainer,
  HalfContainer,
  EarningContainer,
} from "./style";
import {
  getOneDeviceStatistics,
  getClaimHistory,
  getFormDetails,
} from "../../../../methods/actions";
import {
  numberWithCommaswithoutdecimals,
  precise_round,
} from "../../../../methods/helper";
import { useParams } from "react-router-dom";
import moment from "moment";

export const Statistics = () => {
  const dispatch = useDispatch();
  const onedevicestatistics = useSelector(
    (state) => state.DeviceReducer.onedevicestatistics
  );
  const gettingOneDeviceStatistics = useSelector(
    (state) => state.LoaderReducer.gettingOneDeviceStatistics
  );
  const onedeviceearnvalue = useSelector(
    (state) => state.DeviceReducer.onedeviceearnvalue
  );
  const onedeviceearnlabel = useSelector(
    (state) => state.DeviceReducer.onedeviceearnlabel
  );
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);
  const totalclaimed = useSelector((state) => state.RewardReducer.totalclaimed);
  const gettingClaimHistory = useSelector(
    (state) => state.LoaderReducer.gettingClaimHistory
  );
  const [currfilter, setCurrfilter] = useState("ALL");

  let { tokenId, deviceId } = useParams();

  useEffect(() => {
    if (onedevicestatistics) {
      dispatch(
        getFormDetails({
          props: ["center"],
          value:
            onedevicestatistics.latitude === null ||
            onedevicestatistics.longitude === null
              ? [6.44943, 7.49281]
              : [onedevicestatistics.latitude, onedevicestatistics.longitude],
        })
      );
    }
  }, [dispatch, onedevicestatistics]);

  useEffect(() => {
    dispatch(getOneDeviceStatistics(tokenId, deviceId, "", ""));
    dispatch(getClaimHistory(deviceId, "", "", ""));
  }, [dispatch, deviceId, tokenId]);

  const handlefilter = (val) => {
    setCurrfilter(val);
    if (val === "ALL") {
      dispatch(getOneDeviceStatistics(tokenId, deviceId, "", ""));
    } else if (val === "24H") {
      dispatch(getOneDeviceStatistics(tokenId, deviceId, 24, ""));
    } else if (val === "7D") {
      dispatch(getOneDeviceStatistics(tokenId, deviceId, "", 7));
    } else {
      dispatch(getOneDeviceStatistics(tokenId, deviceId, "", 30));
    }
  };

  return (
    <Container>
      <StatisticsContainer>
        <CardCont>
          <CardContainer>
            <CardLabel>Connections</CardLabel>
            <h3>
              {gettingOneDeviceStatistics ? (
                <Loader color={"#E5B910"} />
              ) : onedevicestatistics.connections ? (
                onedevicestatistics.connections
              ) : (
                0
              )}
            </h3>
            <SmallValue size={"0.64rem"} value={""} />
          </CardContainer>
        </CardCont>

        <CardCont>
          <CardContainer>
            <CardLabel>Online Status</CardLabel>
            <main>
              {onedevicestatistics.isActive ? <GreenCircle /> : <RedCircle />}
              <h3> {onedevicestatistics.isActive ? "Online" : "Offline"} </h3>
            </main>
          </CardContainer>
        </CardCont>
      </StatisticsContainer>

      <EarningContainer>
        <EarningComponent
          filterElements={["24H", "7D", "30D", "ALL"]}
          chartLabels={onedeviceearnlabel}
          chartData={onedeviceearnvalue}
          label={"Earnings"}
          title={"Earnings"}
          link={""}
          percentage={""}
          percentsubvalue={""}
          triggerfilter={handlefilter}
          currfilter={currfilter}
          amount={
            gettingOneDeviceStatistics ? (
              <Loader color={"#E5B910"} />
            ) : (
              numberWithCommaswithoutdecimals(
                precise_round(onedevicestatistics.earnings?.totalEarnings, 2)
              ) + " WNT"
            )
          }
          subvalue={
            gettingOneDeviceStatistics ? (
              <Loader color={"#E5B910"} />
            ) : (
              "$" +
              numberWithCommaswithoutdecimals(
                precise_round(onedevicestatistics.earnings?.totalEarningsUsd, 2)
              )
            )
          }
          description={
            "This is the total amount of WNT earned by this hotsstpot hub device."
          }
          handlemouse={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value:
                  "This is the total amount of WNT earned by this hotsstpot hub device.",
              })
            )
          }
          handlemouseleave={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "",
              })
            )
          }
          pos={0}
        />
      </EarningContainer>

      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label="WNT Staked"
            value={
              gettingOneDeviceStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(
                  Number(precise_round(onedevicestatistics.totalWNTStaked, 2))
                )
              )
            }
            subvalue={
              gettingOneDeviceStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                Number(
                  precise_round(
                    onedevicestatistics.totalWNTStaked * stats.marketPrice,
                    2
                  )
                )
              )
            }
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"WNT Claimed"}
            value={
              gettingClaimHistory ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(
                  Number(precise_round(totalclaimed, 2))
                )
              )
            }
            subvalue={
              gettingClaimHistory ? (
                <Loader color={"#E5B910"} />
              ) : (
                Number(precise_round(totalclaimed * stats.marketPrice, 2))
              )
            }
          />
        </HalfContainer>
      </FlexContainer>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label="Last Activity"
            value={
              gettingOneDeviceStatistics ? (
                <Loader color={"#E5B910"} />
              ) : onedevicestatistics.lastTrafficUpdate ? (
                moment(onedevicestatistics.lastTrafficUpdate).format(
                  "DD-MM-YYYY"
                )
              ) : (
                "--"
              )
            }
            subvalue={
              gettingOneDeviceStatistics ? (
                <Loader color={"#E5B910"} />
              ) : onedevicestatistics.lastTrafficUpdate ? (
                moment(onedevicestatistics.lastTrafficUpdate).format(
                  "h:mm:ssa"
                )
              ) : (
                "--"
              )
            }
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label="Manufacturer/Blockchain/OS"
            value={
              gettingOneDeviceStatistics ? (
                <Loader color={"#E5B910"} />
              ) : onedevicestatistics?.manufacturer? (
                onedevicestatistics?.manufacturer
              ) : (
                "--"
              )
            }
            subvalue={"."}
          />
        </HalfContainer>
      </FlexContainer>
    </Container>
  );
};
