import { useSelector } from "react-redux";
import {
  Container,
  CardCont,
  BottomValue,
  CardFlexContainer,
  FlexContainer,
} from "./style";
import { CardContainer, Loader } from "../../../../components";
import filesize from "filesize";
import { numberWithCommaswithoutdecimals } from "../../../../methods/helper";

export const Statistics = () => {
  const citydevicestatistics = useSelector(
    (state) => state.DeviceReducer.citydevicestatistics
  );
  const gettingCityStatistics = useSelector(
    (state) => state.LoaderReducer.gettingCityStatistics
  );

  return (
    <Container>
      <CardCont>
        <CardContainer>
          <h6>All Devices in City</h6>
          <h3>
            {gettingCityStatistics ? (
              <Loader color={"#E5B910"} />
            ) : citydevicestatistics.devices ? (
              citydevicestatistics.devices.value
            ) : (
              ""
            )}
          </h3>
          <BottomValue>
            {gettingCityStatistics ? (
              <Loader color={"#E5B910"} />
            ) : (
              citydevicestatistics.percentageOnline
            )}
            % online
          </BottomValue>
        </CardContainer>
      </CardCont>

      <FlexContainer>
        <CardFlexContainer>
          <CardContainer>
            <h6>Data Volume</h6>
            <h3>
              {gettingCityStatistics ? (
                <Loader color={"#E5B910"} />
              ) : citydevicestatistics.dataVolume ? (
                filesize(citydevicestatistics?.dataVolume)
              ) : (
                ""
              )}
            </h3>
          </CardContainer>
        </CardFlexContainer>

        <CardFlexContainer>
          <CardContainer>
            <h6>Total Connections</h6>
            <h3>
              {gettingCityStatistics ? (
                <Loader color={"#E5B910"} />
              ) : citydevicestatistics.totalConnectons ? (
                numberWithCommaswithoutdecimals(
                  citydevicestatistics?.totalConnectons
                )
              ) : (
                ""
              )}
            </h3>
          </CardContainer>
        </CardFlexContainer>
      </FlexContainer>
    </Container>
  );
};
