import { Switch, Route, useLocation } from "react-router-dom";
import { routeBuilder } from "./routeBuilder";
import { MainLayout } from "./layout";

export const AppRouter = () => {
  const location = useLocation();

  const title = routeBuilder.map((route, i) =>
    location.pathname === route.path ? route.title : null
  );
  const descr = routeBuilder.map((route, i) =>
    location.pathname === route.path ? route.description : null
  );

  return (
    <MainLayout title={title} descr={descr}>
      <Switch>
        {routeBuilder.map((route, i) => (
          <Route key={i} path={route.path} exact component={route.component} />
        ))}
      </Switch>
    </MainLayout>
  );
};
