import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormDetails } from "../../methods/actions";
import {
  DeviceStatistics,
  MyDevices,
  LatestDevices,
  Cities,
} from "../../container";

export const Devices = () => {
  const dispatch = useDispatch();
  const activetab = useSelector((state) => state.GeneralReducer.activetab);

  useEffect(() => {
    //Find a better way to handle this
    dispatch(
      getFormDetails({
        props: ["activetab"],
        value: 1,
      })
    );
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [
          { id: 1, name: "Statistics" },
          { id: 2, name: "My Devices" },
          { id: 3, name: "Latest Devices" },
          { id: 4, name: "Cities" },
        ],
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 0,
      })
    );
    dispatch(
      getFormDetails({
        props: ["oldroutes"],
        value: [],
      })
    );
    //This block of code
  }, [dispatch]);

  return (
    <>
      {activetab === 1 ? (
        <DeviceStatistics />
      ) : activetab === 2 ? (
        <MyDevices />
      ) : activetab === 3 ? (
        <LatestDevices />
      ) : (
        <Cities />
      )}
    </>
  );
};
