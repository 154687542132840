import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  //margin: 10px 0px 10px 0px;
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const HalfContainer = styled.div`
  width: 48%;
  margin: 0px 0px 0px 0px;
`;

export const SearchInput = styled.input`
  border: none;
  border-bottom: 0.5px solid #c4c4c4;
  padding: 10px;
  padding-left: 30px;
  background-color: transparent;
  width: 100%;
  color: #c4c4c4;
  font-size: 14px;
  :active {
    border: none;
    outline: none;
    border-bottom: 2px solid #ffffff;
  }
  :visited {
    border: none;
    outline: none;
  }
  :focus-visible {
    border: none;
    outline: none;
    border-bottom: 2px solid #ffffff;
  }
  :placeholder {
    font-weight: 100;
    font-size: 14px;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 0;
  top: 20%;
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  width: 35%;
  margin-right: 50px;
  position: relative;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

export const CenterFilter = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;
export const FilterContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
`;

export const FilterSection = styled.div`
  background: #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px;

  div {
    cursor: pointer;
  }
`;

export const CenterDevices = styled.div`
  text-align: center;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.black};
  font-weight: 500;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-content: center;
  align-items: center;
  p {
    color: #6b6b6b;
    font-size: 12px;
    padding-bottom: 0px;
    margin-left: 5px;
  }
`;
