import { useSelector } from "react-redux";
import {
  numberWithCommas,
  numberWithCommaswithoutdecimals,
} from "../methods/helper";
import {
  DescriptionValue,
  DescriptionKey,
  DetailContainer,
  DetailTitle,
} from "./style";

export const MoreInfo = () => {
  let earner = useSelector((state) => state.GeneralReducer.earner);
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);

  return (
    <>
      <DetailContainer>
        <DetailTitle> Device Info</DetailTitle>
        <DescriptionKey>
          {" "}
          Name: <DescriptionValue> {earner.deviceName}</DescriptionValue>
        </DescriptionKey>
        <DescriptionKey>
          {" "}
          Manufacturer/Blockchain/OS: 
        <DescriptionValue> {earner.manufacturer}</DescriptionValue>
        </DescriptionKey>
        <DescriptionKey>
          {" "}
          Rank: <DescriptionValue> No {earner.rank}</DescriptionValue>
        </DescriptionKey>
        <DescriptionKey>
          {" "}
          Device Tier:{" "}
          <DescriptionValue> Tier {earner.deviceTier}</DescriptionValue>
        </DescriptionKey>
        <DescriptionKey>
          {" "}
          WNT Staked:{" "}
          <DescriptionValue>
            {" "}
            {numberWithCommas(earner.wntStaked, 2)} WNT ~
          </DescriptionValue>{" "}
          <DescriptionValue>
            {" "}
            ${numberWithCommas(earner.wntStakedUSD, 2)}
          </DescriptionValue>
        </DescriptionKey>
        <DescriptionKey>
          {" "}
          Staking Score:{" "}
          <DescriptionValue>
            {" "}
            {earner.stakingScore === 0
              ? "---"
              : numberWithCommaswithoutdecimals(earner.stakingScore)}
          </DescriptionValue>{" "}
        </DescriptionKey>
      </DetailContainer>
      <DetailContainer>
        <DetailTitle> Connections</DetailTitle>
        <DescriptionKey>
          {" "}
          Total Device Connections:{" "}
          <DescriptionValue> {earner.totalDeviceConnections}</DescriptionValue>
        </DescriptionKey>
        <DescriptionKey>
          {" "}
          Total Unique Connections:{" "}
          <DescriptionValue> {earner.uniqueDeviceConnections}</DescriptionValue>
        </DescriptionKey>
      </DetailContainer>
      <DetailContainer>
        <DetailTitle> Usage</DetailTitle>
        <DescriptionKey>
          {" "}
          Data Usage:{" "}
          <DescriptionValue> {earner.dataUsageInGB} GB</DescriptionValue>
        </DescriptionKey>
        <DescriptionKey>
          {" "}
          Uptime In hours:{" "}
          <DescriptionValue>
            {" "}
            {earner.uptimeInHours} hours
          </DescriptionValue>{" "}
        </DescriptionKey>
      </DetailContainer>
      <DetailContainer>
        <DetailTitle> Reward</DetailTitle>
        <DescriptionKey>
          {" "}
          Total Rewards:{" "}
          <DescriptionValue>
            {" "}
            {numberWithCommas(earner.totalRewards, 2)} WNT ~
          </DescriptionValue>{" "}
          <DescriptionValue>
            {" "}
            ${numberWithCommas(earner.totalRewards * stats.marketPrice, 2)}
          </DescriptionValue>
        </DescriptionKey>
      </DetailContainer>
    </>
  );
};
