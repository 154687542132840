import styled from "styled-components";
import { size } from "../../theme";

export const Container = styled.div`
  // position: relative;
  // display: flex;
  // flex-wrap: wrap;
  // heigth: 100vh;
  // @media (max-width: ${size.tablet}) {
  //   flex-direction: column-reverse;
  //   position: sticky;
  //   z-index: 1;
  // }
`;

export const TopNav = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.blue_gradient};
  top: 0;
  padding: 20px 42px;
  width: calc(100% - 84px);
  left: 0;
`;

export const MobileNavContainer = styled.div`
  display: block;
  @media (min-width: ${size.tablet}) {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  position: absolute;
  z-index: 1000;
  width: 30%;
  display: ${(props) => (props.layout ? "block" : "none")};
  height: 100vh;
  background: ${({ theme }) => theme.white2};

  @media (max-width: ${size.tablet}) {
    width: 100%;
    height: calc(100vh - 20px);
  }
`;

export const Toggle = styled.div`
  height: 51px;
  width: 35px;
  position: absolute;
  top: 50%;
  border-radius: 0px 12px 12px 0px;
  left: 30%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;
`;

export const CloseToggle = styled.div`
  height: 51px;
  width: 35px;
  position: absolute;
  top: 50%;
  border-radius: 0px 12px 12px 0px;
  left: 0%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;
  transition: top 3s;
`;

export const MapContainer = styled.div`
  width: 100%;
  position: relative;
  visibility: hidden;
  background-color: #343332;
  z-index: 1;
  @media (min-width: ${size.tablet}) {
    visibility: visible;
  }
  // @media (max-width: ${size.tablet}) {
  //   flex: none;
  //   width: 100%;
  //   height: 400px !important;
  // }
  // @media (max-width: ${size.mobileL}) {
  //   display: none;
  // }
`;

export const FloatingBtn = styled.div`
  position: absolute;
  right: 0%;
  cursor: pointer;
  z-index: 1000;
  animation: 0.8s infinite beatHeart;
`;
