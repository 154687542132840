import styled from "styled-components";

export const Container = styled.div``;

export const CardCont = styled.div`
  margin-top: 10px;
`;

export const FlexCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexCard = styled.div`
  margin-top: 10px;
  width: 48%;
`;

export const InfoIcon = styled.img`
  margin-left: 5px;
`;
