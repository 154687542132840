import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ArrowContainer,
  CityName,
  ScrollContainer,
} from "../../../../components";
import { getDeviceTransactions } from "../../../../methods/actions";
import { CardCont, CenterTransactions } from "./style";
import moment from "moment";
import { formathash } from "../../../../methods/helper";
import { PageLoader } from "../../../../container";

export const Transaction = () => {
  const dispatch = useDispatch();
  const deviceTransactions = useSelector(
    (state) => state.DeviceReducer.deviceTransactions
  );
  const gettingDeviceTransactions = useSelector(
    (state) => state.LoaderReducer.gettingDeviceTransactions
  );
  let { tokenId, deviceId } = useParams();

  useEffect(() => {
    dispatch(getDeviceTransactions(tokenId, deviceId, 1, 100));
  }, [dispatch, deviceId, tokenId]);

  return (
    <ScrollContainer height={"500px"} margin={"230px"}>
      {gettingDeviceTransactions ? (
        <PageLoader />
      ) : deviceTransactions.length > 0 ? (
        deviceTransactions.map((transaction, i) => (
          <CardCont key={i}>
            <ArrowContainer
              link={`/transaction_stats/${transaction.transactionId}`}
            >
              <div>
                <h3>{formathash(transaction.transactionId)}</h3>
                <CityName
                  flag={"/assets/svgs/clock.svg"}
                  name={moment(transaction.sessionTimeStamp).format(
                    "Do MMMM YYYY, h:mm"
                  )}
                />
              </div>
            </ArrowContainer>
          </CardCont>
        ))
      ) : (
        <CenterTransactions>
          {" "}
          <CenterTransactions>
            <img src="/assets/img/light.svg" alt="empty" />
          </CenterTransactions>
          You have no transactions on this device yet
        </CenterTransactions>
      )}
    </ScrollContainer>
  );
};
