import _const from "../../constants/types";
import axios from "axios";
import config from "../config";
import { togglemodal } from "./generalActions";
import { validateamount } from "../helper";

//Gets all NFT devices
export const getAllDevices = (pageNumber, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ALL_DEVICES,
      payload: true,
    });
    axios
      .get(
        config.apiUrl + `/Device?pageNumber=${pageNumber}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_ALL_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.ALL_DEVICES,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ALL_DEVICES,
          payload: false,
        });
      });
  };
};

//Get device transactions
export const getDeviceTransactions = (
  tokenId,
  deviceId,
  pageNumber,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_TRANSACTIONS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Device/Transactions?tokenId=${
            tokenId === 0 ? "" : tokenId
          }&deviceId=${deviceId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_TRANSACTIONS,
          payload: false,
        });
        dispatch({
          type: _const.DEVICE_TRANSACTIONS,
          payload: res.data.data.records,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_TRANSACTIONS,
          payload: false,
        });
      });
  };
};

//Gets device statistics
export const getDeviceStatistics = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_STATISTICS,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Device/Statistics`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_STATISTICS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_DEVICE_STATISTICS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_STATISTICS,
          payload: false,
        });
      });
  };
};

//Get device statistics chartpoint
export const getDeviceStatisticsChartPoint = (lookBackPeriodInHours, chartLookbackPeriodInDays) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_STATISTICS_CHARTPOINT,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Device/Statistics/ChartPoint?lookBackPeriodInHours=${lookBackPeriodInHours}&chartLookbackPeriodInDays=${chartLookbackPeriodInDays}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_STATISTICS_CHARTPOINT,
          payload: false,
        });
        dispatch({
          type: _const.ALL_DEVICE_STATISTICS_CHARTPOINT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_STATISTICS_CHARTPOINT,
          payload: false,
        });
      });
  };
};

//Gets one device statistics
export const getOneDeviceStatistics = (
  tokenId,
  deviceId,
  lookBackPeriodInHours,
  chartLookbackPeriodInDays
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ONE_DEVICE_STATISTICS,
      payload: true,
    });
    dispatch({
      type: _const.CLEAR_OLD_STATISTICS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Device/DeviceStatistics?tokenId=${
            Number(tokenId) === 0 ? "" : tokenId
          }&deviceId=${
            deviceId ? deviceId : ""
          }&lookBackPeriodInHours=${lookBackPeriodInHours}&chartLookbackPeriodInDays=${chartLookbackPeriodInDays}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_ONE_DEVICE_STATISTICS,
          payload: false,
        });
        dispatch({
          type: _const.ONE_DEVICE_STATISTICS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ONE_DEVICE_STATISTICS,
          payload: false,
        });
      });
  };
};

//Gets a list of my NFT devices
export const getMyDevices = (
  walletAddress,
  pageNumber,
  pageSize,
  deviceName
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_MY_DEVICES,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Device/MyNfts?walletAddress=${walletAddress}&pageNumber=${pageNumber}&pageSize=${pageSize}&deviceName=${deviceName}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_MY_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.ALL_MY_DEVICES,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_MY_DEVICES,
          payload: false,
        });
      });
  };
};

//Get latest devices
export const getLatestDevices = (limit) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_LATEST_DEVICES,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Device/LatestDevice?limit=${limit}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_LATEST_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.ALL_LATEST_DEVICES,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_LATEST_DEVICES,
          payload: false,
        });
      });
  };
};

//Get latest cities with devices
export const getDeviceCities = (city, pageNumber, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_CITIES,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Device/Cities/Statistics?city=${city}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_CITIES,
          payload: false,
        });
        dispatch({
          type: _const.ALL_DEVICE_CITIES,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_CITIES,
          payload: false,
        });
      });
  };
};

//Get statistics for one city
export const getCityDeviceStatistics = (city) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_CITY_STATISTICS,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Device/CityStatistics?city=${city}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_CITY_STATISTICS,
          payload: false,
        });
        dispatch({
          type: _const.CITY_DEVICE_STATISTICS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_CITY_STATISTICS,
          payload: false,
        });
      });
  };
};

//Get devices for a city
export const getDevicesInCity = (
  city,
  pageNumber,
  pageSize,
  deviceName,
  status
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICES_IN_CITY,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Device/NftsInCity/${city}?pageNumber=${pageNumber}&pageSize=${pageSize}&deviceName=${deviceName}&status=${status}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICES_IN_CITY,
          payload: false,
        });
        dispatch({
          type: _const.ALL_DEVICES_IN_CITY,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICES_IN_CITY,
          payload: false,
        });
      });
  };
};

//Get devices by total volume
export const getDevicesByVolume = (city, pageNumber, pageSize, search) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICES_IN_CITY,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Device/Cities/DevicesByTotalDataVolume?city=${city}&page=${pageNumber}&pageSize=${pageSize}&search=${search}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICES_IN_CITY,
          payload: false,
        });
        dispatch({
          type: _const.DEVICES_BY_VOLUME,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICES_IN_CITY,
          payload: false,
        });
      });
  };
};

//Get devices by total data connections
export const getDevicesByConnections = (city, pageNumber, pageSize, search) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICES_IN_CITY,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/Device/Cities/DevicesByTotalDataConnections?city=${city}&page=${pageNumber}&pageSize=${pageSize}&search=${search}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICES_IN_CITY,
          payload: false,
        });
        dispatch({
          type: _const.DEVICES_BY_CONNECTIONS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICES_IN_CITY,
          payload: false,
        });
      });
  };
};

//Simulate device connection
export const startDeviceSimulation = (
  tokenId,
  duration,
  rate,
  macAddress,
  deviceName,
  walletAddress
) => {
  let data = {
    tokenId: tokenId,
    duration: Number(duration),
    rate: Number(rate),
    macAddress: macAddress,
    deviceName: deviceName,
  };
  return (dispatch) => {
    if (!validateamount(Number(rate))) {
      dispatch({
        type: _const.SIMULATION_FAILED,
        payload: "Please enter a valid amount",
      });
    } else {
      dispatch({
        type: _const.SIMULATION_FAILED,
        payload: "",
      });
      dispatch({
        type: _const.STARTING_SIMULATION,
        payload: true,
      });
      axios
        .post(config.apiUrl + `/DeviceNft/StartSimulation`, data)
        .then((res) => {
          dispatch({
            type: _const.STARTING_SIMULATION,
            payload: false,
          });
          dispatch(togglemodal(false, 0));
          dispatch({
            type: _const.DEVICE_CONNECTED,
            payload: res.data.data,
          });
          dispatch(getDeviceStatistics());
          //dispatch(getMyDevices(walletAddress, 1, 100, ""));
        })
        .catch((err) => {
          dispatch({
            type: _const.STARTING_SIMULATION,
            payload: false,
          });
          dispatch({
            type: _const.SIMULATION_FAILED,
            payload: err.response.data.title,
          });
        });
    }
  };
};

//Get device leaderboard
export const getDeviceLeaderBoard = (
  timeline,
  startTime,
  endTime,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_LEADERBOARD,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/reward-leader-board/devices?timeline=${timeline}&startTime=${startTime}&endTime=${endTime}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_LEADERBOARD,
          payload: false,
        });
        dispatch({
          type: _const.DEVICE_LEADERBOARD,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_LEADERBOARD,
          payload: false,
        });
      });
  };
};

//Get device weekly leaderboard
export const getDeviceWeeklyLeaderBoard = (week, page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_LEADERBOARD,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/reward-leader-board/weekly-reward/devices?week=${week}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_LEADERBOARD,
          payload: false,
        });
        dispatch({
          type: _const.DEVICE_LEADERBOARD,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_LEADERBOARD,
          payload: false,
        });
      });
  };
};

//Get current week
export const getCurrentWeek = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_CURRENT_WEEK,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/reward-leader-board/current-week`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_CURRENT_WEEK,
          payload: false,
        });
        dispatch({
          type: _const.CURRENT_WEEK,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_CURRENT_WEEK,
          payload: false,
        });
      });
  };
};

export const updateLeaderBoard = () => {
  return (dispatch) => {
    dispatch({ type: _const.UPDATE_LEADERBOARD, payload: true });
  };
};

//Get latest device 
export const getLatestDevice = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_LATEST_DEVICES,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/Device/LatestDevice/Details`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_LATEST_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.LATEST_DEVICE,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_LATEST_DEVICES,
          payload: false,
        });
      });
  };
};