import {
  BillingRate,
  RadioButton,
  Button,
  Label,
  Input,
  Dropdown,
  Loader,
  Error
} from "../components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputContainer,
  ConnectDeviceContainer,
  ButtonContainer
} from "./style";

import { genMAC, durationMinutes } from "../methods/helper";
import { getFormDetails, startDeviceSimulation } from "../methods/actions";

export const ConnectDevice = () => {
  const dispatch = useDispatch();
  const macAddress = useSelector(state => state.GeneralReducer.macAddress);
  const durationstate = useSelector(
    state => state.GeneralReducer.durationstate
  );
  const rate = useSelector(state => state.GeneralReducer.rate);
  const tokenId = useSelector(state => state.GeneralReducer.tokenId);
  const duration = useSelector(state => state.GeneralReducer.duration);
  const deviceName = useSelector(state => state.GeneralReducer.deviceName);
  const startingSimulation = useSelector(
    state => state.LoaderReducer.startingSimulation
  );
  const showcontent = useSelector(state => state.GeneralReducer.showcontent);
  const connectionerr = useSelector(state => state.DeviceReducer.connectionerr);
  const walletAddress = useSelector(
    state => state.ConnectWalletReducer.address
  );

  const generateAddres = () => {
    let macAddress = genMAC();
    dispatch(
      getFormDetails({
        props: ["macAddress"],
        value: macAddress
      })
    );
  };

  useEffect(() => {
    let macAddress = genMAC();
    dispatch(
      getFormDetails({
        props: ["macAddress"],
        value: macAddress
      })
    );
  }, [dispatch]);

  return (
    <ConnectDeviceContainer>
      <InputContainer>
        <Input
          label={"Device Name"}
          placeholder={""}
          value={deviceName}
          handleChange={e =>
            dispatch(
              getFormDetails({
                props: ["deviceName"],
                value: e.target.value
              })
            )
          }
          icon={""}
          handleClick={""}
          disabled={false}
        />
      </InputContainer>
      <InputContainer>
        <Input
          label={"Client Mac address"}
          placeholder={"FF.FF.FF.CC"}
          value={macAddress}
          handleChange={() => console.log("Mac Address")}
          icon={"/assets/icons/refresh.png"}
          handleClick={generateAddres}
          disabled={true}
        />
      </InputContainer>
      <InputContainer>
        <Label label={"Conection Duration"} /> <br />
        <RadioButton
          onClick={() =>
            dispatch(
              getFormDetails({
                props: ["durationstate"],
                value: 1
              })
            )
          }
          label="Limited"
          checked={durationstate === 1}
          disabled={false}
          name={"duration"}
        />
        <RadioButton
          onClick={() => {
            dispatch(
              getFormDetails({
                props: ["durationstate"],
                value: 2
              })
            );
            dispatch(
              getFormDetails({
                props: ["duration"],
                value: 24 * 60 * 60
              })
            );
          }}
          label="Unlimited"
          checked={durationstate === 2}
          disabled={false}
          name={"duration"}
        />
        {durationstate === 1 ? (
          <Dropdown
            label={""}
            placeholder={"30 Minutes"}
            value={duration + " Minute(s)"}
            content={durationMinutes()}
            handleChange={e => {
              dispatch(
                getFormDetails({
                  props: ["duration"],
                  value: e.target.value
                })
              );
              dispatch(
                getFormDetails({
                  props: ["showcontent"],
                  value: false
                })
              );
            }}
            disabled={true}
            icon={"/assets/icons/dropdown.png"}
            showcontent={showcontent}
            handleDropdown={() =>
              dispatch(
                getFormDetails({
                  props: ["showcontent"],
                  value: !showcontent
                })
              )
            }
          />
        ) : (
          ""
        )}
      </InputContainer>
      <InputContainer>
        <BillingRate
          handlechange={e =>
            dispatch(
              getFormDetails({
                props: ["rate"],
                value: e.target.value
              })
            )
          }
          price={rate}
          priceValue={"USD/MB"}
          placeholder={"Tap to enter rate"}
          rate={""}
        />
      </InputContainer>
      {connectionerr ? <Error msg={connectionerr} /> : ""}
      <ButtonContainer>
        <Button
          onClick={() =>
            dispatch(
              startDeviceSimulation(
                tokenId,
                duration,
                rate,
                macAddress,
                deviceName,
                walletAddress
              )
            )
          }
          buttonClass="primary"
          label={startingSimulation ? <Loader /> : "Connect Device"}
        />
      </ButtonContainer>
    </ConnectDeviceContainer>
  );
};
