
import {useSelector } from "react-redux";
import {
  CityName,
  DeviceOverview,
  Loader
} from "../components";
import { formathash } from "../methods/helper";
import moment from "moment";


export const TransactionNavDets = () => {

  const blockTransactionDetail = useSelector(
    state => state.BlockHeightReducer.blockTransactionDetail
  );
  const gettingTransactionDetail = useSelector(
    state => state.LoaderReducer.gettingTransactionDetail
  );

  return (
    <DeviceOverview
    title={
      gettingTransactionDetail ? (
        <Loader />
      ) : (
        formathash(blockTransactionDetail.transactionId)
      )
    }
  >
    <CityName
      flag={"/assets/svgs/clock.svg"}
      name={
        gettingTransactionDetail ? (
          <Loader />
        ) : (
          moment(blockTransactionDetail.sessionTimeStamp).format(
            "Do MMMM YYYY, h:mm"
          )
        )
      }
    />
  </DeviceOverview>
  );
};
