import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  p {
    margin-left: 11px;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.grey4};
  }
`;

export const CountryName = styled.p`
  text-transform: capitalize;
  margin-right: 5px;
`;
