import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LatestDeviceComponent, ScrollContainer, Search } from "../components";
import { CardCont, CenterDevices, SearchContainer } from "./style";
import { getLatestDevices } from "../methods/actions";
import { PageLoader } from "./pageloader";
import moment from "moment";

export const LatestDevices = () => {
  const [searchValues, seearchItems] = useState([]);

  const dispatch = useDispatch();
  const latestdevices = useSelector(
    (state) => state.DeviceReducer.latestdevices
  );
  const gettinglatestdevices = useSelector(
    (state) => state.LoaderReducer.gettinglatestdevices
  );

  useEffect(() => {
    dispatch(getLatestDevices(30));
  }, [dispatch]);

  const implementSearch = (value) => {
    let devices = latestdevices.filter((each) =>
      each.deviceName.includes(value)
    );
    seearchItems(devices);
  };

  let mapValues = searchValues.length !== 0 ? searchValues : latestdevices;

  return (
    <>
      {gettinglatestdevices ? (
        <PageLoader />
      ) : (
        <div>
          {mapValues.length > 0 ? (
            <SearchContainer margin="100px">
              <Search
                placeholder="Search Latest Devices"
                handleChange={(e) => implementSearch(e.target.value)}
              />
            </SearchContainer>
          ) : null}
          <ScrollContainer height={"500px"}>
            {mapValues.length > 0 ? (
              mapValues.map((latest, i) => (
                <CardCont key={i}>
                  <LatestDeviceComponent
                    link={`/my_device/statistics/${latest.tokenId}/${latest.deviceId}`}
                    title={latest.deviceName}
                    flag={latest.location ? latest.location.flagIconSmall : ""}
                    active={latest.hasActiveSession}
                    countryName={
                      latest.location.city
                        ? latest.location.city + ", " + latest.location.country
                        : "--"
                    }
                    lastactive={
                      latest.lastTrafficUpdate === 0
                        ? "Never Active"
                        : moment(latest.lastTrafficUpdate).format(
                            "DD-MM-YYYY, h:mma"
                          )
                    }
                    tier={latest}
                  />
                </CardCont>
              ))
            ) : (
              <CenterDevices>
                <CenterDevices>
                  <img src="/assets/img/light.svg" alt="empty" />
                </CenterDevices>
                No device has been minted lately
              </CenterDevices>
            )}
          </ScrollContainer>
        </div>
      )}{" "}
    </>
  );
};
