import { ArrowContainer } from "../arrowContainer";
import {
  Info,
  Container,
  WalletAddress,
  Hash,
  CountryContainer,
  Country,
  DevicesNumber
} from "./style";

export const OutlierCard = ({
  address,
  avatar,
  flag,
  country,
  no_of_devices,
  copy,
  link
}) => {
  return (
    <ArrowContainer link={link}>
      <Info>
        <Container>
          <img src={avatar} height="30px" alt={""} />
          <WalletAddress>{address}</WalletAddress>
          <Hash onClick={copy} src={"/assets/svgs/hash.svg"} alt={"hash"} />
          <CountryContainer>
            {flag ? (
              <img height={"10px"} width={"20px"} src={flag} alt="country flag" />
            ) : (
              <div> --</div>
            )}
          </CountryContainer>
          <Country> {country}</Country>
        </Container>
        <main>
          <img src={"/assets/svgs/devices.svg"} alt={"device-icon"} />
          <DevicesNumber>{no_of_devices}</DevicesNumber>
        </main>
      </Info>
    </ArrowContainer>
  );
};
