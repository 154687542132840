import { useHistory } from "react-router";
import { WICRYPT_EXPLORER } from "../constants/routes";

export const Logo = () => {
  const history = useHistory();

  return (
    <img
      onClick={() => history.push(WICRYPT_EXPLORER)}
      src={"/assets/svgs/logomark.svg"}
      alt={""}
    />
  );
};
