import styled from "styled-components";

export const InfoView = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Info = styled.div`
  margin-top: 18px;
`;

export const TabContainer = styled.div`
  // margin-top: 250px;
`;
