import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IspsContainer,
  IspHeader,
  FlexContainer,
  ISPFlexContainer,
  IspBody,
  IspOption,
  Check,
  CenterDevices,
} from "./style";
import { Search, ScrollContainer } from "../../components";
import { getFormDetails } from "../../methods/actions";
import { searchItem } from "../../methods/helper";

const Isps = () => {
  const dispatch = useDispatch();
  const isplist = useSelector((state) => state.GeneralReducer.isplist);
  const colors = useSelector((state) => state.GeneralReducer.colors);
  const selectedisp = useSelector((state) => state.GeneralReducer.selectedisp);
  const [searchValues, seearchItems] = useState([]);
  const [text, setText] = useState("");

  const handleClose = () => {
    dispatch(
      getFormDetails({
        props: ["activemapoption"],
        value: 0,
      })
    );
  };

  const implementSearch = (value) => {
    let res = searchItem(value, isplist);
    setText(value);
    seearchItems(res);
  };

  let list = text ? searchValues : isplist;

  return (
    <IspsContainer>
      <IspHeader>
        <FlexContainer>
          <img src="/assets/icons/signature.svg" alt="signature" />
          <p> Find ISPs</p>
        </FlexContainer>
        <div onClick={() => handleClose()}>
          <img src="/assets/svgs/close.svg" alt="close" />
        </div>
      </IspHeader>
      <IspBody>
        <IspOption active={true}>
          <ISPFlexContainer
            onClick={() =>
              dispatch(
                getFormDetails({
                  props: ["selectedisp"],
                  value: "",
                })
              )
            }
          >
            <p> </p>
            <h6> Default </h6>
          </ISPFlexContainer>
          {!selectedisp.isp ? (
            <Check>
              {" "}
              <img src="/assets/icons/check.svg" alt="check" />{" "}
            </Check>
          ) : null}
        </IspOption>

        <Search
          placeholder="Search  ISPs"
          handleChange={(e) => implementSearch(e.target.value)}
        />

        <ScrollContainer>
          {list.length > 0 ? (
            list.map((each, i) => {
              return (
                <IspOption
                  active={selectedisp.isp === each}
                  onClick={() =>
                    dispatch(
                      getFormDetails({
                        props: ["selectedisp"],
                        value: { id: i, color: colors[i], isp: each },
                      })
                    )
                  }
                  key={i}
                  color={colors[i]}
                >
                  <ISPFlexContainer>
                    <div>
                      <p> </p>
                    </div>
                    <h6> {each} </h6>
                  </ISPFlexContainer>
                  {selectedisp.isp === each ? (
                    <Check>
                      {" "}
                      <img src="/assets/icons/check.svg" alt="check" />{" "}
                    </Check>
                  ) : null}
                </IspOption>
              );
            })
          ) : (
            <CenterDevices>
              {" "}
              <CenterDevices>
                <img src="/assets/img/light.svg" alt="empty" />
              </CenterDevices>{" "}
              Oops this ISP does not exist on the explorer
            </CenterDevices>
          )}
        </ScrollContainer>
      </IspBody>
    </IspsContainer>
  );
};

export default Isps;
