import styled from "styled-components";

export const Container = styled.div`
  //margin-top: 230px;
`;

export const CardCont = styled.div`
  margin-top: 21.02px;

  h6 {
    color: ${({ theme }) => theme.grey7};
  }

  h3 {
    margin-top: 5.89px;
    margin-bottom: 3.76px;
    color: ${({ theme }) => theme.gradient_black};
  }
`;

export const BottomValue = styled.p`
  color: ${({ theme }) => theme.grey4};
  font-size: 0.75rem;
`;

export const CardFlexContainer = styled.div`
  width: 48%;
  margin-top: 10px;

  h6 {
    color: ${({ theme }) => theme.grey7};
  }

  h3 {
    margin-top: 5.89px;
    margin-bottom: 3.76px;
    color: ${({ theme }) => theme.gradient_black};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
