import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Logo, Tabs, NoBreadCrumbTab, BreadCrumb } from "../../components";
import {
  OutlierNavDetail,
  DeviceNavDetails,
  BlockstatnavDetails,
  TransactionNavDets,
  CityNavDetails,
} from "../../container";
import {
  Header,
  HeaderTitle,
  HeaderInfo,
  Info,
  TopNav,
  NavDetails,
  LogoContainer,
} from "./style";

export const SideBar = ({ title, descr, children }) => {
  const currtabs = useSelector((state) => state.GeneralReducer.currtabs);
  const activetab = useSelector((state) => state.GeneralReducer.activetab);
  const hasdets = useSelector((state) => state.GeneralReducer.hasdets);
  const oldroutes = useSelector((state) => state.GeneralReducer.oldroutes);
  const newroute = useSelector((state) => state.GeneralReducer.newroute);

  const [divHeight, setDivHeight] = useState(0);

  const elementRef = useRef(null);

  useEffect(() => {
    setDivHeight(elementRef.current.clientHeight);
  }, [elementRef?.current?.clientHeight]);

  return (
    <Info>
      <TopNav ref={elementRef}>
        <LogoContainer>
          <Logo />
        </LogoContainer>

        <Header>
          {oldroutes.length > 0 ? (
            <BreadCrumb oldroutes={oldroutes} newroute={newroute} />
          ) : null}

          {hasdets === 1 ? (
            <OutlierNavDetail />
          ) : hasdets === 2 ? (
            <DeviceNavDetails />
          ) : hasdets === 3 ? (
            <BlockstatnavDetails />
          ) : hasdets === 4 ? (
            <TransactionNavDets />
          ) : hasdets === 5 ? (
            <CityNavDetails />
          ) : null}
          {title ? <HeaderTitle>{title}</HeaderTitle> : null}
          {descr ? <HeaderInfo> {descr}</HeaderInfo> : null}
          {currtabs.length > 0 ? (
            <NoBreadCrumbTab
              tabs={<Tabs tablist={currtabs} selectedtab={activetab} />}
            />
          ) : null}
        </Header>
      </TopNav>

      <NavDetails height={divHeight}>{children}</NavDetails>
    </Info>
  );
};
