import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormDetails } from "../../methods/actions";
import { Overview, Transaction } from "./container";
import { TabContainer } from "./style";

export const BlockStat = () => {
  const dispatch = useDispatch();
  const activetab = useSelector((state) => state.GeneralReducer.activetab);

  useEffect(() => {
    //Find a better way to handle this
    dispatch(
      getFormDetails({
        props: ["activetab"],
        value: 1,
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 3,
      })
    );
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [
          { id: 1, name: "Overview" },
          { id: 2, name: "Transactions" },
        ],
      })
    );
    dispatch(
      getFormDetails({
        props: ["newroute"],
        value: { id: 1, title: "Block Stats" },
      })
    );
    dispatch(
      getFormDetails({
        props: ["oldroutes"],
        value: [
          { id: 1, link: "/", title: "Home" },
          { id: 2, link: "/block_height", title: "Statistics" },
        ],
      })
    );
    //This block of code
  }, [dispatch]);

  return (
    <TabContainer>
      {activetab === 1 ? <Overview /> : <Transaction />}
    </TabContainer>
  );
};
