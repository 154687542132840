import { useDispatch, useSelector } from "react-redux";
import {
  TimelineContainer,
  OptionContainer,
  Option,
  Check,
  CheckActive,
} from "./style";
import {
  getFormDetails,
  togglemodal,
  updateLeaderBoard,
} from "../methods/actions";

export const Timeline = () => {
  let dispatch = useDispatch();
  let timelineList = useSelector((state) => state.GeneralReducer.timelineList);
  let selectedTimeline = useSelector(
    (state) => state.GeneralReducer.selectedTimeline
  );

  const handleClick = (option) => {
    if (option.id === "Custom") {
      dispatch(togglemodal(true, 4));
    } else {
      dispatch(togglemodal(false, 0));
      dispatch(updateLeaderBoard());
    }
    dispatch(
      getFormDetails({
        props: ["selectedTimeline"],
        value: option,
      })
    );
  };

  return (
    <TimelineContainer>
      <OptionContainer>
        {timelineList.map((each) => {
          return (
            <Option key={each.id} onClick={() => handleClick(each)}>
              {" "}
              {each.timeline}
              <Check>
                {" "}
                {selectedTimeline.id === each.id ? <CheckActive /> : null}
              </Check>
            </Option>
          );
        })}
      </OptionContainer>
    </TimelineContainer>
  );
};
