import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowCard,
  Loader,
  EarningComponent,
  ScrollContainer,
} from "../components";
import { Container, HalfContainer, FlexContainer } from "./style";
import {
  getOutlierDetails,
  getClaimHistory,
  getFormDetails,
} from "../methods/actions";
import { useParams } from "react-router-dom";
import {
  numberWithCommaswithoutdecimals,
  precise_round,
} from "../methods/helper";

export const OutlierOverview = () => {
  const dispatch = useDispatch();
  let { walletAddress } = useParams();
  const outlierdetail = useSelector(
    (state) => state.OutlierReducer.outlierdetail
  );
  const gettingOutlierDetail = useSelector(
    (state) => state.LoaderReducer.gettingOutlierDetail
  );
  const gettingClaimHistory = useSelector(
    (state) => state.LoaderReducer.gettingClaimHistory
  );
  const earningvalues = useSelector(
    (state) => state.OutlierReducer.earningvalues
  );
  const earninglabel = useSelector(
    (state) => state.OutlierReducer.earninglabel
  );
  const marketStatistics = useSelector(
    (state) => state.MarketReducer.marketStatistics
  );
  const totalclaimed = useSelector((state) => state.RewardReducer.totalclaimed);

  const [currfilter, setCurrfilter] = useState("ALL");

  const handlefilter = (val) => {
    setCurrfilter(val);
    if (val === "24H") {
      dispatch(getOutlierDetails(walletAddress, 24, 0));
    } else if (val === "30D") {
      dispatch(getOutlierDetails(walletAddress, 0, 30));
    } else if (val === "ALL") {
      dispatch(getOutlierDetails(walletAddress, " ", " "));
    } else {
      dispatch(getOutlierDetails(walletAddress, 0, 7));
    }
  };

  useEffect(() => {
    dispatch(getOutlierDetails(walletAddress, "", ""));
    dispatch(getClaimHistory("", walletAddress, "", ""));
  }, [dispatch, walletAddress]);

  return (
    <ScrollContainer height={"500px"} margin={"220px"}>
      <Container>
        <ArrowCard
          label="Total WNT Balance"
          value={
            gettingOutlierDetail ? (
              <Loader color={"#E5B910"} />
            ) : (
              `${numberWithCommaswithoutdecimals(
                precise_round(outlierdetail.wntBalance, 2)
              )} WNT`
            )
          }
          subvalue={
            gettingOutlierDetail ? (
              <Loader color={"#E5B910"} />
            ) : (
              `$${precise_round(outlierdetail.wntusdEquivalent, 2)}`
            )
          }
          percentage={""}
          color={"grey2"}
          percentsubvalue={""}
        />
      </Container>
      <Container>
        <EarningComponent
          filterElements={["24H", "7D", "30D", "ALL"]}
          chartLabels={earninglabel}
          chartData={earningvalues}
          title={"Earnings"}
          amount={
            gettingOutlierDetail ? (
              <Loader color={"#E5B910"} />
            ) : (
              numberWithCommaswithoutdecimals(
                precise_round(outlierdetail.earning.totalEarnings, 2)
              ) + " WNT"
            )
          }
          subvalue={
            gettingOutlierDetail ? (
              <Loader color={"#E5B910"} />
            ) : (
              numberWithCommaswithoutdecimals(
                "$ " +
                  precise_round(outlierdetail?.earning?.totalEarningsUsd, 2)
              )
            )
          }
          description={
            "This is the total amount of WNT earned by this outlier."
          }
          handlemouse={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value:
                  "This is the total amount of WNT earned by this outlier.",
              })
            )
          }
          handlemouseleave={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "",
              })
            )
          }
          pos={0}
          currfilter={currfilter}
          triggerfilter={handlefilter}
        />
      </Container>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label="Devices"
            handlechange={() =>
              dispatch(
                getFormDetails({
                  props: ["activetab"],
                  value: 2,
                })
              )
            }
            value={
              gettingOutlierDetail ? (
                <Loader color={"#E5B910"} />
              ) : (
                outlierdetail.numberOfDevices
              )
            }
            subvalue={""}
            description={
              "This is the total devices - minted and un-minted - owned by this Outlier"
            }
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the total devices - minted and un-minted - owned by this Outlier",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            pos={0}
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"Connections"}
            value={
              gettingOutlierDetail ? (
                <Loader color={"#E5B910"} />
              ) : (
                outlierdetail.numberOfConnections
              )
            }
            subvalue={outlierdetail.connectionChange}
          />
        </HalfContainer>
      </FlexContainer>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label="WNT Staked"
            value={
              gettingOutlierDetail ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(outlierdetail.totalWNTStaked)
              )
            }
            subvalue={
              gettingOutlierDetail ? (
                <Loader color={"#E5B910"} />
              ) : (
                "$" +
                Number(
                  precise_round(
                    outlierdetail.totalWNTStaked * marketStatistics.marketPrice,
                    2
                  )
                )
              )
            }
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"WNT Claimed"}
            value={
              gettingClaimHistory ? <Loader color={"#E5B910"} /> : totalclaimed
            }
            subvalue={
              gettingClaimHistory ? (
                <Loader color={"#E5B910"} />
              ) : (
                "$" +
                numberWithCommaswithoutdecimals(
                  Number(
                    precise_round(
                      totalclaimed * marketStatistics.marketPrice,
                      2
                    )
                  )
                )
              )
            }
          />
        </HalfContainer>
      </FlexContainer>
    </ScrollContainer>
  );
};
