import styled from "styled-components";

export const TopNavContainer = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  height: 50px;
  left: 0;
  right: 0;
  top: 3%;
  margin-left: ${(props) => (props.layout ? "30%" : "auto")};
  margin-right: auto;
  width: 80%;
`;

export const SearchContainer = styled.div`
  width: 35%;
  margin-right: 50px;
  position: relative;
`;

export const Search = styled.img`
  position: absolute;
  right: 0;
  top: 20%;
  cursor: pointer;
`;
export const SearchInput = styled.input`
  border: none;
  border-bottom: 1px solid #ffffff;
  padding: 10px;
  background-color: transparent;
  width: 100%;
  color: #c4c4c4;
  font-size: 14px;
  :active {
    border: none;
    outline: none;
    border-bottom: 2px solid #ffffff;
  }
  :visited {
    border: none;
    outline: none;
  }
  :focus-visible {
    border: none;
    outline: none;
    border-bottom: 2px solid #ffffff;
  }
  :placeholder {
    font-weight: 100;
    font-size: 14px;
  }
`;

export const SearchResultContainer = styled.div`
  background-color: #fbfbfb;
  border-radius: 0px 0px 8px 8px;
  height: 450px;
  overflow-y: scroll;
  padding: 20px;
`;

export const Title = styled.p`
  color: #000000;
  font-weight: 500;
  font-size: 13.3505px;
  line-height: 20px;
`;

export const ResultSummary = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #9c9c9c;
  margin-bottom: 30px;
`;

export const TransactionName = styled.h3`
  font-size: ${({ theme }) => theme.textXs};
  color: ${({ theme }) => theme.black2};
  font-weight: 400;
  margin-bottom: 10px;
`;

export const CenterDevices = styled.div`
  text-align: center;
  margin-top: 100px;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey};
`;
