import { BubleContainer, DetailText } from "./style";

const InfoDetails = ({ currentdescription, pos = 0 }) => {
  return (
    <BubleContainer pos={pos}>
      <DetailText>{currentdescription}</DetailText>
    </BubleContainer>
  );
};

export default InfoDetails;
