import styled from "styled-components";

export const CardCont = styled.div`
  h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
`;

export const TransactionName = styled.h3`
  font-size: ${({ theme }) => theme.textXs};
  color: ${({ theme }) => theme.black2};
`;

export const CenterTransactions = styled.div`
  text-align: center;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey};
`;
