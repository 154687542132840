import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowCard, CardContainer, Loader } from "../../../../components";
import {
  CardCont,
  StatisticsContainer,
  Hash,
  CardTitle,
  CardLabel,
  InfoLabels,
} from "./style";
import {
  getBlockDetail,
  copyToClipboard,
  getLatestBlocks,
  getFormDetails,
} from "../../../../methods/actions";
import { formathash } from "../../../../methods/helper";
import { useParams } from "react-router-dom";
import config from "../../../../methods/config";
import InfoDetails from "../../../../components/infoDetails";

export const Overview = () => {
  const dispatch = useDispatch();

  const blockDetail = useSelector(
    (state) => state.BlockHeightReducer.blockDetail
  );
  const gettingBlockDetail = useSelector(
    (state) => state.LoaderReducer.gettingBlockDetail
  );
  const clipboardText = useSelector(
    (state) => state.GeneralReducer.clipboardText
  );

  let { blockId } = useParams();

  useEffect(() => {
    dispatch(getBlockDetail(blockId));
    dispatch(getLatestBlocks(10));
  }, [dispatch, blockId]);

  return (
    <>
      <StatisticsContainer>
        <CardCont>
          <CardContainer>
            <CardTitle>
              <InfoLabels>
                <CardLabel>Block Hash </CardLabel>
                <InfoDetails
                  currentdescription={
                    "This is the current reference number for the block in this session."
                  }
                  pos={1}
                  handlemouse={() =>
                    dispatch(
                      getFormDetails({
                        props: ["description"],
                        value:
                          "This is the current reference number for the block in this session.",
                      })
                    )
                  }
                  handlemouseleave={() =>
                    dispatch(
                      getFormDetails({
                        props: ["description"],
                        value: "",
                      })
                    )
                  }
                />
              </InfoLabels>
            </CardTitle>
            <main
              onClick={() =>
                dispatch(
                  copyToClipboard(
                    config.platform + `block/hash/` + blockDetail.hash
                  )
                )
              }
            >
              <h3>
                {gettingBlockDetail ? (
                  <Loader color={"#E5B910"} />
                ) : (
                  formathash(blockDetail.hash, 4)
                )}
              </h3>
              <Hash
                src={
                  clipboardText ===
                  config.platform + `block/hash/` + blockDetail.hash
                    ? "/assets/svgs/activehash.svg"
                    : "/assets/svgs/hash.svg"
                }
                alt={"hash"}
              />
            </main>
          </CardContainer>
        </CardCont>

        <CardCont>
          <CardContainer>
            <CardTitle>
              <InfoLabels>
                <CardLabel>Previous Block </CardLabel>
                <InfoDetails
                  currentdescription={
                    "This is the hash for the previous block. Copying this opens up the page for the previous block"
                  }
                  pos={2}
                  handlemouse={() =>
                    dispatch(
                      getFormDetails({
                        props: ["description"],
                        value:
                          "This is the hash for the previous block. Copying this opens up the page for the previous block",
                      })
                    )
                  }
                  handlemouseleave={() =>
                    dispatch(
                      getFormDetails({
                        props: ["description"],
                        value: "",
                      })
                    )
                  }
                />
              </InfoLabels>
            </CardTitle>
            <main
              onClick={() =>
                dispatch(
                  copyToClipboard(
                    config.platform +
                      `block/hash/` +
                      blockDetail.previousBlockHash
                  )
                )
              }
            >
              <h3>
                {gettingBlockDetail ? (
                  <Loader color={"#E5B910"} />
                ) : (
                  formathash(blockDetail.previousBlockHash, 4)
                )}
              </h3>
              <Hash
                src={
                  clipboardText ===
                  config.platform +
                    `block/hash/` +
                    blockDetail.previousBlockHash
                    ? "/assets/svgs/activehash.svg"
                    : "/assets/svgs/hash.svg"
                }
                alt={"hash"}
              />
            </main>
          </CardContainer>
        </CardCont>
      </StatisticsContainer>
      <CardCont>
        <ArrowCard
          handlechange={() =>
            dispatch(
              getFormDetails({
                props: ["activetab"],
                value: 2,
              })
            )
          }
          value={
            gettingBlockDetail ? (
              <Loader color={"#E5B910"} />
            ) : (
              blockDetail.transactionCount
            )
          }
          subvalue={
            gettingBlockDetail ? (
              <Loader color={"#E5B910"} />
            ) : (
              blockDetail.transactionRateChange
            )
          }
          label={"Total Transactions"}
          description={
            "This is the total amount of sessions performed on the block"
          }
          handlemouse={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value:
                  "This is the total amount of sessions performed on the block",
              })
            )
          }
          handlemouseleave={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "",
              })
            )
          }
        />
      </CardCont>
    </>
  );
};
