import styled from "styled-components";

export const CardCont = styled.div`
  margin-top: 10px;
  width: 48%;

  h6 {
    color: ${({ theme }) => theme.grey7};
  }

  h3 {
    margin-top: 5.89px;
    margin-bottom: 3.76px;
    color: ${({ theme }) => theme.gradient_black};
  }

  main {
    display: flex;
    align-items: center;
    margin-top: 11.4px;

    img {
      margin-left: 13.33px;
    }
  }
`;

export const StatisticsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CardTitle = styled.h6`
  color: ${({ theme }) => theme.grey2};
`;

export const CardLabel = styled.h6`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey2};
`;

export const Hash = styled.img`
  // &:hover {
  //   transform: translateX(-10px);
  //   transition-duration: 2s;
  // }
`;

export const InfoLabels = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;

  h6 {
    margin-right: 5px;
  }
`;
