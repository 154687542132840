import { InputBox, InputContainer, SearchIcon } from "./style";

export const Search = ({ placeholder, value, handleChange }) => {
  return (
    <InputContainer>
      <InputBox
        placeholder={placeholder ? placeholder : "Search..."}
        value={value}
        onChange={handleChange}
      />
      <SearchIcon src="/assets/img/search.svg" alt="search-icon" />
    </InputContainer>
  );
};
