import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Calendar from "react-calendar";
import { CarlendarContainer } from "./style";
import "react-calendar/dist/Calendar.css";
import {
  getFormDetails,
  togglemodal,
  updateLeaderBoard,
} from "../methods/actions";

const Carlendar = () => {
  let dispatch = useDispatch();
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    if (value.length === 2) {
      dispatch(updateLeaderBoard());
      dispatch(
        getFormDetails({
          props: ["leaderboardstart"],
          value: new Date(value[0]).getTime(),
        })
      );
      dispatch(
        getFormDetails({
          props: ["leaderboardstop"],
          value: new Date(value[1]).getTime(),
        })
      );
      dispatch(togglemodal(false, 0));
    }
  }, [value]);

  return (
    <CarlendarContainer>
      <Calendar
        carlendarType={"US"}
        onChange={onChange}
        selectRange={true}
        // value={value}
      />
    </CarlendarContainer>
  );
};

export default Carlendar;
