import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RewardSummary from "./container/rewardsummary";
import TopEarners from "./container/topearners";
import OtherEarners from "./container/otherearners";
import Filter from "./container/filter";
import SearchResult from "./container/searchresult";
import Search from "./container/search";
import {
  getDeviceLeaderBoard,
  getCurrentWeek,
  getDeviceWeeklyLeaderBoard,
  getFormDetails,
  getMarketStatistics,
} from "../../methods/actions";
import { CenterDevices } from "./container/style";
import { PageLoader } from "../../container";

export const LeaderBoard = () => {
  const dispatch = useDispatch();
  let selectedTimeline = useSelector(
    (state) => state.GeneralReducer.selectedTimeline
  );
  let leaderboardstart = useSelector(
    (state) => state.GeneralReducer.leaderboardstart
  );
  let leaderboardstop = useSelector(
    (state) => state.GeneralReducer.leaderboardstop
  );
  let earnerscurrentpage = useSelector(
    (state) => state.DeviceReducer.earnerscurrentpage
  );
  let earnerstotalpage = useSelector(
    (state) => state.DeviceReducer.earnerstotalpage
  );
  let searchEarners = useSelector(
    (state) => state.GeneralReducer.searchEarners
  );
  let selectedWeek = useSelector((state) => state.GeneralReducer.selectedWeek);
  let earners = useSelector((state) => state.DeviceReducer.earners);

  const gettingDeviceLeaderBoard = useSelector(
    (state) => state.LoaderReducer.gettingDeviceLeaderBoard
  );

  useEffect(() => {
    //Find a better way to handle this
    dispatch(
      getFormDetails({
        props: ["newroute"],
        value: { id: 2, title: "Distributed Rewards" },
      })
    );
    dispatch(
      getFormDetails({
        props: ["oldroutes"],
        value: [{ id: 1, link: "/", title: "Home" }],
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (selectedTimeline.timeline) {
      let newPage = earnerscurrentpage + 1;
      if (earnerstotalpage > earnerscurrentpage) {
        dispatch(
          getDeviceLeaderBoard(
            selectedTimeline.id,
            leaderboardstart,
            leaderboardstop,
            newPage,
            50
          )
        );
      }
    }
  }, [
    dispatch,
    selectedTimeline.id,
    leaderboardstart,
    leaderboardstop,
    earnerscurrentpage,
    earnerstotalpage,
    selectedWeek,
  ]);

  useEffect(() => {
    if (!selectedTimeline.timeline && selectedWeek !== 0) {
      let newPage = earnerscurrentpage + 1;
      if (earnerstotalpage > earnerscurrentpage) {
        dispatch(getDeviceWeeklyLeaderBoard(selectedWeek, newPage, 50));
      }
    }
  }, [dispatch, selectedWeek, earnerscurrentpage, earnerstotalpage]);

  useEffect(() => {
    dispatch(getCurrentWeek());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMarketStatistics());
  }, []);

  return (
    <>
      {" "}
      <Filter />
      <RewardSummary />
      <Search />
      {searchEarners === "" ? (
        <>
          {earners.length > 0 ? (
            <>
              <TopEarners />
              <OtherEarners />{" "}
            </>
          ) : (
            <OtherEarners />
          )}

          {gettingDeviceLeaderBoard ? <PageLoader /> : null}
          {earners.length === 0 && !gettingDeviceLeaderBoard ? (
            <CenterDevices>
              <CenterDevices>
                <img src="/assets/img/light.svg" alt="empty" />
              </CenterDevices>{" "}
              Rewards have not been distributed for this week
            </CenterDevices>
          ) : null}
        </>
      ) : (
        <SearchResult />
      )}
    </>
  );
};
