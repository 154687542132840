import { Loader } from "../components";
import { LoaderContainer } from "./style";

export const PageLoader = () => {
  return (
    <>
      <LoaderContainer>
        <Loader color="#E5B910" size={10} />
      </LoaderContainer>
    </>
  );
};
