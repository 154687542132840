import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchFunction } from "../../../methods/helper";
import { getFormDetails } from "../../../methods/actions";
import {
  SearchInput,
  ContainerFlex,
  SearchIcon,
  SearchContainer,
  Title,
} from "./style";
import moment from "moment";

const Search = () => {
  let dispatch = useDispatch();
  let earners = useSelector((state) => state.DeviceReducer.earners);
  const searchEarners = useSelector(
    (state) => state.GeneralReducer.searchEarners
  );
  const selectedTimeline = useSelector(
    (state) => state.GeneralReducer.selectedTimeline
  );
  const selectedWeek = useSelector(
    (state) => state.GeneralReducer.selectedWeek
  );
  const leaderboardstart = useSelector(
    (state) => state.GeneralReducer.leaderboardstart
  );
  const leaderboardstop = useSelector(
    (state) => state.GeneralReducer.leaderboardstop
  );

  const implementSearch = (value) => {
    dispatch(
      getFormDetails({
        props: ["searchEarners"],
        value: value,
      })
    );
    let res = searchFunction(searchEarners, earners, "deviceName");
    console.log(res, "i am th search res");
    dispatch(
      getFormDetails({
        props: ["searchEarnersResult"],
        value: res,
      })
    );
  };

  return (
    <ContainerFlex>
      {searchEarners !== "" ? (
        <Title></Title>
      ) : (
        <Title>
          {" "}
          Top 3 Earners{" "}
          {!selectedTimeline.timeline
            ? "from Week " + selectedWeek
            : selectedTimeline.timeline === "Custom" && leaderboardstart !== ""
            ? "from" +
              moment(leaderboardstart).format("DD/MM/YYYY") +
              " - " +
              moment(leaderboardstop).format("DD/MM/YYYY")
            : " from " + selectedTimeline.timeline}{" "}
        </Title>
      )}
      <SearchContainer>
        <SearchInput
          onChange={(e) => implementSearch(e.target.value)}
          type="text"
          value={searchEarners}
          placeholder="Search Devices"
        />
        <SearchIcon src="/assets/svgs/search2.svg" alt="search-icon" />
      </SearchContainer>
    </ContainerFlex>
  );
};

export default Search;
